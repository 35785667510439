import React, { FC } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CheckSmall } from '@icon-park/react';

import { User } from 'models/User/UserModel';

import ConvertUtil from 'util/ConvertUtil';

import { BodyL, BodyS, COLORS } from 'styles/v3/variables';

import {
  Container,
  UserInformationContainer,
  UserBasicsContainer,
} from './styles';

interface UserInfoApplyProps {
  user: User;
  isUserSelected: boolean;
  handleSelected: (id: string) => void;
}

export const UserInfoApply: FC<UserInfoApplyProps> = ({
  user,
  isUserSelected,
  handleSelected,
}) => {
  return (
    <Container
      onClick={() => handleSelected(user.id)}
      isSelected={isUserSelected}
    >
      {isUserSelected && <CheckSmall fill={COLORS.brandPrimary} size={16} />}
      <Avatar
        size={62}
        icon={<UserOutlined />}
        src={ConvertUtil.getMinioUrl(user.photo)}
      />
      <UserInformationContainer>
        <BodyL $color='grey50'>
          {user.firstName} {user.lastName}
        </BodyL>
        <UserBasicsContainer>
          <BodyS $color='grey400'>
            {user.age} - {user.gender} - {user.address?.city} -{' '}
            {user.address?.state}
          </BodyS>
        </UserBasicsContainer>
      </UserInformationContainer>
    </Container>
  );
};
