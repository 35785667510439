import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { SettingTwo } from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';

import { MembershipTypes } from 'services/v3/User/types';

import { onClose, onOpen } from 'redux/v3/profileModal/actions';
import { ApplicationState } from 'redux/store';

import { useGetProfiles } from 'hooks/v3/users/useGetProfiles/useGetProfiles';
import useScreenType from 'hooks/useScreenType';
import { useProfile } from 'hooks/v3/profile/useProfile';

import ConvertUtil from 'util/ConvertUtil';
import { formatUserName } from 'util/formatUserName';

import { AccountTag } from './components/AccountTag/AccountTag';
import { AccountAccordion } from './components/AccountAccordion/AccountAccordion';

import * as S from './styles';

export const AccountModal = () => {
  useGetProfiles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isMobile } = useScreenType();
  const { users, isAnyRoles } = useProfile();

  const isOpenProfileModal = useSelector(
    (state: ApplicationState) => state.profileModal.isOpen
  );

  const currentUser = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );

  const isOfficialCurrentUser = currentUser?.membershipType === MembershipTypes.Official;

  const isCommunityOtherUsers = useMemo(() => {
    return users.some((user) => (
      user.baseUserId &&
      user?.membershipType === MembershipTypes.Community &&
      (user.type === UserType.PLAYER || user.type === UserType.COACH)
    ));
  }, [users]);

  const types = useMemo(() => {
    return [
      currentUser?.type,
      ...(Array.isArray(currentUser?.types) ? currentUser.types : [])
    ].filter(Boolean) as UserType[];
  }, [currentUser]);

  const handleGoToUpgradeMembership = () => {
    dispatch(onClose());
    history.push('/v3/profile/upgrade-membership');
  };

  const handleRedirect = () => {
    dispatch(onClose());
    history.push(`/v3/user/${currentUser.id}/info`);
  };

  return (
    <S.StyledModal
      mask={false}
      maskClosable
      footer={false}
      closable={false}
      centered={isMobile}
      maskStyle={{ zIndex: 0 }}
      visible={isOpenProfileModal}
      onOk={() => dispatch(onOpen())}
      onCancel={() => dispatch(onClose())}
      width={!isMobile ? '460px' : '100vw'}
      style={
        !isMobile ? { top: 100, right: 0, position: 'absolute' } : undefined
      }
    >
      <S.ModalWrapper>
        <S.ButtonWrapper>
          <S.SettingsButton onClick={handleRedirect}>
            <SettingTwo size='24px' />
          </S.SettingsButton>
        </S.ButtonWrapper>
        <S.HeaderWrapper>
          <Avatar
            size={118}
            icon={<UserOutlined />}
            src={ConvertUtil.getMinioUrl(currentUser.photo)}
          />
          <S.UserName>
            {formatUserName({
              lastName: currentUser.lastName,
              firstName: currentUser.firstName,
              middleName: currentUser.middleName,
            })}
          </S.UserName>
          <S.TagsWrapper>
            {types?.map((type) => (
              <AccountTag key={type} type={type} />
            ))}
          </S.TagsWrapper>
        </S.HeaderWrapper>
        {isCommunityOtherUsers && isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER]) && (
          <S.UpgradeButton onClick={handleGoToUpgradeMembership}>
            Upgrade Memberships
          </S.UpgradeButton>
        )}
        {(!isOfficialCurrentUser && !isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER])) && (
          <S.UpgradeButton onClick={handleGoToUpgradeMembership}>
            Upgrade Membership
          </S.UpgradeButton>
        )}
        <AccountAccordion />
      </S.ModalWrapper>
    </S.StyledModal>
  );
};
