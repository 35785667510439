import { AgeDivision, NewAgeDivision } from '../../admin/models/AgeDivision';
import { Gender } from '../../admin/models/Enums';

export enum ParticipationStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  DECLINED = 'DECLINED',
  DELETED = 'DELETED',
  // NOT_SUBMITTED = 'NOT_SUBMITTED',
}

export interface RosterCreation {
  id?: string;
  ageDivision: AgeDivision;
  clubId: string;
  eventId: string;
  name: string;
  eventParticipationStatus?: ParticipationStatus;
}

export interface RosterUpdate {
  id?: string;
  ageDivision?: AgeDivision;
  clubId?: string;
  eventId?: string;
  name?: string;
  eventParticipationStatus?: ParticipationStatus;
  status?: string;
  locked?: boolean;
}

export interface PlayerRosterItem {
  id: string;
  birthDate: Date;
  firstName: string;
  lastName: string;
  number: number;
  photo: string;
  approvedPhoto?: string;
  membershipId: string;
  attend?: boolean;
  membershipName: string;
  membershipType: 'COMMUNITY' | 'OFFICIAL';
}

export interface CoachRosterItem {
  id: string;
  birthDate: Date;
  firstName: string;
  lastName: string;
  membershipId: string;
  photo: string;
  approvedPhoto?: string;
  membershipName: string;
  membershipType: 'COMMUNITY' | 'OFFICIAL';
}

export interface Roster {
  id?: string;
  rosterId?: string;
  ageDivision?: NewAgeDivision;
  eventParticipationStatus?: ParticipationStatus;
  creationDate: Date;
  submittedDate: Date;
  updateDate: Date;
  coaches: CoachRosterItem[];
  players: PlayerRosterItem[];
  status?: string;
  club?: {
    id: string,
    logo: string,
    name: string,
  };
  eventId?: string;
  name?: string;
  event?: {
    id: string,
    logo: string,
    name: string,
    type: string,
    maximumCoachesNumber: number,
    maximumManagersNumber: number,
    maximumPlayersNumber: number,
    minimumCoachesNumber: number,
    minimumManagersNumber: number,
    minimumPlayersNumber: number,
  };
}

export interface RosterListing {
  id: string;
  status: string;
  name: string;
  ageDivision: AgeDivision | NewAgeDivision;
  eventParticipationStatus: ParticipationStatus;
  creationDate: Date;
  submittedDate: Date;
  updateDate: Date;
  club: {
    id: string,
    logo: string,
    name: string,
  };
  event?: {
    id: string,
    name: string,
    logo?: string,
  };
  locked?: boolean;
}

export interface RosterMemberpool {
  birthDate: Date;
  firstName: string;
  gender: Gender;
  id: string;
  lastName: string;
  membershipId: string;
  middleName: string;
  photo: string;
  approvedPhoto?: string;
  rosterAvailability: {
    age: boolean,
    ageProof: boolean,
    document: boolean,
    gender: boolean,
    membership: boolean,
    photo: boolean,
  };
}

export interface JoinRequirements {
  year?: number;
  gender?: string;
  membershipId?: string[];
}
