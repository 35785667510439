import React, { FC } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ConvertUtil from 'util/ConvertUtil';

import { BodyS } from 'styles/v3/variables';

import * as S from './styles';

interface INameFieldProps {
  name: string;
  photo: string;
}

export const Name: FC<INameFieldProps> = ({ name, photo }) => {
  return (
    <S.Container>
      <Avatar
        size={24}
        icon={<UserOutlined />}
        src={ConvertUtil.getMinioUrl(photo)}
      />
      <BodyS>{name}</BodyS>
    </S.Container>
  );
};
