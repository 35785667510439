import React, { useMemo } from 'react';

import ConvertUtil from 'util/ConvertUtil';
import { NewAgeDivision } from 'admin/models/AgeDivision';

import { TitleH2, BodyM, BodyMBold } from 'styles/v3/variables';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import { AgeRulesEnumsToLabel } from 'pages/V3/setupEvent/Divisions/constants';
import {
  Container,
  HeaderInformationsContainer,
  HeaderTitleContainer,
  AvatarEventInformation,
  EventImage,
  RulesInformation,
  Rules,
  ButtonsHeader,
  LocksContainer,
} from './styles';

interface HeaderRosterEditProps {
  handleGoBack: () => void;
  name: string;
  eventName: string;
  eventLogo: string;
  division: NewAgeDivision;
  locksIn?: Date;
}

export const HeaderRosterEdit = ({
  handleGoBack,
  name,
  eventName,
  eventLogo,
  division,
  locksIn = new Date(),
}: HeaderRosterEditProps) => {
  const lockDate = new Date(locksIn).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const years = useMemo(() => {
    return division.years.map((year) =>
      AgeRulesEnumsToLabel[division.rule].replace('Year', year.toString())
    );
  }, [division.rule, division.years]);

  const allowGender = (): string => {
    if (division.allowGirlBoysOnAnotherDivision) {
      if (division.gender === 'MALE') {
        return `- Boys can Play`;
      }

      if (division.gender === 'FEMALE') {
        return `- Girls can Play`;
      }
    }

    return '';
  };

  return (
    <Container>
      <HeaderInformationsContainer>
        <TextButtonWithIcon
          reverse
          icon='back'
          color='light'
          align='flex-end'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <HeaderTitleContainer>
          <TitleH2>{name}</TitleH2>
          <AvatarEventInformation>
            <EventImage>
              <img src={ConvertUtil.getMinioUrl(eventLogo)} alt='Event Logo' />
            </EventImage>
            <BodyM>{eventName}</BodyM>
          </AvatarEventInformation>
        </HeaderTitleContainer>
        <RulesInformation>
          <Rules>
            <BodyMBold $color='grey400'>Gender Rules</BodyMBold>
            <BodyM $color='grey400'>
              {division.gender} {allowGender()}
            </BodyM>
          </Rules>
          <Rules>
            <BodyMBold $color='grey400'>Years Allowed</BodyMBold>
            <BodyM $color='grey400'>{years.join(', ')}</BodyM>
          </Rules>
          {division.gender === 'COED' && (
            <Rules>
              <BodyMBold $color='grey400'>Coed Rules</BodyMBold>
              <BodyM $color='grey400'>{division.coedRules}</BodyM>
            </Rules>
          )}
        </RulesInformation>
      </HeaderInformationsContainer>
      <ButtonsHeader>
        <LocksContainer>
          <BodyM $isUpper $color='grey400'>
            Locks
          </BodyM>
          <BodyM $isUpper $color='grey50'>
            {lockDate}
          </BodyM>
        </LocksContainer>
      </ButtonsHeader>
    </Container>
  );
};
