import React, { useCallback, useMemo, useState } from 'react';
import { CloseSmall } from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';
import { JoinClubRole } from 'models/v3/Club/ClubModel';

import { useProfile } from 'hooks/v3/profile/useProfile';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { useRequestJoinAndCreateRule } from 'hooks/v3/clubs/useRequestJoinAndCreateRule/useRequestJoinAndCreateRule';

import { TooltipAppears } from 'components/TooltipAppears/TooltipAppears';
import InputCheckbox from 'components/v3/Forms/InputCheckbox/InputCheckbox';
import FilledButton from 'components/v3/Buttons/FilledButton';

import { BodyM, BodyXL, TitleH3 } from 'styles/v3/variables';

import * as S from './styles';
import { JoinModalProps } from './types';
import { ALLOWED_ROLES, DISABLE_REASONS } from './configs';

export const JoinModal = ({ isOpen, handleIsOpen, club }: JoinModalProps) => {
  const { checkAgePermission } = useProfile();

  const {
    isCoachOfThisClub,
    isPlayerOfThisClub,
    isTeamManagerOfThisClub,
    isDirectorOfThisClub,
  } = useClubPermission(club);

  const {
    mutateAsync: requestJoin,
    isLoading: isRequesting,
  } = useRequestJoinAndCreateRule();

  const [roles, setUpdateRoles] = useState(ALLOWED_ROLES);

  const handleCloseModal = () => {
    handleIsOpen(false);
    const updatedRoles = roles.map((role) => ({ ...role, selected: false }));
    setUpdateRoles(updatedRoles);
  };

  const handleJoinClub = async () => {
    if (isRequesting) return;

    let rolesSeleted: JoinClubRole | JoinClubRole[] =
      roles.filter((role) => role.selected)?.map((role) => role.slug) ?? [];
    rolesSeleted = rolesSeleted.length === 4 ? 'all' : rolesSeleted;

    try {
      if (Array.isArray(rolesSeleted)) {
        const joinAll = rolesSeleted.map(async (roleItem) => {
          const response = await requestJoin({
            clubId: club.id,
            role: roleItem,
          });

          return response;
        });

        await Promise.all(joinAll).then(() => handleCloseModal());
      } else {
        requestJoin({ clubId: club.id, role: rolesSeleted }).then(() => {
          handleCloseModal();
        });
      }
    } catch (e) {
      console.warn(`Error: ${e}`);
    }
  };

  const disablingReasons = useCallback(
    (role: UserType) => {
      if (!club.allowApplications) {
        return DISABLE_REASONS.application;
      }

      if (!checkAgePermission(role)) {
        return DISABLE_REASONS.ageRules(role);
      }

      if (role === UserType.PLAYER && isPlayerOfThisClub) {
        return DISABLE_REASONS.applied(UserType.PLAYER);
      }

      if (role === UserType.COACH && isCoachOfThisClub) {
        return DISABLE_REASONS.applied(UserType.COACH);
      }

      if (role === UserType.TEAM_MANAGER && isTeamManagerOfThisClub) {
        return DISABLE_REASONS.applied(UserType.TEAM_MANAGER);
      }

      if (role === UserType.CLUB_DIRECTOR && isDirectorOfThisClub) {
        return DISABLE_REASONS.applied(UserType.CLUB_DIRECTOR);
      }

      return '';
    },
    [
      club,
      isCoachOfThisClub,
      isPlayerOfThisClub,
      isTeamManagerOfThisClub,
      isDirectorOfThisClub,
      checkAgePermission,
    ]
  );

  const hasCheckedMember = useMemo(() => {
    return roles.some((role) => role.selected);
  }, [roles]);

  const handleSelectMember = (roleTitle: string) => {
    const updatedRoles = roles.map((role) => {
      if (role.title === roleTitle) {
        return {
          ...role,
          selected: !role.selected,
        };
      }

      return role;
    });

    setUpdateRoles(updatedRoles);
  };

  return (
    <S.StyledModal
      centered
      footer={false}
      closable={false}
      visible={isOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
    >
      <S.Container>
        <S.HeaderWrapper>
          <TitleH3>Select how you want to join this club</TitleH3>
          <S.CloseButton onClick={handleCloseModal}>
            <CloseSmall size='24px' />
          </S.CloseButton>
        </S.HeaderWrapper>
        <BodyM $color='grey400'>You can select more than one option.</BodyM>
        <S.RolesWrapper>
          {roles.map((role) => (
            <TooltipAppears
              fullWidth
              enabled={!!disablingReasons(role.name).length}
              title={disablingReasons(role.name)}
            >
              <S.RoleCard key={role.name}>
                <InputCheckbox
                  id={role.name}
                  name={role.name}
                  onChange={() => handleSelectMember(role.title)}
                  checked={role.selected}
                />
                <BodyXL>{role.title}</BodyXL>
                <role.icon size={36} />
              </S.RoleCard>
            </TooltipAppears>
          ))}
        </S.RolesWrapper>
        <FilledButton
          disabled={!hasCheckedMember || isRequesting}
          isUpper
          onClick={handleJoinClub}
        >
          {isRequesting ? 'Requesting...' : 'Request to Join'}
        </FilledButton>
      </S.Container>
    </S.StyledModal>
  );
};
