import React, { FC, ReactNode } from 'react';
import { Pagination } from 'antd';

import * as S from './styles';

interface IPaginationWrapperProps {
  current?: number;
  total?: number;
  pageSize?: number;
  defaultCurrent?: number;
  defaultPageSize?: number;
  children: ReactNode;
  onChange?: (page: number, pageSize?: number) => void;
}

export const PaginationWrapper: FC<IPaginationWrapperProps> = ({
  current,
  defaultCurrent = 1,
  total = 0,
  pageSize = 10,
  defaultPageSize = 10,
  children,
  onChange,
}) => {
  return (
    <>
      {children}
      {total > pageSize && (
        <S.Wrapper>
          <Pagination
            defaultCurrent={defaultCurrent}
            defaultPageSize={defaultPageSize}
            current={current}
            showSizeChanger={false}
            total={total}
            onChange={onChange}
            pageSize={pageSize}
          />
        </S.Wrapper>
      )}
    </>
  );
};
