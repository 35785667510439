import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import EventService from 'services/v3/Event/EventService';
import { PagedEventListWithRosterUsers } from 'services/v3/Event/types';

export function useGetJoinedEventsInfinity(pageSize: number) {
  const { id: accountId } = useSelector(
    (state: ApplicationState) => state.currentUser.data
  );
  return useInfiniteQuery({
    keepPreviousData: true,
    queryKey: ['get-joined-event-infinity', accountId, pageSize],
    queryFn: ({ pageParam: page }) => {
      return EventService.getJoinedEvents(page, pageSize);
    },
    getNextPageParam: (
      data: ServiceResponseV3<PagedEventListWithRosterUsers>
    ) => (data.data?.hasNextPage ? data.data?.page + 1 : undefined),
  });
}
