import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const TeamsContainer = styled.div`
  padding: ${toRem(16)} 0;

  background-color: ${COLORS.grey950};
  border-radius: ${toRem(24)};
`;

export const TeamsHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 ${toRem(24)} ${toRem(8)} ${toRem(24)};
`;

export const TeamsTitleBackBtnContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${toRem(24)};
`;

export const TeamsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(24)} ${toRem(20)};
  gap: ${toRem(16)};
`;

export const Popover = styled.div``;

export const PopoverDropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: ${toRem(257)};
  padding: ${toRem(8)} 0;
  align-items: flex-start;
  background-color: ${COLORS.grey950};
  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(12)};
  position: absolute;
  right: 72px;
  top: 0;
  transition-duration: 200ms;
  opacity: 0;
  visibility: hidden;
  transition-duration: 200ms;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
`;

export const PopoverItem = styled.div`
  width: 100%;
  padding: ${toRem(12)} ${toRem(16)};
  cursor: pointer;
  transition-duration: 300ms;

  :hover {
    background-color: ${COLORS.grey800};
  }
`;
