import React from 'react';

import { BodyXS } from 'styles/v3/variables';
import {
  HeadsetOne,
  SoccerOne,
  Whistling,
  PersonalPrivacy,
} from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';
import * as S from './styles';

export type AccountTagProps = {
  type: UserType,
};

export const ACCOUNTS_TAG_ICONS = {
  STAFF: Whistling,
  PLAYER: SoccerOne,
  COACH: HeadsetOne,
  REFEREE: Whistling,
  CLUB_DIRECTOR: Whistling,
  TOURNAMENT_DIRECTOR: Whistling,
  TEAM_MANAGER: PersonalPrivacy,
};

export const AccountTag = ({ type }: AccountTagProps) => {
  const Icon = ACCOUNTS_TAG_ICONS[type];

  return (
    <S.Tag>
      <Icon size='18px' />
      <BodyXS>{type.replace('_', '  ')}</BodyXS>
    </S.Tag>
  );
};
