import React, { FC, useState, useCallback, useMemo, MouseEvent } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import OrderService from 'services/v3/Order/OrderService';

import { UserType } from 'redux/user/types';

import { useProfile } from 'hooks/v3/profile/useProfile';

import PaymentCard from 'components/v3/Cards/PaymentCard/PaymentCard';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import { notification } from 'components/v3/Notification/notification';

import DateUtil from 'util/DateUtil';

import { TitleH1, TitleH3, BodyM, BodyS } from 'styles/v3/variables';

import Logo from 'assets/imgs/logos/logo-vault-green.png';

import ConvertUtil from 'util/ConvertUtil';

import * as S from './styles';

const PRICE = 29;

const UpgradeMembershipPayment: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const { currentUser, users, isAnyRoles } = useProfile();

  const currentSeason = DateUtil.formatSeason();
  const firstYear = currentSeason.split('-')[0];
  const lastYear = currentSeason.split('-')[1];

  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const purchasedUserIds = useMemo(() => {
    const ids = urlParams.get('users');

    return ids?.split(',') || [];
  }, [urlParams]);

  const price = useMemo(() => {
    if (isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER])) {
      return purchasedUserIds.length * PRICE;
    }

    return PRICE;
  }, [purchasedUserIds, isAnyRoles]);

  const purchasedUsers = useMemo(() => {
    return users.filter((user) => {
      if (isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER])) {
        return purchasedUserIds.includes(user.id);
      }

      return currentUser.id === user.id;
    });
  }, [purchasedUserIds, currentUser, users]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const createMembership = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const ids = purchasedUserIds.join(',');

    OrderService.getMembershipCheckoutStripeUrl({
      userIds: purchasedUserIds,
      cancelUrl: `/v3/profile/upgrade-membership/payment?users=${ids}`,
      successUrl: `/v3/profile/upgrade-membership/payment?success=true&users=${ids}`,
    })
      .then((res) => {
        if (res.httpCode === 200) {
          window.location.href = res.data.paymentUrl;
        } else {
          notification.error({
            duration: 3,
            message: 'Error',
            description:
              res.message ||
              'Error upgrading membership checkout please try again',
          });
        }
      })
      .catch((error: any) => {
        notification.error({
          duration: 3,
          message: 'Error',
          description:
            error.message ||
            'Error upgrading membership checkout please try again',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <S.PaymentWrapper>
      {urlParams.get('success') ? (
        <PaymentCard
          title='Payment was a success'
          description='You will be redirected soon. If you were not redirected, just click the button below.'
          link='/v3/home-page'
          buttonLabel='Go to home page'
          orientation='row'
          size='large'
          theme='success'
        />
      ) : (
        <>
          <TextButtonWithIcon
            className='back-button'
            color='light'
            align='flex-end'
            reverse
            icon='back'
            onClick={handleGoBack}
          >
            Go back
          </TextButtonWithIcon>
          <PaymentCard
            title='something went wrong'
            description='Please make sure your payment information is correct and try again'
            action={createMembership}
            buttonLabel={isLoading ? 'Processing....' : 'Continue to payment'}
            orientation='row'
            size='large'
            theme='error'
          />
        </>
      )}
      <TitleH1>Order Summary</TitleH1>
      <S.OrderSummaryWrapper>
        <S.SeasonWrapper>
          <img src={Logo} alt='Vault Logo' />
          <S.SeasonDetails>
            <TitleH3>Season {currentSeason}</TitleH3>
            <BodyM $color='grey300'>
              Starts at September 1st, {firstYear}
              <br />
              Ends at August 31th, {lastYear}
            </BodyM>
          </S.SeasonDetails>
        </S.SeasonWrapper>
        <S.MembershipDetailsWrapper>
          <S.MembershipInfo $direction='row'>
            <TitleH3 $color='brandPrimary'>${price}</TitleH3>
            <BodyM>Official Membership</BodyM>
          </S.MembershipInfo>
          <S.MembershipInfo $direction='column'>
            <BodyM className='user-status'>User upgraded</BodyM>
            <S.UsersWrapper>
              {purchasedUsers.map((user) => (
                <S.UserItem key={user.id}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={ConvertUtil.getMinioUrl(user.photo)}
                  />
                  <BodyM>
                    {user.firstName} {user.lastName}
                  </BodyM>
                </S.UserItem>
              ))}
            </S.UsersWrapper>
          </S.MembershipInfo>
        </S.MembershipDetailsWrapper>
      </S.OrderSummaryWrapper>
      <BodyS className='disclaimer'>
        Your receipt will be sent to your email.
      </BodyS>
    </S.PaymentWrapper>
  );
};

export default withRouter(UpgradeMembershipPayment);
