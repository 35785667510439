import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PeoplePlusOne, Plus } from '@icon-park/react';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import { BodyLBold } from 'styles/v3/variables';

import { RoastersForClub } from './components/RoastersOpen/components/RoastersForClub';

import * as S from './styles';

const SUB_TAB_KEYS = {
  OPEN: 'Open',
  COMPLETED: 'Completed'
};

type ExtraButtonsProps = {
  type: 'clubs' | 'referees';
};

const tabPath = {
  OPEN: 'open',
  COMPLETED: 'completed'
};

const ExtraButtons = ({ type }: ExtraButtonsProps) => {
  if (type === 'clubs') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
        Invite club
      </OutlinedButtonWithIcon>
    );
  }

  if (type === 'referees') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
        Invite referee
      </OutlinedButtonWithIcon>
    );
  }

  return null;
};

export const ClubDirectorTeamsAndRoster = () => {
  const history = useHistory();

  const params: {
    clubId: string,
    tabSlug: string,
    subTabSlug: string,
  } = useParams();

  const handleOnChange = (key: string) => {
    history.push(
      `/v3/clubs/${params.clubId}/dashboard/teams-and-rosters/${key}`
    );
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        activeKey={params.subTabSlug}
        onChange={handleOnChange}
        tabBarExtraContent={<ExtraButtons type={params.subTabSlug as any} />}
      >
        <S.TabPane
          key={tabPath.OPEN}
          tab={<BodyLBold>{SUB_TAB_KEYS.OPEN}</BodyLBold>}
        />
        <S.TabPane
          key={tabPath.COMPLETED}
          tab={<BodyLBold>{SUB_TAB_KEYS.COMPLETED}</BodyLBold>}
        />
      </S.Tabs>
      <RoastersForClub tab={params.subTabSlug} />
    </S.Container>
  );
};
