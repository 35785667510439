import React, { FC, useCallback, useMemo } from 'react';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import { RosterStatus } from 'services/v3/Rosters/enums';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import { BodyXL, BodyM } from 'styles/v3/variables';

import { PlayerOfCoachInformation } from './PlayerOrCoachInformation';
import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip';

import { IUserRosterError, UserWithJersey } from '../../types';

import {
  Container,
  HeaderList,
  BodyList,
  ContainerOfInfos,
  TitleWrapper,
} from './styles';

interface IListOfUserProps {
  typeOfUser: string;
  coachesList?: User[];
  playersList?: UserWithJersey[];
  rosterId?: Maybe<string>;
  isLockedRoster?: boolean;
  hasLimitsError: boolean;
  validationMin: number;
  validationMax: number;
  rosterUserErrors: IUserRosterError[];
  rosterStatus?: RosterStatus;
  handleOpenModal: (title: UserType) => void;
  handleOpenUserInfoModal: (user: User) => void;
  onResetUserErrors: (userId: string, param: keyof IUserRosterError, value: boolean) => void;
}

export const ListOfUser: FC<IListOfUserProps> = ({
  typeOfUser,
  coachesList,
  playersList,
  rosterId,
  validationMin,
  validationMax,
  isLockedRoster = false,
  hasLimitsError,
  rosterUserErrors,
  rosterStatus,
  handleOpenModal,
  handleOpenUserInfoModal,
  onResetUserErrors,
}) => {
  const userType = typeOfUser === 'Coaches' ? UserType.COACH : UserType.PLAYER;
  const listOfUser = typeOfUser === 'Coaches' ? coachesList : playersList;

  const errorMessages = useMemo(() => {
    if (listOfUser && listOfUser?.length > validationMax) {
      return [`There are no more than ${validationMax} ${typeOfUser}`];
    }

    if (listOfUser && listOfUser?.length < validationMin) {
      return [`There are at least ${validationMin} ${typeOfUser}`];
    }

    return [];
  }, [validationMin, validationMax, listOfUser]);

  const currentUserRosterError = useCallback(
    (userId: string) => {
      return rosterUserErrors.find((error) => error.userId === userId);
    },
    [rosterUserErrors]
  );

  return (
    <Container>
      <HeaderList>
        <TitleWrapper>
          <BodyXL $color='white'>
            {typeOfUser} ({listOfUser?.length})
          </BodyXL>
          <ErrorTooltip visible={hasLimitsError} textContents={errorMessages} />
        </TitleWrapper>
        <TextButtonWithIcon
          customIcon='PeoplePlusOne'
          color='primary'
          align='flex-end'
          isUpper
          disabled={isLockedRoster}
          isBold={false}
          onClick={() => handleOpenModal(userType)}
        >
          ADD {typeOfUser}
        </TextButtonWithIcon>
      </HeaderList>
      <BodyList>
        {!!listOfUser?.length && (
          <ContainerOfInfos>
            {listOfUser.map((user) => (
              <PlayerOfCoachInformation
                key={user.id}
                user={user}
                rosterId={rosterId}
                rosterStatus={rosterStatus}
                isLockedRoster={isLockedRoster}
                error={currentUserRosterError(user.id)}
                handleOpenUserInfoModal={handleOpenUserInfoModal}
                onResetUserErrors={onResetUserErrors}
              />
            ))}
          </ContainerOfInfos>
        )}
        {!listOfUser?.length && (
          <BodyM $color='grey400' style={{ paddingTop: 50, paddingBottom: 50 }}>
            You don&apos;t have{' '}
            {typeOfUser === 'Coaches' ? 'coaches' : 'players'} in this roster.
            Add now
          </BodyM>
        )}
      </BodyList>
    </Container>
  );
};
