import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { User } from 'models/User/UserModel';
import { ApplicationState } from 'redux/store';
import { clubDashboardToggleSelectedMemberPoolUser } from 'redux/v3/clubDashboard/actions';

import { useGetMyClubsInvites } from 'hooks/v3/clubs/useGetMyClubsInvites/useGetMyClubsInvites';

import DateUtil from 'util/DateUtil';

import { Table } from 'components/v3/Table/Table';
import RightDrawer from 'components/v3/RightDrawer';

import { BodyLBold } from 'styles/v3/variables';

import { Filters } from '../Filters/Filters';
import {
  getDocumentStatus,
  getReadableGender,
} from '../../../MembersPoolTab/components/MembersPoolTable/utils';

import { columns } from './columns';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';
import { FilterModalBody } from './components/Modal/FilterModalBody/FilterModalBody';
import { InvitationFiltersType } from './components/Modal/FilterModalBody/types';

export const InvitationsTable = () => {
  const { clubId } = useParams<{ clubId: string }>();

  const dispatch = useDispatch();

  const {
    data: members,
    mutateAsync: fetchClubInvites,
  } = useGetMyClubsInvites();

  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [currentMember, setCurrentMember] = React.useState<User | undefined>(
    undefined
  );

  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    documentStatus: [],
    gender: [],
    membership: [],
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const invitesAdapter = useMemo(() => {
    return (
      members?.data.map((member) => ({
        id: member.id,
        firstName: `${member.receiver.firstName} ${member.receiver.lastName}`,
        gender: getReadableGender(member.receiver.gender),
        birthYear: DateUtil.getYearFromDateString(
          member.receiver.birthDate?.toString()
        ),
        xp: '-',
        email: member.receiver.email ?? member.receiver.accountEmail,
        status: member.status,
        membershipType: member.receiver.membership?.type ?? 'COMMUNITY',
        documentStatus: getDocumentStatus(
          member.receiver.documentationVerified,
          member.receiver.ageVerified,
          member.receiver.type
        ),
        photo: member.receiver.photo,
        selected: selectedUserIds.includes(member.receiver.id),
        toggleSelected: () => handleToggleSelectedUser(member.receiver.id),
        openDrawer: () => handleOpenDrawer(member.receiver),
      })) || []
    );
  }, [members, selectedUserIds]);

  const handleToggleSelectedUser = (userId: string) => {
    dispatch(clubDashboardToggleSelectedMemberPoolUser(userId));
  };

  const fetchBySearch = useCallback(
    debounce((value: string) => {
      fetchClubInvites({
        clubId,
        birthYear: advancedFilter.age,
        gender: advancedFilter.gender,
        documentStatus: advancedFilter.documentStatus,
        membershipType: advancedFilter.membership,
        name: value,
      });
    }, 300),
    [clubId, advancedFilter]
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    fetchBySearch(value);
  };

  const handleOpenDrawer = (member: User) => {
    setCurrentMember(member);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentMember(undefined);
    setIsOpen(false);
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handleOpenFilterModal = () => {
    setIsFilterOpen(true);
  };

  const handleSetAdvancedFilter = (filters: InvitationFiltersType) => {
    setAdvancedFilter(filters);

    fetchClubInvites({
      clubId,
      birthYear: filters.age,
      gender: filters.gender,
      documentStatus: filters.documentStatus,
      membershipType: filters.membership,
      name: searchValue,
    });

    setIsFilterOpen(false);
  };

  useEffect(() => {
    fetchClubInvites({ clubId });
  }, [clubId]);

  return (
    <>
      <Filters
        handleOpenFilterModal={handleOpenFilterModal}
        handleChange={handleSearch}
      />
      <Table
        columns={columns}
        dataSource={invitesAdapter}
        pagination={{ hideOnSinglePage: true }}
      />
      <RightDrawer
        isOpen={isOpen && !!currentMember}
        handleCloseModal={handleCloseModal}
        headerComponent={<ModalHeader photo={currentMember?.photo} />}
      >
        {currentMember && <ModalBody user={currentMember} />}
      </RightDrawer>
      <RightDrawer
        isOpen={isFilterOpen}
        handleCloseModal={handleCloseFilterModal}
        headerComponent={<BodyLBold $color='brandPrimary'>Filters</BodyLBold>}
      >
        <FilterModalBody
          filters={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </RightDrawer>
    </>
  );
};
