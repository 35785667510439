import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, get, DeepPartial } from 'react-hook-form';
import moment from 'moment';

import { Address } from 'models/User/AddressModel';
import UserType from 'models/User/UserTypeModel';

import { Gender } from 'admin/models/Enums';
import { PatchBaseUser } from 'redux/v3/baseUsers/types';

import UserServices from 'services/v3/User/UserService';

import { useFindAllWithFilters } from 'hooks/v3/clubs/useFindAllWithFilters/useFindAllWithFilters';
import { useRequestJoinAndCreateRule } from 'hooks/v3/clubs/useRequestJoinAndCreateRule/useRequestJoinAndCreateRule';
import { AGE_RULES } from 'hooks/v3/profile/useProfile/configs';

import InputDate from 'components/v3/Forms/InputDate/InputDate';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import { notification } from 'components/v3/Notification/notification';
import Select from 'components/v3/Forms/Select/Select';

import { useProfile } from 'hooks/v3/profile/useProfile';

import { BodyXL } from 'styles/v3/variables';

import InputPhone from 'components/v3/Forms/InputPhone/InputPhone';
import InputText from 'components/v3/Forms/InputText/InputText';

import { USER_ROLES_OPTIONS, GENDER_OPTIONS } from './configs';
import {
  getCountriesOptions,
  getCountryStatesOptions,
  getClubsOptions,
  convertUserTypesToClubRole,
} from './utils';

import * as S from './styles';

interface IOption<T> {
  label: string;
  value: T;
}

interface IRegistrationForm {
  birthDate: string;
  gender: IOption<Gender>;
  club?: IOption<string>;
  userType: IOption<UserType>;
  phoneNumber: string;
  addressLine1: string;
  addressLine2?: string;
  country: IOption<string>;
  state?: Maybe<IOption<string>>;
  city: string;
  zipcode: string;
}

const CompleteRegistration = () => {
  const history = useHistory();

  const [clubSearchValue, setClubSearchValue] = useState('');

  const { currentUser, isBaseUser, updateCurrentAccount } = useProfile();
  const { mutateAsync: requestToJoinClub } = useRequestJoinAndCreateRule();
  const { data: clubs, isLoading } = useFindAllWithFilters({
    filters: {
      name: clubSearchValue,
    },
  });

  const {
    control,
    formState: { errors, isValid },
    watch,
    setValue,
    handleSubmit,
  } = useForm<IRegistrationForm>({
    mode: 'onChange',
  });

  const fields = watch(['country', 'userType', 'birthDate', 'state']);

  const countryStatesOptions = useMemo(() => {
    if (fields.country?.value) {
      return getCountryStatesOptions(fields.country.value);
    }

    return [];
  }, [fields.country]);

  const clubsOptions = useMemo(() => {
    if (clubs?.content) {
      return getClubsOptions(clubs.content);
    }

    return [];
  }, [clubs]);

  const isAccessedClubsSelection = useMemo(() => {
    const accessedRoles = [
      UserType.COACH,
      UserType.PLAYER,
      UserType.CLUB_DIRECTOR,
      UserType.TEAM_MANAGER,
    ];

    return accessedRoles.includes(fields.userType?.value);
  }, [fields.userType]);

  const allowedRoleByAgeError = useMemo(() => {
    const errorMaps: Record<UserType, Maybe<string>> = {
      [UserType.COACH]: `Coach must be ${AGE_RULES.COACH} years old or older`,
      [UserType.CLUB_DIRECTOR]: `Club Director must be ${AGE_RULES.CLUB_DIRECTOR} years old or older`,
      [UserType.REFEREE]: `Referee must be ${AGE_RULES.REFEREE} years old or older`,
      [UserType.TEAM_MANAGER]: `Team Manager must be ${AGE_RULES.TEAM_MANAGER} years old or older`,
      [UserType.PLAYER]: null,
      [UserType.TOURNAMENT_DIRECTOR]: null,
      [UserType.STAFF]: null,
    };

    const checkLocalAgePermission = () => {
      if (fields.userType.value && fields.birthDate) {
        const currentAge = moment().diff(
          moment(fields.birthDate, 'YYYY-MM-DD'),
          'years'
        );

        return currentAge >= AGE_RULES[fields.userType.value];
      }

      return false;
    };

    if (fields.userType && fields.birthDate && !checkLocalAgePermission()) {
      return errorMaps[fields.userType.value] ?? null;
    }

    return null;
  }, [fields.userType, fields.birthDate]);

  const isRequiredStateField = useMemo(() => {
    return !!countryStatesOptions.length && !fields.state;
  }, [countryStatesOptions, fields.state]);

  const isAdult = useMemo(() => {
    if (fields.birthDate) {
      const birthDate = moment(fields.birthDate);
      const today = moment();

      return today.diff(birthDate, 'years') >= 18;
    }

    return true;
  }, [fields.birthDate]);

  const submitCompleteRegistrationForm = useCallback(
    async (data: IRegistrationForm) => {
      if (!currentUser.id) return;

      const {
        birthDate,
        gender,
        userType,
        club,
        phoneNumber,
        addressLine1,
        addressLine2,
        country,
        state,
        city,
        zipcode,
      } = data;

      const clubRole = convertUserTypesToClubRole(userType.value);

      const address: Address = {
        line1: addressLine1,
        city,
        country: country.value,
        zipCode: zipcode,
        ...(addressLine2 && { line2: addressLine2 }),
        ...(state && { state: state.value }),
      };

      const userPayload: DeepPartial<PatchBaseUser> = {
        birthDate,
        gender: gender.value,
        phoneNumber,
        ...(isAdult && {
          address,
        }),
        ...(!isAdult && {
          guardian: {
            address,
          },
        }),
      };

      try {
        await UserServices.updateUser(
          currentUser.id,
          userPayload,
          isBaseUser,
          userType.value
        );

        if (clubRole && club) {
          await requestToJoinClub({ clubId: club.value, role: clubRole });
        }

        await updateCurrentAccount();

        history.push('/v3/home-page');
      } catch {
        notification.error({
          message: 'Failed to complete registration',
        });
      }
    },
    [isAdult, currentUser]
  );

  const handleClubInputChange = useCallback((value: string) => {
    setClubSearchValue(value);
  }, []);

  useEffect(() => {
    setValue('state', null);
  }, [fields.country]);

  useEffect(() => {
    if (typeof currentUser.birthDate === 'string') {
      setValue('birthDate', currentUser.birthDate, { shouldValidate: true });
    }

    if (currentUser.phoneNumber) {
      setValue('phoneNumber', currentUser.phoneNumber, {
        shouldValidate: true,
      });
    }

    if (currentUser.gender) {
      const defaultGender = GENDER_OPTIONS.find(
        (gender) => gender.value === currentUser.gender
      );

      if (defaultGender) {
        setValue('gender', defaultGender, { shouldValidate: true });
      }
    }

    if (currentUser && currentUser.types?.length) {
      history.push('/v3/home-page');
    }
  }, [currentUser]);

  return (
    <S.Container>
      <S.Form onSubmit={handleSubmit(submitCompleteRegistrationForm)}>
        <BodyXL $color='brandPrimary' $isUpper>
          complete registration
        </BodyXL>
        <S.Subtitle>user info</S.Subtitle>
        <S.GroupFields>
          <Controller
            control={control}
            name='gender'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <Select
                required
                value={value}
                placeholder='Gender'
                name='gender'
                options={GENDER_OPTIONS}
                error={!!errors.gender}
                errorMessage={get(errors, 'gender.message')}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='userType'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <Select
                required
                value={value}
                placeholder='Role'
                name='user-role'
                options={USER_ROLES_OPTIONS}
                error={!!errors.userType}
                errorMessage={get(errors, 'userType.message')}
                onChange={onChange}
              />
            )}
          />
        </S.GroupFields>
        <S.GroupFields>
          <Controller
            control={control}
            name='birthDate'
            rules={{
              required: 'This field is required',
              validate: (birthDate) => {
                if (moment().isBefore(moment(birthDate))) {
                  return "Birth date cant't be greater than current date";
                }

                if (allowedRoleByAgeError?.length) {
                  return allowedRoleByAgeError;
                }

                return true;
              },
            }}
            render={({ value, onChange }) => (
              <InputDate
                isRequired
                value={value}
                id='birthDate'
                placeholder='Birthdate'
                label='Birthdate'
                containerClassName='user-info-input'
                error={!!allowedRoleByAgeError?.length || !!errors.birthDate}
                errorMessage={
                  allowedRoleByAgeError || get(errors, 'birthDate.message')
                }
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='phoneNumber'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputPhone
                isRequired
                value={value}
                id='phoneNumber'
                placeholder='+1 (___) ___-____'
                label='Phone number'
                onChange={onChange}
                error={!!errors.phoneNumber}
                errorMessage={get(errors, 'phoneNumber.message')}
                containerClassName='user-info-input'
              />
            )}
          />
        </S.GroupFields>
        <S.Subtitle>
          {isAdult ? 'address' : 'legal guardian address'}
        </S.Subtitle>
        <Controller
          control={control}
          name='addressLine1'
          rules={{ required: 'This field is required' }}
          render={({ value, onChange }) => (
            <InputText
              isRequired
              value={value}
              id='addressLine1'
              placeholder='Address line 1'
              label='Address line 1'
              containerClassName='user-info-input'
              error={!!errors.addressLine1}
              errorMessage={get(errors, 'addressLine1.message')}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name='addressLine2'
          render={({ value, onChange }) => (
            <InputText
              value={value}
              id='addressLine2'
              placeholder='Address line 2'
              label='Address line 2'
              containerClassName='user-info-input'
              onChange={onChange}
            />
          )}
        />
        <S.GroupFields>
          <Controller
            control={control}
            name='country'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <Select
                required
                isSearchable
                value={value}
                placeholder='Country'
                name='country'
                error={!!errors.country}
                errorMessage={get(errors, 'country.message')}
                options={getCountriesOptions()}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='state'
            rules={{
              required: false,
            }}
            render={({ value, onChange }) => (
              <Select
                required={!!countryStatesOptions.length}
                isDisabled={!countryStatesOptions.length}
                isSearchable
                value={value}
                placeholder='State'
                name='state'
                options={countryStatesOptions}
                error={!!errors.state}
                errorMessage={get(errors, 'state.message')}
                onChange={onChange}
              />
            )}
          />
        </S.GroupFields>
        <S.GroupFields>
          <Controller
            control={control}
            name='city'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputText
                isRequired
                value={value}
                id='city'
                placeholder='City'
                label='City'
                containerClassName='user-info-input'
                error={!!errors.city}
                errorMessage={get(errors, 'city.message')}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='zipcode'
            rules={{ required: 'This field is required' }}
            render={({ value, onChange }) => (
              <InputText
                isRequired
                value={value}
                id='zipcode'
                placeholder='Zipcode'
                label='Zipcode'
                containerClassName='user-info-input'
                error={!!errors.zipcode}
                errorMessage={get(errors, 'zipcode.message')}
                onChange={onChange}
              />
            )}
          />
        </S.GroupFields>
        <S.Subtitle>request to join</S.Subtitle>
        <Controller
          control={control}
          name='club'
          render={({ value, onChange }) => (
            <Select
              isLoading={isLoading}
              isSearchable
              value={value}
              placeholder='Club'
              name='club'
              isClearable
              options={clubsOptions}
              isDisabled={!isAccessedClubsSelection}
              onChange={onChange}
              onInputChange={handleClubInputChange}
            />
          )}
        />
        <FilledButtonWithIcon
          type='submit'
          disabled={!isValid || isRequiredStateField}
        >
          Submit
        </FilledButtonWithIcon>
      </S.Form>
    </S.Container>
  );
};

export default CompleteRegistration;
