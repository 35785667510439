import React, { FC, useCallback } from 'react';

import { User } from 'models/User/UserModel';
import { BaseUser } from 'redux/v3/baseUsers/types';
import { UserType } from 'redux/user/types';

import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';
import { useGetJoinedEventsInfinity } from 'hooks/v3/event/useGetJoinedEventsInfinity/useGetJoinedEventsInfinity';

import MyEventCard from 'components/v3/Cards/MyEventCard/MyEventCard';
import Loading from 'components/v3/Loading/Loading';
import { notification } from 'components/v3/Notification/notification';

import { DeepPartial } from 'util/types';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IMyEventsProps {
  currentUser?: DeepPartial<BaseUser>;
  users: User[];
}

const PAGE_SIZE = 10;

export const MyEvents: FC<IMyEventsProps> = ({ currentUser, users }) => {
  const { data, isFetching, fetchNextPage } = useGetJoinedEventsInfinity(
    PAGE_SIZE
  );

  const eventData = data?.pages.flatMap((page) => page.data.content);

  const currentPage = data?.pages[data.pages.length - 1]?.data?.page ?? 0;
  const total = data?.pages[0]?.data?.total ?? 0;

  const hasNextPage = PAGE_SIZE * (currentPage + 1) < total;

  const setIntersectionObserver = useInfiniteScroll(
    hasNextPage,
    isFetching,
    async () => {
      try {
        fetchNextPage();
      } catch {
        notification.error({ message: 'Failed to load more events!' });
      }
    }
  );

  const getRelatedUserToEvent = useCallback(
    (refereesIds: string[]) => {
      const linkedUsers = users.filter(
        (user) => currentUser?.id === user.baseUserId
      );

      const matchedUser = linkedUsers.find(
        (user) =>
          (refereesIds.includes(user.id) && user.type === UserType.REFEREE) ||
          user.type === UserType.COACH ||
          user.type === UserType.PLAYER
      );

      const invalidUser = users.find(
        (user) => !user.baseUserId && currentUser?.id === user.id
      );

      return matchedUser?.type || invalidUser?.type || null;
    },
    [currentUser, users]
  );

  return (
    <S.EventsList>
      {(!isFetching || !!eventData?.length) &&
        eventData?.map((event) => (
          <MyEventCard
            size='small'
            key={event.id}
            id={event.id}
            logo={event.logo}
            title={event.name}
            orientation='column'
            actionText='See event'
            eventStatus={event.draft ? 'draft' : 'published'}
            description={event.description}
            startDate={event.startDate}
            finalDate={event.endDate}
            link={`/v3/event/${event.id}`}
            currentUserType={getRelatedUserToEvent(event.referees ?? [])}
          />
        ))}
      {hasNextPage && <div ref={setIntersectionObserver} />}
      {isFetching && <Loading />}
      {!isFetching && !eventData?.length && (
        <BodyM>Join the Event on the Discover Event tab</BodyM>
      )}
    </S.EventsList>
  );
};
