import { IRoute } from 'routes/interfaces/route.interface';

import PlanSelection from 'pages/V3/draftEvent/PlanSelection';
import GeneralEvent from 'pages/V3/draftEvent/GeneralEvent';
import Payment from 'pages/V3/draftEvent/Payment';
import LandingPage from 'pages/V3/LandingPage';
import V3Login from 'pages/V3/V3Login';
import Home from 'pages/V3/Home';
import RedirectToHome from 'pages/V3/Home/redirect';
import MyEvents from 'pages/V3/setupEvent/MyEvents/MyEvents';
import EditEvent from 'pages/V3/setupEvent/EditEvent';
import ChoosePlan from 'pages/V3/profile/UpgradeMembership/ChoosePlan/ChoosePlan';
import ReleaseTermsPage from 'pages/V3/profile/UpgradeMembership/ReleaseTerms/ReleaseTerms';
import CovidTermsPage from 'pages/V3/profile/UpgradeMembership/CovidTerms/CovidTerms';
import OrderSummary from 'pages/V3/profile/UpgradeMembership/OrderSummary/OrderSummary';
import UpgradeMembershipPayment from 'pages/V3/profile/UpgradeMembership/Payment/Payment';
import NewUser from 'pages/V3/profile/NewUser/NewUser';
import UserCreated from 'pages/V3/profile/NewUser/UserCreated';
import Event from 'pages/V3/event/Event';
import Events from 'pages/V3/Events/Events';
import UserInfo from 'pages/V3/profile/CompleteProfile/UserInfo/UserInfo';
import LegalGuardian from 'pages/V3/profile/CompleteProfile/LegalGuardian/LegalGuardian';
import Address from 'pages/V3/profile/CompleteProfile/Address/Address';
import RefereeApplication from 'pages/V3/RefereeApplication';
import InformationScreen from 'pages/V3/RefereeApplication/InformationScreen';
import Documents from 'pages/V3/profile/CompleteProfile/Documents/Documents';
import V3Logout from 'pages/V3/V3Logout';
import CheckoutAsClubDirector from 'pages/V3/event/CheckoutAsClubDirector/CheckoutAsClubDirector';
import SignUpAsClubDirector from 'pages/V3/event/SignUpAsClubDirector/SignUpAsClubDirector';
import ClubDirectorPayment from 'pages/V3/event/ClubDirectorPayment/ClubDirectorPayment';
import Clubs from 'pages/V3/clubs/Clubs';
import FindClubs from 'pages/V3/clubs/Find';
import NewClub from 'pages/V3/clubs/New';
import PendingClub from 'pages/V3/clubs/Pending';
import EventManager from 'pages/V3/EventManager/EventManager';
import InviteClubs from 'pages/V3/Invites/Clubs/Clubs';
import InviteReferees from 'pages/V3/Invites/Referees/Referees';
import InviteEmail from 'pages/V3/Invites/Email/Email';
import ClubDashboard from 'pages/V3/ClubDashboard/ClubDashboard';
import Club from 'pages/V3/Club/Club';
import RosterEditPage from 'pages/V3/Roster/RosterEditPage';
import InviteMembersToClub from 'pages/V3/Invites/InviteMembersToClub/InviteMembersToClub';
import RosterDetails from 'pages/V3/Roster/RosterViewPage/components';
import MySettings from 'pages/V3/profile/MySettings/MySettings';
import Groups from 'pages/V3/EventManager/components/Groups';
import Notifications from 'pages/V3/Notifications';
import TeamPlacement from 'pages/V3/EventManager/components/TeamPlacement';
import FormatBrackets from 'pages/V3/setupEvent/FormatEvent/FormatBrackets';
import PrintAllTeamsList from 'pages/V3/Print/AllTeamsList/AllTeamsList';
import PrintAllRosters from 'pages/V3/Print/AllRosters/AllRosters';
import ScheduleByCourt from 'pages/V3/Print/ScheduleByCourt/ScheduleByCourt';
import Schedule from 'pages/V3/Schedule';
import ScheduleByTimePerLocation from 'pages/V3/Print/ScheduleByTimePerLocation/ScheduleByTimePerLocation';
import PrintAllBrackets from 'pages/V3/Print/AllBrackets/AllBrackets';
import PrintAllScoreSheet from 'pages/V3/Print/AllScoreSheet/AllScoreSheet';
import AllGames from 'pages/V3/Schedule/pages/AllGames';
import FindYourTeam from 'pages/V3/Schedule/pages/FindYourTeam';
import TeamView from 'pages/V3/Schedule/pages/TeamView';
import BracketResult from 'pages/V3/Schedule/pages/BracketResult';
import PrintAllGames from 'pages/V3/Schedule/pages/PrintAllGames';
import GamesControl from 'pages/V3/GamesControl/GamesControl';
import CompleteRegistration from 'pages/V3/CompleteRegistration/CompleteRegistration';

import { LayoutsName } from './enums/layouts-name.enum';

export const V3Routes: IRoute[] = [
  {
    path: '/v3',
    component: LandingPage,
    exact: true,
    private: false,
    layout: LayoutsName.Base,
  },
  {
    path: ['/v3/login', '/login'],
    component: V3Login,
    private: false,
    layout: LayoutsName.Base,
  },
  {
    path: '/v3/logout',
    component: V3Logout,
    private: false,
    layout: LayoutsName.Base,
  },
  {
    path: ['/v3/home', '/'],
    component: RedirectToHome,
    exact: true,
    private: false,
    layout: LayoutsName.Base,
  },
  {
    path: '/v3/home-page',
    component: Home,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/draft-event/plan-selection',
    component: PlanSelection,
    exact: true,
    private: false,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/draft-event/general-event',
    component: GeneralEvent,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/draft-event/payment',
    component: Payment,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/setup-event/my-events',
    component: MyEvents,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/setup-event/my-events/:eventId',
    component: EditEvent,
    exact: true,
    private: true,
    layout: LayoutsName.EditEvent,
  },
  {
    path: '/v3/event/:eventId/dashboard/brackets',
    component: FormatBrackets,
    exact: true,
    private: true,
    layout: LayoutsName.EditEvent,
  },
  {
    path: '/v3/profile/upgrade-membership',
    component: ChoosePlan,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/profile/upgrade-membership/release-terms',
    component: ReleaseTermsPage,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/profile/upgrade-membership/covid-terms',
    component: CovidTermsPage,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/profile/upgrade-membership/order-summary',
    component: OrderSummary,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/profile/upgrade-membership/payment',
    component: UpgradeMembershipPayment,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/profile/my-settings',
    component: MySettings,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/referee-application',
    component: RefereeApplication,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/referee-application-status',
    component: InformationScreen,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/referee-application/:eventId',
    component: RefereeApplication,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/events',
    exact: true,
    private: true,
    component: Events,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/event',
    exact: true,
    private: true,
    component: Events,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/event/:eventId',
    exact: true,
    private: true,
    component: Event,
    layout: LayoutsName.SetupEvent,
    noPaddingOnMobile: true,
  },
  {
    path: '/v3/event',
    exact: true,
    private: true,
    component: Event,
    layout: LayoutsName.SetupEvent,
  },
  {
    path:
      '/v3/event/:eventId/(signup-as-club-director|signup-as-team-manager|signup-as-club-director-or-team-manager)',
    component: SignUpAsClubDirector,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path:
      '/v3/event/:eventId/(checkout-as-club-director|checkout-as-team-manager|checkout-as-club-director-or-team-manager)',
    component: CheckoutAsClubDirector,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/event/:eventId/club-director-payment',
    component: ClubDirectorPayment,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/event/:eventId/signup-as-referee',
    component: RefereeApplication,
    exact: true,
    private: true,
    layout: LayoutsName.Profile,
  },
  {
    path: '/v3/user',
    component: NewUser,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/user/new',
    component: NewUser,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/user/:userId/created',
    component: UserCreated,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/user/:userId/info',
    component: UserInfo,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/user/:userId/legal-guardian',
    component: LegalGuardian,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/user/:userId/address',
    component: Address,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/user/:userId/documents',
    component: Documents,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/clubs',
    component: Clubs,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/clubs/find',
    component: FindClubs,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/clubs/new',
    component: NewClub,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/clubs/:rosterId/dashboard/roster',
    component: RosterDetails,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/clubs/:clubId/dashboard/:tabSlug/:subTabSlug',
    component: ClubDashboard,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/clubs/:clubId/pending',
    component: PendingClub,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/event/:eventId/dashboard',
    component: EventManager,
    exact: true,
    private: true,
    layout: LayoutsName.EventDashboard,
  },
  {
    path: '/v3/event/:eventId/games/:gameId/scoresheet',
    component: GamesControl,
    exact: true,
    private: true,
    layout: LayoutsName.EventDashboard,
  },
  {
    path: '/v3/roster',
    component: Clubs,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/roster/:rosterId/edit',
    component: RosterEditPage,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/invites/clubs/:eventId',
    component: InviteClubs,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/invites/clubs/',
    component: InviteClubs,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/invites/referees/:eventId',
    component: InviteReferees,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/invites/referees/',
    component: InviteReferees,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/invites/email',
    component: InviteEmail,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/club/:clubId',
    component: Club,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/club/',
    component: Club,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/invites/members/club/:clubId',
    component: InviteMembersToClub,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/invites/members/club',
    component: InviteMembersToClub,
    exact: true,
    private: true,
    layout: LayoutsName.DraftEvent,
  },
  {
    path: '/v3/event/:eventId/dashboard/groups',
    component: Groups,
    exact: true,
    private: true,
    layout: LayoutsName.EditEvent,
  },
  {
    path: '/v3/event/dashboard/groups',
    component: Groups,
    exact: true,
    private: true,
    layout: LayoutsName.EditEvent,
  },
  {
    path: '/v3/notifications',
    component: Notifications,
    exact: true,
    private: true,
    layout: LayoutsName.SetupEvent,
  },
  {
    path: '/v3/team-placement/:eventId',
    component: TeamPlacement,
    exact: true,
    private: true,
    layout: LayoutsName.EditEvent,
  },
  // ENG-320 temporary disable Team placement
  // {
  //   path: '/v3/event/:eventId/dashboard/team-placement',
  //   component: TeamPlacement,
  //   exact: true,
  //   private: true,
  //   layout: LayoutsName.EditEvent,
  // },
  {
    path: '/v3/print/event',
    component: ScheduleByCourt,
    exact: true,
    private: true,
  },
  {
    path: '/v3/print/event/:eventId/schedule-by-court',
    component: ScheduleByCourt,
    exact: true,
    private: true,
    layout: LayoutsName.Print,
  },
  {
    path: '/v3/print/event/:eventId/schedule-by-time-per-location',
    component: ScheduleByTimePerLocation,
    exact: true,
    private: true,
    layout: LayoutsName.Print,
  },
  {
    path: '/v3/print/event/:eventId/all-teams',
    component: PrintAllTeamsList,
    exact: true,
    private: true,
    layout: LayoutsName.Print,
  },
  {
    path: '/v3/print/event/:eventId/all-rosters',
    component: PrintAllRosters,
    exact: true,
    private: true,
    layout: LayoutsName.Print,
  },
  {
    path: '/v3/print/event/:eventId/all-scoresheet',
    component: PrintAllScoreSheet,
    exact: true,
    private: true,
    layout: LayoutsName.Print,
  },
  {
    path: '/v3/print/event/:eventId/all-brackets',
    component: PrintAllBrackets,
    exact: true,
    private: true,
    layout: LayoutsName.Print,
  },
  {
    path: '/v3/event/:eventId/schedule',
    exact: true,
    private: false,
    component: Schedule,
    layout: LayoutsName.Base,
  },
  {
    path: '/v3/event/:eventId/schedule/see-all-games',
    exact: true,
    private: false,
    component: AllGames,
    layout: LayoutsName.Base,
  },
  {
    path: '/v3/event/:eventId/schedule/print-all-games',
    exact: true,
    private: true,
    component: PrintAllGames,
    layout: LayoutsName.BaseDesktop,
  },
  {
    path: '/v3/event/:eventId/schedule/find-your-team',
    component: FindYourTeam,
    exact: true,
    private: false,
  },
  {
    path: '/v3/event/:eventId/schedule/team/:clubId',
    component: TeamView,
    exact: true,
    private: false,
  },
  {
    path: '/v3/event/:eventId/schedule/bracket-age-division',
    component: BracketResult,
    exact: true,
    private: false,
  },
  {
    path: '/v3/complete-registration',
    component: CompleteRegistration,
    layout: LayoutsName.SetupEvent,
    private: false,
  },
];
