export const DATE_SEASONS = [
  {
    season: 1,
    startDate: '2020-09-01T00:00:00.000+00:00',
    endDate: '2021-08-31T00:00:00.000+00:00',
  },
  {
    season: 2,
    startDate: '2021-09-01T00:00:00.000+00:00',
    endDate: '2022-08-31T00:00:00.000+00:00',
  },
  {
    season: 3,
    startDate: '2022-09-01T00:00:00.000+00:00',
    endDate: '2023-08-31T00:00:00.000+00:00',
  },
  {
    season: 4,
    startDate: '2023-09-01T00:00:00.000+00:00',
    endDate: '2024-08-31T00:00:00.000+00:00',
  },
  {
    season: 5,
    startDate: '2024-09-01T00:00:00.000+00:00',
    endDate: '2025-08-31T00:00:00.000+00:00',
  },
];

export const INITIAL_RANKINGS_OPTIONS = {
  eventIdList: [],
  actionList: [],
  userTypeList: ['PLAYER'],
  ageDivisionList: [],
  specialDivision: '',
  timeFrame: 'SEASON',
  season: 5,
};
