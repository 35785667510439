import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PeoplePlusOne, Plus } from '@icon-park/react';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

export enum TabContentType {
  Clubs = 'clubs',
  Referees = 'referees',
  Invitations = 'invitations',
}

interface IExtraButtonsProps {
  type: TabContentType;
}

export const ExtraButtons: FC<IExtraButtonsProps> = ({ type }) => {
  const params: { clubId: string } = useParams();

  const history = useHistory();

  const handleInviteMembers = () => {
    history.push(`/v3/invites/members/club/${params.clubId}`);
  };

  if (type === TabContentType.Clubs) {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
        Invite club
      </OutlinedButtonWithIcon>
    );
  }

  if (type === TabContentType.Referees) {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
        Invite referee
      </OutlinedButtonWithIcon>
    );
  }

  if (type === TabContentType.Invitations) {
    return (
      <OutlinedButtonWithIcon
        onClick={handleInviteMembers}
        color='white-dark'
        isUpper
        customIcon={<Plus />}
      >
        Invite Member
      </OutlinedButtonWithIcon>
    );
  }

  return null;
};
