import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import { TABS_KEYS } from 'pages/V3/ClubDashboard/tab-keys';
import { Header } from './Header/Header';
import { Tabs, TabsProps } from './Tabs/Tabs';
import * as S from './styles';
import Loading from '../Loading/Loading';
import TextButtonWithIcon from '../Buttons/TextButtonWithIcon';

export const ClubManagerDashboard = ({ tabs }: TabsProps) => {
  const history = useHistory();
  const location = useLocation();
  const params: { clubId: string, tabSlug: string } = useParams();
  const { data: currentClub } = useGetClubById(params.clubId);

  const defaultSelectedTab = (location.state as any)?.tab === 'teams-rosters' ? TABS_KEYS.TEAMS_AND_ROSTERS : TABS_KEYS.MEMBERS;

  const { isMemberOfThisClub } = useClubPermission(currentClub);

  if (!currentClub) return <Loading />;

  const handleGoBack = () => {
    history.push('/v3/clubs');
  };

  if (!isMemberOfThisClub) {
    history.push(`/v3/club/${currentClub.id}`);
  }

  const handleChangeTab = (tab: string) => {
    if(tab === 'members') {
      history.push(
        `/v3/clubs/${params.clubId}/dashboard/members/members-pool`
      );
    } else {
      history.push(
        `/v3/clubs/${params.clubId}/dashboard/teams-and-rosters/open`
      );
    }
  }

  return (
    <>
      <TextButtonWithIcon
        reverse
        icon='back'
        align='flex-end'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <S.Container>
        <Header club={currentClub} maxWidth={false} />
        <Tabs
          tabs={tabs}
          active={params.tabSlug ?? ''}
          handleChangeTab={handleChangeTab}
          defaultActiveKey={defaultSelectedTab}
        />
      </S.Container>
    </>
  );
};
