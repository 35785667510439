import React, { FC, useState } from 'react';
import { Divider } from 'antd';

import { Table } from 'components/v3/Table/Table';

import { formatUserName } from 'util/formatUserName';

import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';
import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';
import { Filters } from '../../../Filters/Filters';
import { IFiltrationState } from '../../interfaces';
import { columns } from './columns';

interface IRefereesTableProps {
  id: string;
}

export const RefereesTable: FC<IRefereesTableProps> = ({ id }) => {
  const [filtration, setFiltration] = useState<IFiltrationState>({});
  const debounceSearch = useDebounce(filtration.search, 400);

  const { data } = useGetEventInvites(id, EventInvitesTypes.EVENT_TO_REFEREE, {
    name: debounceSearch,
    page: 1,
    size: 20,
    status: filtration?.status,
  });

  const uniqueRefereeSet = new Set<string>();

  const newReferees = data?.filter((invite) => {
    if (!invite.referee) return true;

    const refereeName = formatUserName({ ...invite.referee });

    if (uniqueRefereeSet.has(refereeName)) return false;

    uniqueRefereeSet.add(refereeName);
    return true;
  });

  const handleChangeFilters = (value: IFiltrationState) => {
    setFiltration({ ...filtration, ...value });
  };

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Filters filters={filtration} onSetFiltration={handleChangeFilters} />
      <Table
        columns={columns}
        dataSource={newReferees}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};
