import React, { FC, useEffect, useMemo } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import Keycloak from 'keycloak-js';

import UserType from 'models/User/UserTypeModel';

import { useProfile } from 'hooks/v3/profile/useProfile';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics/useGoogleAnalytics';

import UserSelection from 'pages/UserSelection/UserSelection.page';

import { getUserId } from 'util/UsersUtil';

interface IPrivateRouteProps {
  auth: Keycloak.KeycloakInstance | undefined;
  render: React.FC;
  path: string;
  exact: boolean;
  userSelectedNotRequired?: boolean;
}

const PrivateRoute: FC<IPrivateRouteProps> = ({
  auth,
  path,
  exact,
  userSelectedNotRequired,
  render,
}) => {
  useGoogleAnalytics();

  const { currentUser } = useProfile();

  const location = useLocation();
  const history = useHistory();

  const isV3Route = useMemo(() => {
    return location.pathname.includes('v3/');
  }, [location.pathname]);

  const isStuff = useMemo(() => {
    return (
      currentUser.type === UserType.STAFF ||
      currentUser.types?.includes(UserType.STAFF)
    );
  }, [currentUser]);

  const isReferee = useMemo(() => {
    return (
      currentUser.type === UserType.REFEREE ||
      currentUser.types?.includes(UserType.REFEREE)
    );
  }, [currentUser]);

  useEffect(() => {
    if (auth?.isTokenExpired()) {
      auth
        .updateToken(5)
        .then(() => {
          localStorage.setItem('kc_token', auth.token || '');
          localStorage.setItem('kc_refreshToken', auth.refreshToken || '');
        })
        .catch(() => {
          localStorage.setItem('kc_token', '');
          localStorage.setItem('kc_refreshToken', '');

          history.push('/v3/logout');
        });
    }
  }, [auth, currentUser, history]);

  if (auth?.authenticated) {
    if (!isV3Route) {
      const selectedUser = getUserId();

      if (
        (!userSelectedNotRequired && !selectedUser) ||
        (!userSelectedNotRequired && selectedUser === null)
      ) {
        return <UserSelection />;
      }

      if (!isStuff && !isReferee) {
        return (
          <Redirect
            to={{
              pathname: '/v3/home-page',
              state: { from: location },
            }}
          />
        );
      }
    }

    if (currentUser && currentUser.types?.length === 0) {
      return (
        <Redirect
          to={{
            pathname: '/v3/complete-registration',
          }}
        />
      );
    }
    return <Route path={path} exact={exact} component={render} />;
  }

  return (
    <Redirect
      to={{
        pathname: '/v3/login',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
