import React, { FC, useCallback, useState } from 'react';
import { Divider } from 'antd';

import { EventInvitesTypes } from 'models/v3/EventInvite/EventInvitesModel';

import { useGetEventInvites } from 'hooks/v3/event/useGetEventInvites/useGetEventInvites';

import { Table } from 'components/v3/Table/Table';

import { useDebounce } from 'hooks/useDebounce/useDebounce';
import { Filters } from '../../../Filters/Filters';
import { useClubDataFilter } from '../../../../hooks';
import { IFiltrationState } from '../../interfaces';

import { columns } from './columns';

interface IClubsTableProps {
  id: string;
}

export const ClubsTable: FC<IClubsTableProps> = ({ id }) => {
  const [filtration, setFiltration] = useState<IFiltrationState>({});
  const debounceSearch = useDebounce(filtration.search, 400);

  const { data } = useGetEventInvites(id, EventInvitesTypes.EVENT_TO_CLUB, {
    page: 0,
    size: 20,
    name: debounceSearch,
    status: filtration?.status,
  });

  const uniqueClubsSet = new Set<string>();

  const newClubs = data?.filter((invite) => {
    if (!invite.club) return true;

    const clubName = invite.club.name;

    if (uniqueClubsSet.has(clubName)) return false;

    uniqueClubsSet.add(clubName);

    return true;
  });

  const filteredClubs = useClubDataFilter({
    clubs: newClubs,
    searchValue: filtration.search,
    statusValue: filtration.status,
  });

  const handleSetFiltration = useCallback(
    (value: IFiltrationState) => {
      setFiltration({ ...filtration, ...value });
    },
    [filtration]
  );

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Filters filters={filtration} onSetFiltration={handleSetFiltration} />
      <Table
        columns={columns}
        dataSource={filteredClubs}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};
