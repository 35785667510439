import React, { FC, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import ReleaseTerms from 'pages/Terms/ReleaseTerms';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import TemplateReadAndAcceptDocument from 'components/v3/TemplateReadAndAcceptDocument/TemplateReadAndAcceptDocument';

import { BodyM, BodyS, BodySBold, TitleH1 } from 'styles/v3/variables';

import * as S from './styles';

const ReleaseTermsPage: FC = () => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <S.ReleaseTermsWrapper>
      <TextButtonWithIcon
        color='light'
        align='flex-end'
        reverse
        icon='back'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <TitleH1 $isUpper $color='brandPrimary'>
        Release & Waiver of Liability
      </TitleH1>
      <BodyM $color='grey300' className='caption'>
        You Must Read This Document in its Entirety to Proceed
      </BodyM>
      <TemplateReadAndAcceptDocument
        setRead={() => {}}
        buttonText='Agree and continue upgrade'
        link='/v3/profile/upgrade-membership/covid-terms'
        infoText={
          <BodyS $spanColor='brandPrimary' $color='grey200'>
            By clicking accept you authorize United Futsal to{' '}
            <span>authenticate</span> the requested documentation and accept and
            acknowledge the <BodySBold>Release & Liability Waiver</BodySBold>{' '}
            presented to you during the application process.
          </BodyS>
        }
        label='I AGREE to ALL terms and conditions set forth in the Release & Waiver of Liability, Assumption of the Risk, Indemnity & Parental Consent Agreement I just read.'
      >
        <ReleaseTerms />
      </TemplateReadAndAcceptDocument>
    </S.ReleaseTermsWrapper>
  );
};

export default withRouter(ReleaseTermsPage);
