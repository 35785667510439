import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PreviewOpen } from '@icon-park/react';
import { Divider } from 'antd';

import { RequestStatus } from 'models/Request/RequestModel';
import { User } from 'models/User/UserModel';
import UserType from 'models/User/UserTypeModel';
import { NewRoaster } from 'services/v3/Rosters/RosterService';
import { RosterStatus } from 'services/v3/Rosters/enums';

import { useGetUserDocuments } from 'hooks/v3/users/useGetUserDocuments/useGetUserDocuments';
import { useUpdatePlayerNumber  } from 'hooks/v3/rosters/useUpdatePlayerNumber/useUpdatePlayerNumber';
import { useUpdateEditedRosterStatus } from 'hooks/v3/rosters/useUpdateEditedRosterStatus/useUpdateEditedRosterStatus';

import InputText from 'components/v3/Forms/InputText/InputText';
import IconButton from 'components/v3/Buttons/IconButton';
import OutlinedButton from 'components/v3/Buttons/OutlinedButton';
import { DocumentStatusViewModal } from 'components/v3/ModalInformations/DocumentStatusViewModal/DocumentStatusViewModal';

import { BodyLBold, BodyM, BodyS, COLORS, ColorsInterface } from 'styles/v3/variables';

import ModalHeaderMemberInfo from '../ModalHeaderMemberInfo';

import { IUserRosterError } from '../../../types';

import {
  Container,
  UserDocumentsInformation,
  UserDocuments,
  BreadInfo,
} from './styles';

interface IModalBodyMemberInfoProps {
  member: User;
  rosterDetailed: Maybe<NewRoaster>;
  isLockedRoster?: boolean;
  handleRemoveUser: () => Promise<void>;
  onResetUserErrors: (userId: string, param: keyof IUserRosterError, value: boolean) => void;
}

const ModalBodyMemberInfo: FC<IModalBodyMemberInfoProps> = ({
  member,
  rosterDetailed,
  isLockedRoster = false,
  handleRemoveUser,
  onResetUserErrors,
}) => {
  const [modalPhotoOpen, setModalPhotoOpen] = useState(false);
  const [modalAgeOpen, setModalAgeOpen] = useState(false);
  const [modalSafeSportOpen, setModalSafeSportOpen] = useState(false);

  const { data: userInfo } = useGetUserDocuments(member.id);
  const { mutateAsync: updatePlayerNumber, isLoading } = useUpdatePlayerNumber()
  const { mutateAsync: updateEditedRosterStatus } = useUpdateEditedRosterStatus();

  const jerseyStoredNumber = useMemo(() => {
    return rosterDetailed?.players.find(player => player.id === member.id)?.number
  }, [rosterDetailed, member]);

  const [jerseyValue, setJerseyValue] = useState<Maybe<number>>(null);

  const statusVerifiedColor = (status: RequestStatus): keyof ColorsInterface => {
    if (status === RequestStatus.APPROVED) {
      return 'brandPrimary';
    }

    if (status === RequestStatus.DECLINED) {
      return 'supportError';
    }

    return 'supportNeutral';
  }

  const safeSportView = (type?: UserType) => {
    if (type === UserType.PLAYER) {
      return (
        <>
          <BodyM $color='grey700'>Safesport</BodyM>
          <BodyM $color='grey700'>No Documents</BodyM>
        </>
      );
    }

    return (
      <>
        <BodyM $color='grey200'>Safesport</BodyM>
        <BreadInfo color={statusVerifiedColor(member.documentationVerified as RequestStatus)}>
          <BodyS $color={statusVerifiedColor(member.documentationVerified as RequestStatus)}>{member.documentationVerified}</BodyS>
        </BreadInfo>
        <IconButton
          icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey700 }}
          onClick={() => setModalSafeSportOpen(true)}
        />
      </>
    )
  }

  const handleChangeJerseyNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const sanitizedValue = value.replace(/[^0-9]/g, '');

    setJerseyValue(Number(sanitizedValue));
  }, []);

  const handleStoreJerseyNumber = useCallback(() => {
    if (rosterDetailed?.id && !isLoading && jerseyValue !== jerseyStoredNumber && jerseyValue !== null) {
      updatePlayerNumber({
        number: jerseyValue,
        playerId: member.id,
        rosterId: rosterDetailed.id,
      })
      .then(() => {
        if (rosterDetailed.status === RosterStatus.Submitted && rosterDetailed.id) {
          updateEditedRosterStatus(rosterDetailed.id);
        }

        onResetUserErrors(member.id, 'jerseyNumber', false);
      })
      .catch(() => {
        setJerseyValue(jerseyStoredNumber ?? null);
      })
    }

    if (jerseyStoredNumber === jerseyValue) {
      setJerseyValue(jerseyStoredNumber);
    }
  }, [member, rosterDetailed, jerseyStoredNumber, jerseyValue, isLoading, onResetUserErrors]);

  useEffect(() => {
    setJerseyValue(jerseyStoredNumber ?? null);
  }, [jerseyStoredNumber]);

  return (
    <>
      <Container>
        <ModalHeaderMemberInfo member={member} />
        <BodyLBold $color='white'>Contact Info</BodyLBold>
        {member.type === UserType.PLAYER && (
          <InputText
            id='jerseyNumber'
            label='Jersey Number'
            placeholder='Jersey Number'
            value={jerseyValue ?? ''}
            className='general-event-input'
            disabled={isLoading || isLockedRoster}
            onChange={handleChangeJerseyNumber}
            onBlur={handleStoreJerseyNumber}
          />
        )}
        <Divider style={{ margin: 0 }} />
        <BodyLBold $color='white'>Documents</BodyLBold>
        <UserDocumentsInformation>
          <UserDocuments>
            <BodyM $color='grey200'>Photo</BodyM>
            <BreadInfo color={statusVerifiedColor(member.photoVerified as RequestStatus)}>
              <BodyS $color={statusVerifiedColor(member.photoVerified as RequestStatus)}>{member.photoVerified}</BodyS>
            </BreadInfo>
            <IconButton
              icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
              style={{ backgroundColor: COLORS.grey700 }}
              onClick={() => setModalPhotoOpen(true)}
            />
          </UserDocuments>
          {member.type !== UserType.COACH && (
            <UserDocuments>
              <BodyM $color='grey200'>Proof of Age</BodyM>
              <BreadInfo color={statusVerifiedColor(member.ageVerified as RequestStatus)}>
                <BodyS $color={statusVerifiedColor(member.ageVerified as RequestStatus)}>{member.ageVerified}</BodyS>
              </BreadInfo>
              <IconButton
                icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
                style={{ backgroundColor: COLORS.grey700 }}
                onClick={() => setModalAgeOpen(true)}
              />
            </UserDocuments>
          )}
          <UserDocuments>
            {safeSportView(member.type)}
          </UserDocuments>
        </UserDocumentsInformation>
        <Divider style={{ margin: 0 }} />
        <OutlinedButton
          color='white-dark'
          isUpper
          isBold={false}
          disabled={isLockedRoster}
          onClick={handleRemoveUser}
        >
          Remove from Roster
        </OutlinedButton>
      </Container>
      <DocumentStatusViewModal
        isOpen={modalAgeOpen}
        documentName='Proof of Age'
        documentStatus={member.ageVerified as RequestStatus}
        urlImg={userInfo?.ageProofUser as string}
        handleIsOpen={setModalAgeOpen}
      />
      <DocumentStatusViewModal
        isOpen={modalPhotoOpen}
        documentName='Photo'
        documentStatus={member.photoVerified as RequestStatus}
        isPhoto
        urlImg={userInfo?.photoUser as string}
        handleIsOpen={setModalPhotoOpen}
      />
      <DocumentStatusViewModal
        isOpen={modalSafeSportOpen}
        documentName='SafeSport'
        documentStatus={member.documentationVerified as RequestStatus}
        urlImg={userInfo?.safeSSportUser as string}
        handleIsOpen={setModalSafeSportOpen}
      />
    </>
  );
};

export default ModalBodyMemberInfo;
