import styled from 'styled-components';

import { Collapse } from 'antd';
import { COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { BREAKPOINTS } from 'styles/v3/constants';

export const AccountsCollapse = styled(Collapse)`
  width: 100%;
  border-radius: 12px;
  border: none !important;

  background-color: ${COLORS.grey950};

  .ant-collapse > .ant-collapse-item {
    border: none !important;
  }
`;

export const AccountsCollapsePanel = styled(Collapse.Panel)`
  border: none;
  color: ${COLORS.grey50};

  .ant-collapse-content-box {
    overflow-y: auto;
    max-height: ${toRem(300)};

    padding-top: 4px;

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    ::-webkit-scrollbar-track:active {
      background: #333333;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    flex-direction: row;

    min-height: 57px;

    p {
      flex: 1;
    }
  }

  .ant-collapse-extra {
    display: flex;
    margin-right: 14px;
  }

  .ant-collapse-content-box {
    padding: 0;
    background-color: ${COLORS.grey950};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .ant-collapse-content-box {
      overflow-y: hidden;
      max-height: unset;

      padding-top: 4px;
    }
  }
`;

export const AccountsCollapseMain = styled.div`
  padding: 20px 16px 20px 12px;
  border-bottom: 1px solid #434343;
`;

export const AccountsCollapseFooter = styled.div`
  padding: 20px;

  button {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 5%;
    line-height: 19.1px;
    text-transform: uppercase;
  }
`;

export const AccountsCollapseAddUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  gap: 24px;

  button {
    background-color: ${COLORS.grey800} !important; // Due to nesty restriction
    margin-bottom: 0 !important; // Due to nesty restriction
  }
`;

export const AddUserButton = styled.button`
  border: none;
  border-radius: 10px;

  cursor: pointer;

  width: 34px;
  height: 33px;
  padding: 5.4px 0px;

  background-color: ${COLORS.grey800};
`;
