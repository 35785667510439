import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { B2bEventStatus } from 'models/v3/Event/Event';

import { ApplicationState } from 'redux/store';
import { B2bEventItem } from 'redux/v3/b2bSetupEvent/types';
import { b2bSetupEventFetchEventsSuccess } from 'redux/v3/b2bSetupEvent/actions';

import { useGetCreatedByMyEventsInfinity } from 'hooks/v3/event/useGetCreatedByMyEventsInfinity/useGetCreatedByMyEvents';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';

import { EventPublishedStatus } from 'admin/models/event/Event';
import EventCard from 'components/v3/Cards/EventCard/EventCard';
import Loading from 'components/v3/Loading/Loading';
import { notification } from 'components/v3/Notification/notification';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

const PAGE_SIZE = 10;

export const CreatedByMe: FC = () => {
  const {
    data,
    isFetching,
    hasPreviousPage,
    fetchNextPage,
  } = useGetCreatedByMyEventsInfinity(PAGE_SIZE);

  const dispatch = useDispatch();

  const { content: storedEvents } = useSelector(
    (state: ApplicationState) => state.b2bSetupEvent.data
  );

  const eventData = data?.pages.flatMap((page) => page.data.content);

  const currentPage = data?.pages[data.pages.length - 1]?.data?.page ?? 0;
  const total = data?.pages[0]?.data?.total ?? 0;

  const hasNextPage = PAGE_SIZE * (currentPage + 1) < total;

  const setIntersectionObserver = useInfiniteScroll(
    hasNextPage,
    isFetching,
    async () => {
      try {
        await fetchNextPage();
      } catch {
        notification.error({ message: 'Failed to load more events!' });
      }
    }
  );

  const getEventStatus = (currentEvent: B2bEventItem): B2bEventStatus => {
    if (!currentEvent?.data?.eventPublished) return 'draft';

    switch (currentEvent.data.eventPublished) {
      case EventPublishedStatus.PUBLISHED:
        return 'published';
      case EventPublishedStatus.PENDING:
        return 'waiting approval';
      case EventPublishedStatus.EXPIRED:
        return 'expired';
      default:
        return 'draft';
    }
  };

  useEffect(() => {
    if (eventData) {
      dispatch(
        b2bSetupEventFetchEventsSuccess({
          content: eventData,
          hasNextPage: !!hasNextPage,
          hasPreviousPage: !!hasPreviousPage,
          page: currentPage,
          pageSize: PAGE_SIZE,
          total,
        })
      );
    }
  }, [data, hasNextPage, currentPage, hasPreviousPage, dispatch]);

  return (
    <>
      {(!isFetching || !!storedEvents.length) && (
        <S.EventsList>
          {storedEvents?.map((i) => {
            const eventStatus = getEventStatus(i);
            const linkToRedirect =
              eventStatus === 'published'
                ? `/v3/event/${i.id}/dashboard`
                : `/v3/setup-event/my-events/${i.id}`;

            return (
              <EventCard
                key={i.id}
                eventStatus={eventStatus}
                orientation='column'
                size='small'
                title={i.name}
                description={i.description}
                startDate={i.startDate}
                finalDate={i.endDate}
                id={i.id}
                logo={i.logo}
                divisions={i.ageDivisions}
                link={linkToRedirect}
              />
            );
          })}
        </S.EventsList>
      )}
      {hasNextPage && <div ref={setIntersectionObserver} />}
      {isFetching && <Loading />}
      {!isFetching && !storedEvents.length && (
        <BodyM>No events of your own. Create a new event</BodyM>
      )}
    </>
  );
};
