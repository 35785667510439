import React from 'react';
import { PeoplePlusOne, Plus } from '@icon-park/react';
import { useHistory, useParams } from 'react-router-dom';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import { BodyLBold } from 'styles/v3/variables';

import MyRosters from './components/RoastersOpen/components/RoastersForClub/MyRosters';
import AllOpenedRosters from './components/RoastersOpen/components/RoastersForClub/AllOpenedRosters';

import * as S from './styles';

type ExtraButtonsProps = {
  type: 'clubs' | 'referees';
};

const SUB_TAB_KEYS = {
  YOUR_ROSTERS: 'Your Rosters',
  OPEN: 'Open',
};

const tabPath = {
  YOUR_ROSTERS: 'your-rosters',
  OPEN: 'open',
};

const ExtraButtons = ({ type }: ExtraButtonsProps) => {
  if (type === 'clubs') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<Plus />}>
        Invite club
      </OutlinedButtonWithIcon>
    );
  }

  if (type === 'referees') {
    return (
      <OutlinedButtonWithIcon isUpper customIcon={<PeoplePlusOne />}>
        Invite referee
      </OutlinedButtonWithIcon>
    );
  }

  return null;
};

export const MemberTeamsAndRoster = () => {
  const history = useHistory();

  const params: {
    clubId: string,
    tabSlug: string,
    subTabSlug: string,
  } = useParams();
  
  const handleOnChange = (key: string) => {
    history.push(
      `/v3/clubs/${params.clubId}/dashboard/teams-and-rosters/${key}`
    );
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        destroyInactiveTabPane
        activeKey={params.subTabSlug}
        onChange={handleOnChange}
        tabBarExtraContent={<ExtraButtons type={params.subTabSlug as any} />}
      >
        <S.TabPane
          key={tabPath.YOUR_ROSTERS}
          tab={<BodyLBold>{SUB_TAB_KEYS.YOUR_ROSTERS}</BodyLBold>}
        >
          <MyRosters />
        </S.TabPane>
        <S.TabPane
          key={tabPath.OPEN}
          tab={<BodyLBold>{SUB_TAB_KEYS.OPEN}</BodyLBold>}
        >
          <AllOpenedRosters />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};