import React from 'react';

import { BodyLBold } from 'styles/v3/variables';

import { useHistory, useParams } from 'react-router-dom';
import { ExtraButtons, TabContentType } from './components';
import { SUB_TAB_KEYS } from './sub-tab-keys';
import { MembersPoolTab } from './components/MembersPoolTab/MembersPoolTab';
import { InvitationsTab } from './components/InvitationsTab/InvitationsTab';
import { ApplicationsTab } from './components/ApplicationsTab/ApplicationsTab';

import * as S from './styles';

export const subTabs = {
  MEMBERS_POOL: 'members-pool',
  APPLICATIONS: 'applications',
  INVITATIONS: 'invitations',
};

export const ClubDirectorMembers = () => {
  const history = useHistory();

  const params: {
    clubId: string,
    tabSlug: string,
    subTabSlug: TabContentType,
  } = useParams();

  const handleOnChange = (key: string) => {
    history.push(`/v3/clubs/${params.clubId}/dashboard/members/${key}`);
  };

  return (
    <S.Container>
      <S.Tabs
        type='card'
        activeKey={params.subTabSlug}
        onChange={handleOnChange}
        destroyInactiveTabPane
        tabBarExtraContent={<ExtraButtons type={params.subTabSlug} />}
      >
        <S.TabPane
          key={subTabs.MEMBERS_POOL}
          tab={<BodyLBold>{SUB_TAB_KEYS.MEMBERS_POOL}</BodyLBold>}
        >
          <MembersPoolTab />
        </S.TabPane>
        <S.TabPane
          key={subTabs.APPLICATIONS}
          tab={<BodyLBold>{SUB_TAB_KEYS.APPLICATIONS}</BodyLBold>}
        >
          <ApplicationsTab />
        </S.TabPane>
        <S.TabPane
          key={subTabs.INVITATIONS}
          tab={<BodyLBold>{SUB_TAB_KEYS.INVITATIONS}</BodyLBold>}
        >
          <InvitationsTab />
        </S.TabPane>
      </S.Tabs>
    </S.Container>
  );
};
