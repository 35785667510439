import React, { FC, useState } from 'react';
import { notification, Tooltip } from 'antd';
import styled from 'styled-components';

import RosterService from 'services/Rosters/RosterService';

import { RosterListing } from '../../../models/Roster/RosterModel';
import EventService from '../../../services/Event/EventService';
import ConfirmBox from '../../base/ConfirmBox/ConfirmBox';

interface RosterApprovalButtonsProps {
  active?: boolean;
  actionsEnabled?: boolean;
  roster: RosterListing;
  notSubmitted?: boolean;
  reloadList?: () => void;
  openRosterDetails: (rosterId: string) => void;
}

const RosterApprovalButtons: FC<RosterApprovalButtonsProps> = (
  props
) => {
  const { roster, actionsEnabled, reloadList, notSubmitted } = props;

  const [confirmPaymentOpen, setConfirmPaymentOpen] = useState(false);
  const [confirmApprovalOpen, setConfirmApprovalOpen] = useState(false);
  const [confirmDeclineOpen, setConfirmDeclineOpen] = useState(false);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

  const approvePayment = () => {
    setConfirmPaymentOpen(true);
  };

  const approveConfirmation = () => {
    setConfirmApprovalOpen(true);
  };

  const approveRosterPayment = async () => {
    try {
      const response = await EventService.approveRosterPayment(roster.id);

      if (!response.success) {
        notification.error({ message: response.message
          ? response.message
          : 'Failed to approve roster payment'
        });
      } else {
        reloadList?.call(null);
        setConfirmPaymentOpen(false);
        notification.success({ message: 'Roster payment approved successfully' })
      }
    } catch(e) {
      notification.error({ message: 'Failed to approve roster payment' });
    }
  };

  const approveRoster = async () => {
    try {
      const response = await EventService.approveRoster(roster.id);

      if (!response.success) {
        notification.error({ message: response.message
          ? response.message
          : 'Failed to approve roster'
        });
      } else {
        reloadList?.call(null);
        setConfirmApprovalOpen(false);
        notification.success({ message: 'Roster approved successfully' });
      }
    } catch (e) {
      notification.error({ message: 'Failed to approve roster' });
    }
  };

  const rejectRoster = async (reason?: string) => {
    try {
      const response = await EventService.declineRoster(roster.id, reason || '');

      if (!response.success) {
        notification.error({ message: response.message
          ? response.message
          : 'Failed to reject roster'
        });
      } else {
        reloadList?.call(null);
        setConfirmDeclineOpen(false);

        notification.success({ message: 'Roster is rejected successfully' });
      }
    } catch (e) {
      notification.error({ message: 'Failed to reject roster' });
    }
  };

  const deleteRoster = async () => {
    try {
      const response = await RosterService.deleteRoster(roster.id);

      if (!response.success) {
        notification.error({ message: response.message
          ? response.message
          : 'Failed to remove rosters'
        });
      } else {
        reloadList?.call(null);
        setConfirmRemoveOpen(false);

        notification.success({ message: 'Roster is removed successfully' });
      }
    } catch (e) {
      notification.error({ message: 'Failed to remove rosters' });
    }
  }

  return (
    <WrapperBox>
      <div className='actions'>
        {actionsEnabled && !notSubmitted && (
          <>
            {roster.eventParticipationStatus === 'PENDING' && (
              <>
                <Tooltip placement='topRight' title='Approve Roster'>
                  <div
                    className='bt-action i-icon-check'
                    onClick={approveConfirmation}
                    onKeyDown={approveConfirmation}
                    role='button'
                    aria-label='Approve Team'
                    tabIndex={0}
                  />
                </Tooltip>
              </>
            )}
            {roster.eventParticipationStatus === 'WAITING_PAYMENT' && (
              <>
                <Tooltip placement='topRight' title='Confirm Roster Payment'>
                  <div
                    className='bt-action i-icon-check'
                    onClick={approvePayment}
                    onKeyDown={approvePayment}
                    role='button'
                    aria-label='Approve Team'
                    tabIndex={0}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip placement='topRight' title='Reject Roster'>
              <div
                className='bt-action close i-icon-close'
                onClick={() => setConfirmDeclineOpen(true)}
                onKeyDown={() => setConfirmDeclineOpen(true)}
                role='button'
                aria-label='Reject Team'
                tabIndex={0}
              />
            </Tooltip>
            {roster.eventParticipationStatus !== 'DELETED' && (
              <Tooltip placement='topRight' title='Delete Roster'>
                <div
                  tabIndex={0}
                  className='bt-action close i-icon-trash'
                  role='button'
                  aria-label='Reject Team'
                  onClick={() => setConfirmRemoveOpen(true)}
                  onKeyDown={() => setConfirmRemoveOpen(true)}
                />
              </Tooltip>
            )}
          </>
        )}
      </div>
      <ConfirmBox
        isOpen={confirmApprovalOpen}
        title='Are you sure you want to approve this Roster?'
        labelConfirm='Approve'
        labelCancel='Cancel'
        onClose={() => setConfirmApprovalOpen(false)}
        onConfirm={approveRoster}
        onCancel={() => setConfirmApprovalOpen(false)}
      />
      <ConfirmBox
        isOpen={confirmPaymentOpen}
        title='Are you sure you want to confirm the payment from this Roster?'
        labelConfirm='Confirm'
        labelCancel='Cancel'
        onClose={() => setConfirmPaymentOpen(false)}
        onConfirm={approveRosterPayment}
        onCancel={() => setConfirmPaymentOpen(false)}
      />
      <ConfirmBox
        isOpen={confirmDeclineOpen}
        textArea=''
        title='Are you sure you want to decline this Roster?'
        labelConfirm='Reject'
        labelCancel='Cancel'
        onConfirm={rejectRoster}
        onClose={() => setConfirmDeclineOpen(false)}
        onCancel={() => setConfirmDeclineOpen(false)}
      />
      <ConfirmBox
        isOpen={confirmRemoveOpen}
        textArea=''
        title='Are you sure you want to remove this Roster?'
        labelConfirm='Remove'
        labelCancel='Cancel'
        onClose={() => setConfirmRemoveOpen(false)}
        onConfirm={deleteRoster}
        onCancel={() => setConfirmRemoveOpen(false)}
      />
    </WrapperBox>
  );
};

const WrapperBox = styled.div<{ active?: boolean }>`
  .actions {
    display: flex;
    text-align: center;
    margin-left: auto;
  }
`;

export default RosterApprovalButtons;
