import React, { FC } from 'react';

import { RequestStatus } from 'models/Request/RequestModel';

import { Item } from './components/Item/Item';

import * as S from './styles';

interface IDocumentStatusFieldProps {
  photoStatus?: RequestStatus;
  ageStatus?: RequestStatus;
  safeSportStatus?: RequestStatus;
  permission:
    | 'PLAYER'
    | 'COACH'
    | 'CLUB_DIRECTOR'
    | 'REFEREE'
    | 'TOURNAMENT_DIRECTOR'
    | 'STAFF';
}

export const DocumentStatusField: FC<IDocumentStatusFieldProps> = ({
  ageStatus,
  permission,
  safeSportStatus,
  photoStatus,
}) => {
  return (
    <S.Container>
      <Item name='PH' permission={permission} status={photoStatus} />
      <Item name='AV' permission={permission} status={ageStatus} />
      <Item name='SS' permission={permission} status={safeSportStatus} />
    </S.Container>
  );
};
