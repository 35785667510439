import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, message, Pagination, Space, Table } from 'antd';
import * as Sentry from '@sentry/react';
import { ExportOutlined ,
  CameraOutlined,
  FileTextOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/lib/table/Table';
import FileSaver from 'file-saver';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SorterResult } from 'antd/lib/table/interface';

import {
  FilterPaginationUsers,
} from 'models/Pagination/Pagination';
import { User } from 'models/User/UserModel';

import UserServices from 'services/User/UserApi';

import { ApplicationState } from 'redux/store';
import { UserType } from 'redux/user/types';

import useBlackList from 'hooks/useBlackList/useBlackList';
import useQuery from 'hooks/useQuery/useQuery';

import { getSortParamsByKey } from 'admin/pages/documents/utils';
import LightBox from 'components/base/LightBox/LightBox';
import PageContainer from 'components/base/PageContainer/PageContainer';

import ConvertUtil from 'util/ConvertUtil';

import AdvancedUserSearch from './AdvancedUserSearch/AdvancedUserSearch';
import MembershipUpgradeModal from './MembershipUpgradeModal/MembershipUpgradeModal';
import { convertUsersURLParams } from './utils';

interface TableData {
  loading: boolean;
  users: User[];
}

const UsersListPage = () => {
  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  const history = useHistory();
  const queryParam = useQuery();
  const pageSize = 10;

  const accessedRolesToUpgradeMembership = [
    UserType.PLAYER,
    UserType.TEAM_MANAGER,
    UserType.CLUB_DIRECTOR,
    UserType.COACH,
    UserType.REFEREE,
  ]

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'photo',
      key: 'photo',
      width: '50px',
      // eslint-disable-next-line react/display-name
      render: (_: string, record: User) => (
        <div className='avatar-table'>
          <img src={ConvertUtil.getMinioUrl(record.photo, 100)} alt="avatar" />
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: '220px',
      // eslint-disable-next-line react/display-name
      render: (_: string, record: User) => (
        <Link to={`./users-list/${record.id}`}>
          {`${record.firstName} ${record.middleName} ${record.lastName} ${record.suffix}`}
        </Link>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      width: '120px',
      sorter: true,
      render: (text: string) => moment(text).format('MM/DD/YYYY'),
      align: 'center' as const,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      width: '120px',
      align: 'center' as const,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      width: '120px',
      align: 'center' as const,
    },
    {
      title: 'Club',
      key: 'clubs',
      sorter: true,
      width: '100px',
      align: 'center' as const,
      render: (_: string, record: User) => {
        return record.playerInfo?.primaryClubId
          ? record.clubs.find(
              (club) => club.id === record.playerInfo?.primaryClubId
            )?.name
          : record.clubs[0]?.name || '-';
      },
    },
    {
      title: 'Membership',
      dataIndex: 'membershipId',
      key: 'membershipId',
      sorter: true,
      width: '200px',
      // eslint-disable-next-line react/display-name
      render: (text: string, record: User) => (
        <div>
          {memberships.data.find((e) => e.id === text)?.name}
          {console.log(`record ${record.firstName} ${record.lastName} >>>`, record)}
          <div>
            {record.type && accessedRolesToUpgradeMembership.includes(record.type) && !blackListed && (
              <Button
                onClick={() => setUpgradeMembershipUser(record)}
                size='small'
              >
                UPGRADE
              </Button>
            )}
          </div>
        </div>
      ),
      align: 'center' as const,
      onFilter: (value: any, record: any) =>
        record.membershipId.indexOf(value) === 0,
    },
    {
      title: 'Date of birth',
      dataIndex: 'birthDate',
      key: 'birthDate',
      sorter: true,
      width: '150px',
      align: 'center' as const,
      render: (text: string) => moment(text).format('MM/DD/YYYY'),
    },
    {
      title: 'Documents',
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (text: string, record: User, index: number) => (
        <Space size='middle'>
          {photoStatus(record)}
          {ageStatus(record)}
          {documentStatus(record)}
        </Space>
      ),
    },
  ];

  function photoStatus(record: User) {
    let content;
    if (record.photoVerified === 'PENDING') {
      content = <CameraOutlined style={{ fontSize: '20px' }} />;
    } else if (record.photoVerified === 'APPROVED') {
      content = (
        <CameraOutlined style={{ color: '#00cc00', fontSize: '20px' }} />
      );
    } else {
      content = (
        <CameraOutlined style={{ color: '#ff0000', fontSize: '20px' }} />
      );
    }
    return content;
  }

  function ageStatus(record: User) {
    let content;
    if (record.ageVerified === 'PENDING') {
      content = <IdcardOutlined style={{ fontSize: '20px' }} />;
    } else if (record.ageVerified === 'APPROVED') {
      content = (
        <IdcardOutlined style={{ color: '#00cc00', fontSize: '20px' }} />
      );
    } else {
      content = (
        <IdcardOutlined style={{ color: '#ff0000', fontSize: '20px' }} />
      );
    }
    return content;
  }

  function documentStatus(record: User) {
    let content;
    if (record.documentationVerified === 'PENDING') {
      content = <FileTextOutlined style={{ fontSize: '20px' }} />;
    } else if (record.documentationVerified === 'APPROVED') {
      content = (
        <FileTextOutlined style={{ color: '#00cc00', fontSize: '20px' }} />
      );
    } else {
      content = (
        <FileTextOutlined style={{ color: '#ff0000', fontSize: '20px' }} />
      );
    }
    return content;
  }

  const searchUsers = () => {
    setUserData({ ...userData, loading: true });
    refreshUrlParams();
    UserServices.quickSearch({
      urlParams: convertUsersURLParams(filter.pagination),
      body: {
        quickSearch: filter.query,
        ...(filter.clubs && { clubIdList: filter.clubs }),
        ...(filter.memberships && { membershipIdList: filter.memberships }),
        ...(filter.type && { typeList: [filter.type] }),
        ...(filter.birthYear && { birthYear: filter.birthYear }),
      },
    }).then((res) => {
      if (res.success) {
        setFilter((filter) => ({
          ...filter,
          pagination: {
            ...filter.pagination,
            size: res.data.pageSize,
            total: res.data.total,
            current: res.data.page,
          },
        }));

        setUserData({
          ...userData,
          loading: false,
          users: res.data.content,
        });
      } else {
        message.error(res.message);
        setUserData({ ...userData, loading: false });
      }
    });
  };

  const blackListed = useBlackList();

  const [filter, setFilter] = useState<FilterPaginationUsers>({
    query: queryParam.get('q') || '',
    pagination: {
      current: Number(queryParam.get('p')) || 0,
      size: 10,
      total: 0,
      loading: false,
    },
    ...(queryParam.get('clubs') && {
      clubs: queryParam.get('clubs')?.split(',') || [],
    }),
    ...(queryParam.get('memberships') && {
      memberships: queryParam.get('memberships')?.split(',') || [],
    }),
    ...(queryParam.get('birthYear') && {
      birthYear: queryParam.get('birthYear')?.split(',') || [],
    }),
    ...(queryParam.get('type') && {
      type: queryParam.get('type')!,
    }),
  });

  const refreshUrlParams = () => {
    if (filter.clubs && filter.clubs.length > 0) {
      searchParams.set('clubs', filter.clubs.toString());
    } else {
      searchParams.delete('clubs');
    }

    if (filter.memberships && filter.memberships.length > 0) {
      searchParams.set('memberships', filter.memberships.toString());
    } else {
      searchParams.delete('memberships');
    }

    if (filter.birthYear && filter.birthYear.length > 0) {
      searchParams.set('birthYear', filter.birthYear.toString());
    } else {
      searchParams.delete('birthYear');
    }

    if (filter.type && filter.type !== '') {
      searchParams.set('type', filter.type.toString());
    } else {
      searchParams.delete('type');
    }

    if (filter.query && filter.query !== '') {
      searchParams.set('q', filter.query);
    } else {
      searchParams.delete('q');
    }
  };

  useEffect(() => {
    refreshUrlParams();

    searchParams.set('p', filter.pagination.current.toString());

    history.replace({ search: searchParams.toString() });
  }, [filter.pagination.current, filter]);

  const handleTableChange = (
    page: TablePaginationConfig,
    filters: Record<string, (React.Key | boolean)[] | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
    extra: any
  ) => {
    setFilter((filter) => ({
      ...filter,
      pagination: {
        ...filter.pagination,
        sort: getSortParamsByKey(sorter),
      },
    }));
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setFilter((filter) => ({
      ...filter,
      pagination: {
        ...filter.pagination,
        current: page - 1,
      },
    }));
  };

  const handleOnShowSizeChange = (current: number, size: number) => {
    setFilter((filter) => ({
      ...filter,
      pagination: {
        ...filter.pagination,
        current: current - 1,
        size,
      },
    }));
  };

  useEffect(() => {
    searchUsers();
  }, [
    filter.pagination.current,
    filter.pagination.size,
    filter.pagination.sort,
    filter.query,
  ]);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [userData, setUserData] = useState<TableData>({
    loading: false,

    users: [],
  });

  const handleSearchChange = (
    event: React.FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (event?.currentTarget) {
      doSearch({ ...filter, query: event.currentTarget.value });
    }
  };

  const doSearch = useRef(
    debounce((filter) => {
      setFilter({
        ...filter,
        pagination: { ...filter.pagination, current: 0 },
      });
      history.replace({ search: searchParams.toString() });
    }, 300)
  ).current;

  const exportRecords = (query: string, page?: number) => {
    setUserData({ ...userData, loading: true });

    const urlParamsTmp = new URLSearchParams({
      size: pageSize.toString(),
      page: queryParam.get('p') || '0',
    });

    if (page === 0) {
      urlParamsTmp.set('page', '0');
    }

    UserServices.export({
      query,
      urlParams: urlParamsTmp,
      ...(filter.clubs?.length && { clubIdList: filter.clubs }),
      ...(filter.memberships?.length && {
        membershipIdList: filter.memberships,
      }),
      ...(filter.birthYear && {
        // eslint-disable-next-line radix
        birthYear: filter.birthYear.map((elem) => parseInt(elem)),
      }),
      ...(filter.type && {
        typeUser: [filter.type],
      }),
    })
      .then((response) =>
        FileSaver.saveAs(new Blob([response.data]), 'exported-users.csv')
      )
      .then(() => setUserData({ ...userData, loading: false }))
      .catch((error) => {
        setUserData({ ...userData, loading: false });
        Sentry.captureException(error, error.response?.data?.message);
        message.error({
          content: error.response?.data?.message,
          duration: 5,
        });
      });
  };

  const [upgradeMembershipUser, setUpgradeMembershipUser] = useState<
    User | undefined
  >(undefined);

  const handleSuccessUpgradeMembershipUser = () => {
    setUpgradeMembershipUser(undefined);
    searchUsers();
  };

  return (
    <PageContainer title='Users'>
      <div className='group-box'>
        <div className='form-group'>
          <span className='icon icon-search' />
          <input
            name='query'
            defaultValue={filter.query}
            onChange={handleSearchChange}
            type='text'
            className='icon'
            placeholder='Search'
            autoComplete='off'
          />
        </div>
        <AdvancedUserSearch
          filterParams={filter}
          setFilterParams={setFilter}
          onSearch={() => searchUsers()}
        />
        <div style={{ marginBottom: '10px' }}>
          Total found: <b>{filter.pagination.total}</b>
          <Button
            style={{ marginLeft: '20px' }}
            onClick={() => {
              exportRecords(filter.query || '', 0);
            }}
          >
            <ExportOutlined /> Export records
          </Button>
        </div>
        <Table
          rowKey='id'
          dataSource={userData?.users}
          pagination={false}
          loading={userData.loading}
          columns={columns}
          onChange={handleTableChange}
        />
        <Pagination
          current={filter.pagination.current + 1}
          defaultPageSize={filter.pagination.size}
          total={filter.pagination.total}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={handleOnShowSizeChange}
        />
      </div>
      <LightBox
        isOpen={!!upgradeMembershipUser}
        onClose={() => setUpgradeMembershipUser(undefined)}
      >
        {upgradeMembershipUser && (
          <MembershipUpgradeModal
            user={upgradeMembershipUser}
            onSuccess={handleSuccessUpgradeMembershipUser}
          />
        )}
      </LightBox>
    </PageContainer>
  );
};

export default UsersListPage;
