import { RequestStatus } from 'models/Request/RequestModel';
import styled from 'styled-components';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .not-required {
    color: ${COLORS.grey700};
  }
  .required {
    color: ${COLORS.grey200};
  }
`;


const getStatusColor = (status?: RequestStatus) => {
  switch (status) {
    case 'APPROVED':
      return COLORS.brandPrimary;
    case 'PENDING':
      return '#68E4FF';
    case 'EXPIRED':
    case 'DECLINED':
      return COLORS.supportError;
    default:
      return '#68E4FF';
  }
};

export const StatusTag = styled.div<{ $status?: RequestStatus }>`
  display: flex;
  padding: ${toRem(4)} ${toRem(16)};
  justify-content: center;
  align-items: center;
  gap: ${toRem(4)};
  text-transform: capitalize;
  color: ${COLORS.grey800};

  border-radius: ${toRem(33)};
  background: ${({ $status }) => getStatusColor($status)};
`;
