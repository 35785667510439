import React, { FC } from 'react';

import { User } from 'models/User/UserModel';

import { Documents } from 'pages/V3/ClubDashboard/ClubDirectorView/Members/components/InvitationsTab/components/InvitationsTable/components/Modal/ModalBody/components/Documents/Documents';

import { Header } from './components/Header';

import * as S from './styles';

interface IViewMemberDrawerProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
}

export const ViewMemberDrawer: FC<IViewMemberDrawerProps> = ({
  user,
  isOpen,
  onClose,
}) => {
  return (
    <S.Sidebar visible={isOpen} placement='right' onClose={onClose}>
      <S.Container>
        <Header user={user} />
        <Documents user={user} />
      </S.Container>
    </S.Sidebar>
  );
};
