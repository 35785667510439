import { useInfiniteQuery } from '@tanstack/react-query';
import { B2bEventsList } from 'redux/v3/b2bSetupEvent/types';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';

export const useGetCreatedByMyEventsInfinity = (pageSize: number) => {
  return useInfiniteQuery({
    queryKey: ['get-created-by-me-events', pageSize],
    queryFn: ({ pageParam = 0 }) => {
      return B2bEventService.listEvents(pageSize, pageParam);
    },
    getNextPageParam: (lastPage: ServiceResponseV3<B2bEventsList>) =>
      lastPage.data?.hasNextPage ? lastPage.data.page + 1 : undefined,
    keepPreviousData: true,
  });
};
