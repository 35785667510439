import React, { FC, useMemo } from 'react';
import { notification, Switch, Tooltip } from 'antd';
import { RosterListing } from 'models/Roster/RosterModel';
import RosterServiceV3 from 'services/v3/Rosters/RosterService';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { NewAgeDivision } from 'admin/models/AgeDivision';

interface IProps {
  value: boolean;
  roster: RosterListing;
}

const SwitchLockedButton: FC<IProps> = ({ value, roster }) => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId?: string }>();
  
  const checkedValue =
    !!value || !!(roster.ageDivision as NewAgeDivision)?.locked;

  const lockedTitle = useMemo(() => {
    let title = 'unlocked';
    title = value ? 'locked' : title;

    if ((roster.ageDivision as NewAgeDivision)?.locked) {
      title =
        'The Tournament Owner has locked the associated Division. Please ask Tournament Owner to unlock Division. Division AND Rosters should be unlocked to edit & resubmit Rosters';
    }

    return title;
  }, [value, roster.ageDivision]);

  const handleChangeRosterLockedStatus = async () => {
    try {
      await RosterServiceV3.changeLockedRosterState(roster?.id, !value);
      await queryClient.invalidateQueries(['rosters-event', eventId]);
      notification.success({
        message: 'Roster locked status changed successfully',
      });
    } catch (e) {
      notification.error({
        message: 'Failed to change roster locked status',
      });
    }
  };

  return (
    <Tooltip placement='topLeft' title={lockedTitle}  style={{ width: 250 }}>
      <Switch
        checked={checkedValue}
        disabled={(roster.ageDivision as NewAgeDivision)?.locked}
        onChange={handleChangeRosterLockedStatus}
      />
    </Tooltip>
  );
};

export default SwitchLockedButton;
