import React from 'react';
import { Lock } from '@icon-park/react';

import { NewAgeDivision } from 'admin/models/AgeDivision';

import { BodyLBold, BodyM, BodyS, COLORS } from 'styles/v3/variables';

import { TEXT_AGE_RULES_CONTENTS } from './configs';

import {
  DivisionCardContainer,
  DivisionStripColor,
  DivisionColorInformation,
  DivisionsContentInformation,
  DivisionInformationTeams,
} from './styles';

interface DivisionCardProps {
  division: NewAgeDivision;
}

const DivisionCard: React.FC<DivisionCardProps> = ({ division }) => {
  const {
    name,
    color,
    gender,
    rule,
    allowGirlBoysOnAnotherDivision,
    years,
    targetNumberOfTeams,
  } = division;

  const generateDesription = () => {
    const textRule = `${years[0]} ${TEXT_AGE_RULES_CONTENTS[rule]}`;

    let textAllow = '';

    if (allowGirlBoysOnAnotherDivision) {
      if (gender === 'FEMALE') {
        textAllow = '- Allow Boys';
      } else if (gender === 'MALE') {
        textAllow = '- Allow Girls';
      }
    }

    return `${gender} - ${textRule} ${textAllow}`;
  };

  const generateInformationTeams = () => {
    const numberTeams = `0 / ${targetNumberOfTeams}`;

    return numberTeams;
  };

  return (
    <DivisionCardContainer>
      <DivisionColorInformation>
        <DivisionStripColor colorStripe={color || COLORS.brandPrimary} />
        <DivisionsContentInformation>
          <BodyLBold>{name}</BodyLBold>
          <BodyS>{generateDesription()}</BodyS>
        </DivisionsContentInformation>
      </DivisionColorInformation>
      {/* division status = 'FULL' | 'LOCKED' | 'OPEN' */}
      <DivisionInformationTeams divisionStatus='OPEN'>
        <BodyM>{division.locked ? <Lock /> : generateInformationTeams()}</BodyM>
      </DivisionInformationTeams>
    </DivisionCardContainer>
  );
};

export default DivisionCard;
