import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import UserType from 'models/User/UserTypeModel';

import { MembershipView } from 'admin/models/membership/Membership';
import { RequestStatus } from 'models/Request/RequestModel';

import { BodyS } from 'styles/v3/variables';

import * as F from './components'

export type DataType = {
  id: string,
  name: string,
  type: UserType;
  permission: 'PLAYER'| 'COACH' | 'CLUB_DIRECTOR' |'REFEREE' | 'TOURNAMENT_DIRECTOR' | 'STAFF',
  age: number,
  gender: string,
  membership: MembershipView['type'],
  photoStatus: RequestStatus,
  ageStatus: RequestStatus,
  safeSportStatus: RequestStatus,
  photo: string,
  selected: boolean,
  toggleSelected: () => void,
  openDrawer: () => void,
};

export const columns: ColumnsType<DataType> = [
  {
    title: '',
    render(_, { id, toggleSelected, membership, permission, selected, type }) {
      const validUserTypesForOfficialMembership = ['PLAYER', 'COACH'];

      const isAvoidedUserTypes = type === UserType.CLUB_DIRECTOR || type === UserType.TEAM_MANAGER;

      return <F.Checkbox 
        id={id} 
        selected={selected} 
        disabled={
          membership === 'OFFICIAL' ||
          !validUserTypesForOfficialMembership.includes(permission) ||
          isAvoidedUserTypes
        } 
        toggle={toggleSelected} />;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render(_, {name, photo}) {
      return <F.Name name={name} photo={photo} />;
    },
  },
  {
    title: 'Permission',
    dataIndex: 'permission',
    render(value) {
      return  <F.Permission permission={value} />;
    },
  },
  {
    title: 'Age',
    dataIndex: 'age',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Gender',
    dataIndex: 'genderText',
    render(value) {
      return <BodyS $color='grey400'>{value}</BodyS>;
    },
  },
  {
    title: 'Membership',
    dataIndex: 'membership',
    render(value) {
      return <F.Membership membership={value} />;
    },
  },
  {
    title: 'Document Status',
    render(_, { ageStatus, photoStatus, safeSportStatus, permission }) {
      return (
        <F.DocumentStatus
          ageStatus={ageStatus}
          photoStatus={photoStatus}
          safeSportStatus={safeSportStatus}
          permission={permission}
        />
      );
    },
  },
  {
    title: '',
    render(_, { openDrawer }) {
      return <F.ViewMember openDrawer={openDrawer}/>;
    },
  },
];
