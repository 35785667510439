import styled from 'styled-components';

import { toRem } from 'styles/v3/functions';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${toRem(14)};
`;
