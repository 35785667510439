import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { BodyLBold } from 'styles/v3/variables';
import Select from 'components/v3/Forms/Select/Select';
import FilledButton from 'components/v3/Buttons/FilledButton';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import * as S from './styles';

import { FilterDrawerProps, InvitationFiltersType } from './types';

export const FindFiltersDrawer = ({
  isOpen,
  onClose,
  isLoading,
  handleChange,
  drawerOptions,
  advancedFilter,
}: FilterDrawerProps) => {
    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selectedFilters, setSelectedFilters] = useState<InvitationFiltersType>(
    advancedFilter
  );

  const handleChangeMultiSelect = (
    type: keyof InvitationFiltersType,
    event: any
  ) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [type]: event?.value ? event.value : '',
    }));
  };

  const handleApply = () => {
    handleChange(selectedFilters);
    onClose();
  };

  const debouncedHandleChange = debounce(handleChange, 250);

  useEffect(() => {
    debouncedHandleChange(selectedFilters);

    return () => {
      debouncedHandleChange.cancel();
    };
  }, [selectedFilters]);

  return (
    <Drawer
      visible={isOpen}
      placement='right'
      onClose={onClose}
      title={<BodyLBold>Filters</BodyLBold>}
      footer={
        <S.Footer>
          <FilledButton isUpper onClick={handleApply}>
            Apply filters
          </FilledButton>
        </S.Footer>
      }
    >
      <S.Container>
        <S.Section>
          <BodyLBold $color='grey50'>Size of Club</BodyLBold>
          <Select
            name='totalMembers'
            isClearable
            placeholder='totalMembers'
            isLoading={isLoading}
            defaultValue={
              searchParams?.get('totalMembers')
                ? drawerOptions?.totalMembers[0]
                : undefined
            }
            options={drawerOptions?.totalMembers as any}
            onChange={(e) => handleChangeMultiSelect('totalMembers', e)}
          />
        </S.Section>
        <S.Section>
          <BodyLBold $color='grey50'>Location</BodyLBold>
          <Select
            name='country'
            isClearable
            placeholder='Country'
            isLoading={isLoading}
            defaultValue={
              searchParams?.get('country')
                ? drawerOptions?.country[0]
                : undefined
            }
            options={drawerOptions?.country as any}
            onChange={(e) => handleChangeMultiSelect('country', e)}
          />
          <Select
            name='state'
            isClearable
            isLoading={isLoading}
            placeholder='State / Region'
            defaultValue={
              searchParams?.get('state') 
              ? drawerOptions?.state[0] 
              : undefined
            }
            options={drawerOptions?.state as any}
            onChange={(e) => handleChangeMultiSelect('state', e)}
          />
          <Select
            name='city'
            isClearable
            placeholder='City'
            isLoading={isLoading}
            defaultValue={
              searchParams.get('city') 
              ? drawerOptions?.city[0] 
              : undefined}
            options={drawerOptions?.city as any}
            onChange={(e) => handleChangeMultiSelect('city', e)}
          />
        </S.Section>
      </S.Container>
    </Drawer>
  );
};
