import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { MembersFilterTypes } from 'models/v3/Club/ClubModel';

import { useGetClubMembersById } from 'hooks/v3/clubs/useGetClubMembersById/useGetClubMembersById';

import { Spinner } from 'components/v3/Spinner/Spinner';
import { PaginationWrapper } from 'components/PaginationWrapper/PaginationWrapper';

import { TitleH4 } from 'styles/v3/variables';

import { AvatarWithName } from '../AvatarWithName/AvatarWithName';

import * as S from './styles';

export const Staff = () => {
  const { clubId } = useParams<{ clubId: string }>();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: response, isLoading } = useGetClubMembersById({
    id: clubId,
    page: currentPage - 1,
    type: MembersFilterTypes.STAFF,
  });

  const staff = response?.content || [];
  const hasStaff = staff.length > 0;

  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <S.Container>
      {hasStaff ? (
        <PaginationWrapper
          current={currentPage}
          total={response?.total}
          pageSize={response?.pageSize}
          onChange={setCurrentPage}
        >
          <S.AvatarWrapper>
            {staff.map((member) => (
              <AvatarWithName
                key={member.id}
                photo={member.photo}
                name={member.firstName}
              />
            ))}
          </S.AvatarWrapper>
        </PaginationWrapper>
      ) : (
        <S.FallbackMessageWrapper>
          <TitleH4 $color='grey500'>
            A club without staff? That&apos;s like a soccer game without a ball!
            Let&apos;s check back later.
          </TitleH4>
        </S.FallbackMessageWrapper>
      )}
    </S.Container>
  );
};
