import React, { FC, useState, useEffect } from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import { message as MessageSnackbar } from 'antd';
import { Upload } from '@icon-park/react';
import moment from 'moment';

import { BodyM, BodyMBold, BodyS, BodySBold } from 'styles/v3/variables';
import InputFile from 'components/v3/Forms/InputFile/InputFile';
import { StatusChip } from 'components/v3/Cards/DocumentCard/styles';

import {
  DocumentStatusLabel,
} from 'redux/v3/baseUsers/types';

import ConvertUtil from 'util/ConvertUtil';

import UploadService from 'services/upload/UploadService';
import DownloadService from 'services/download/DownloadService';
import UserServices from 'services/v3/User/UserService';

import ExamplePhoto from 'assets/imgs/passport.jpg';
import { useLocation } from 'react-router-dom';
import ResponseUtil from 'util/ResponseUtil';
import { useProfile } from 'hooks/v3/profile/useProfile';
import { FooterProps, ProfilePhotoModalProps } from './ProfilePhotoModal';
import OutlinedButtonWithIcon from '../Buttons/OutlinedButtonWithIcon';
import FilledButton from '../Buttons/FilledButton';
import OutlinedButton from '../Buttons/OutlinedButton';
import UploadModal from './UploadModal';

import * as S from './styles';
import { notification } from '../Notification/notification';

const ModalFooterInfo = () => (
  <>
    <BodyMBold $color='white'>Why do we need this?</BodyMBold>
    <BodyS $color='grey200' $spanColor='brandPrimary' className='tip-text'>
      We require your SafeSport to make sure you are able to act as a coach,
      referee or club director.
    </BodyS>
  </>
);

const SendFileFooter: FC<FooterProps> = ({
  setStep,
  isLoading,
  sendToApproval,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButton isUpper onClick={() => setStep(0)} color='white-dark'>
      Change File
    </OutlinedButton>
    <FilledButton
      className='button-approval'
      isUpper
      onClick={sendToApproval}
      disabled={isLoading}
    >
      Send to approval
    </FilledButton>
  </S.ButtonsWrapper>
);

const ResendFileFooter: FC<{ setStep: (e: number) => void }> = ({
  setStep,
}) => (
  <S.ButtonsWrapper>
    <OutlinedButtonWithIcon
      isUpper
      color='white-dark'
      customIcon={<Upload size={24} />}
      onClick={() => setStep(0)}
    >
      Reupload File
    </OutlinedButtonWithIcon>
  </S.ButtonsWrapper>
);

const ResendFileFooterInfo: FC<{ reason: string }> = ({ reason }) => (
  <>
    <BodyMBold>Why was my SafeSport denied?</BodyMBold>
    <BodyS className='tip-text'>{reason}</BodyS>
  </>
);

const ApprovedFooter: FC<{ date: string }> = ({ date }) => (
  <S.DateWrapper>
    <BodyM $color='grey200'>Expiration Date</BodyM>&nbsp;
    <BodyMBold $color='grey200'>{date}</BodyMBold>
  </S.DateWrapper>
);

const SafeSportModal: FC<ProfilePhotoModalProps> = ({
  status,
  user,
  handleCloseModal,
  ...props
}) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userType = searchParams.get('type') ?? '';

  const [step, setStep] = useState<number>(0);
  const [filePath, setFilePath] = useState<string>('');
  const [filePathConverted, setFilePathConverted] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileType, setFileType] = useState<'PDF' | 'IMAGE' | ''>('');
  const [fileList, setFileList] = useState<FileList | null>(null);

  const { currentUser: currentUserState, isBaseUser, updateCurrentAccount } = useProfile()
  const currentUser = user || currentUserState;

  const upload = async () => {
    setIsLoading(true);
    try {
      await UploadService.upload({
        file: fileList![0],
        prefix: 'user',
        public: false,
      }).then((response) => {
        if (!response.success) {
          MessageSnackbar.error({ content: response.message, duration: 5 });
          return;
        }
        const url = response.data.id;
        setFilePath(url);

        DownloadService.download(url).then((res) => {
          if (res.success) {
            setFilePathConverted(res.data);
            setIsLoading(false);
          }
        });
      });
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        MessageSnackbar.error({ content: e.message, duration: 5 });
      }
    }
  };

  const sendToApproval = async () => {
      setIsLoading(true);
      const payload = {
        extraInfo: {
          ...currentUser?.extraInfo,
          certificate: filePath,
        },
      };

      UserServices.updateUser(`${currentUser.id}`, payload, isBaseUser, userType).then(async (res) => {
        updateCurrentAccount()
        notification.success({
          message: 'User data Updated'
        });
        setStep(2);
      }).catch((err) => {
        if (userType !== '' && err) {
          const formattedErrors = ResponseUtil.formatInvalidRequestResponse(err)
          const invalidFields = formattedErrors.details.map(f => f.field)
          const description = invalidFields.length ? `Invalid fields: ${invalidFields.join(', ')}` : ''
          notification.error({
            message: 'Please complete your profile before uploading your SafeSport',
            description
          });
        } else {
          notification.error({
            message: 'Error uploading your SafeSport',
          });
        }
      }).finally(() => {
        setIsLoading(false);
        handleCloseModal();
      })
  };

  const step0 = () => (
    <S.ModalContentUploadPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Example photo</BodyM>
        <S.WrapperImage>
          <img src={ExamplePhoto} alt='Example' />
        </S.WrapperImage>
      </S.ModalContentWrapperPhoto>
      <S.ModalContentWrapperPhoto>
        <BodyM className='example-title'>Choose your file</BodyM>
        <InputFile
          id='safesportFile'
          text='Upload File'
          icon='UploadLogs'
          format='square'
          onChange={(e: any) => {
            setFileList(e.target.files);
          }}
        />
      </S.ModalContentWrapperPhoto>
    </S.ModalContentUploadPhoto>
  );

  const step1 = () => (
    <>
      {fileType !== '' && filePathConverted !== '' && (
        <S.ChoseFileWrapper $type='square'>
          {fileType === 'PDF' ? (
            <S.PdfWrapper>
              <PDFReader
                url={ConvertUtil.getPrivateMinioUrl(filePathConverted)}
                scale={0.5}
                showAllPage
              />
            </S.PdfWrapper>
          ) : (
            <img
              src={`${ConvertUtil.getPrivateMinioUrl(filePathConverted)}`}
              alt='User Document'
            />
          )}
        </S.ChoseFileWrapper>
      )}
    </>
  );

  const step2 = () => (
    <>
      <S.StatusHeader>
        <BodyM>DOCUMENT STATUS</BodyM>
        <StatusChip $status={status}>
          <BodySBold
            $isUpper
            $color={
              status === DocumentStatusLabel.DECLINED ||
                status === DocumentStatusLabel.EXPIRED
                ? 'white'
                : 'grey800'
            }
          >
            {status}
          </BodySBold>
        </StatusChip>
      </S.StatusHeader>
      {fileType !== '' && filePathConverted !== '' && (
        <S.ChoseFileWrapper $status={status} $type='square'>
          {fileType === 'PDF' ? (
            <S.PdfWrapper>
              <PDFReader
                url={ConvertUtil.getPrivateMinioUrl(filePathConverted)}
                scale={0.5}
                showAllPage
              />
            </S.PdfWrapper>
          ) : (
            <img
              src={`${ConvertUtil.getPrivateMinioUrl(filePathConverted)}`}
              alt='User Document'
            />
          )}
        </S.ChoseFileWrapper>
      )}
    </>
  );

  const renderFooter = () => {
    if (step === 1) {
      return (
        <SendFileFooter
          isLoading={isLoading}
          sendToApproval={sendToApproval}
          setStep={setStep}
        />
      );
    }
    if (step === 2) {
      const formatDate = moment(currentUser?.safesportValidUntil).format(
        'MM/DD/YYYY'
      );
      return status === DocumentStatusLabel.DECLINED ||
        status === DocumentStatusLabel.EXPIRED ? (
        <ResendFileFooter setStep={setStep} />
      ) : (
        status === DocumentStatusLabel.APPROVED && (
          <ApprovedFooter date={formatDate} />
        )
      );
    }
    return true;
  };

  const renderFooterInfo = () => {
    if (step === 0) {
      return <ModalFooterInfo />;
    }
    if (step === 2) {
      return (
        status === DocumentStatusLabel.DECLINED &&
        currentUser?.feedbacks?.document && (
          <ResendFileFooterInfo reason={currentUser.feedbacks.document} />
        )
      );
    }
    return true;
  };

  useEffect(() => {
    if (step === 0) {
      setFilePath('');
      setFilePathConverted('');
    }
  }, [step]);

  useEffect(() => {
    if (fileList) {
      setStep(1);
      if (fileList[0]) {
        if (fileList[0].type === 'application/pdf') {
          setFileType('PDF');
        } else {
          setFileType('IMAGE');
        }
        upload();
      }
    }
  }, [fileList]);

  useEffect(() => {
    if (currentUser?.extraInfo?.certificate) {
      DownloadService.download(currentUser?.extraInfo?.certificate).then((res) => {
        if (res.success) {
          setFilePathConverted(res.data);
          fetch(ConvertUtil.getPrivateMinioUrl(res.data))
            .then((blobResp) => blobResp.blob())
            .then((blob) => {
              if (blob.type === 'application/pdf') {
                setFileType('PDF');
              } else {
                setFileType('IMAGE');
              }
            });
        }
      });
    }
  }, [currentUser?.extraInfo?.certificate]);

  useEffect(() => {
    if (currentUser?.extraInfo?.certificate) {
      setStep(2);
    }
  }, []);

  return (
    <UploadModal
      {...props}
      title='Your SafeSport'
      footer={renderFooter()}
      footerInfo={renderFooterInfo()}
      handleCloseModal={handleCloseModal}
      onAfterClose={() => {
        return currentUser?.extraInfo?.certificate ? false : setStep(0);
      }}
    >
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
    </UploadModal>
  );
};

export default SafeSportModal;
