import React, { useState, useMemo } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import UserType from 'models/User/UserTypeModel';
import { RequestStatus } from 'models/Request/RequestModel';

import { DocumentStatusLabel } from 'redux/v3/baseUsers/types';
import { useProfile } from 'hooks/v3/profile/useProfile';

import DocumentCard from 'components/v3/Cards/DocumentCard/DocumentCard';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import ProfilePhotoModal from 'components/v3/UploadModal/ProfilePhotoModal';
import ProofOfAgeModal from 'components/v3/UploadModal/ProofOfAgeModal';
import SafeSportModal from 'components/v3/UploadModal/SafesportModal';

import DateUtil from 'util/DateUtil';

import { BodyXL } from 'styles/v3/variables';

import CompleteUserTemplate from '../Template/Template';
import * as S from '../Template/styles';

export type ModalState = 'photo' | 'age' | 'safesport' | '';

const Documents = () => {
  const history = useHistory();
  const { currentUser, isAnAdult } = useProfile();
  const [modalId, setModalId] = useState<ModalState>('');

  const isRefereeOrCoach = useMemo(() => {
    return (
      currentUser.type === UserType.COACH ||
      currentUser.type === UserType.REFEREE ||
      currentUser.types?.includes(UserType.COACH) ||
      currentUser.types?.includes(UserType.REFEREE)
    );
  }, [currentUser]);

  const photoStatus = useMemo(() => {
    switch (currentUser?.photoVerified) {
      case RequestStatus.PENDING:
        return DocumentStatusLabel.PENDING;
      case RequestStatus.APPROVED:
        return DocumentStatusLabel.APPROVED;
      case RequestStatus.DECLINED:
        return DocumentStatusLabel.DECLINED;
      default:
        return DocumentStatusLabel.EMPTY;
    }
  }, [currentUser?.photoVerified]);

  const ageStatus = useMemo(() => {
    switch (currentUser?.ageVerified) {
      case RequestStatus.PENDING:
        return DocumentStatusLabel.PENDING;
      case RequestStatus.APPROVED:
        return DocumentStatusLabel.APPROVED;
      case RequestStatus.DECLINED:
        return DocumentStatusLabel.DECLINED;
      default:
        return DocumentStatusLabel.EMPTY;
    }
  }, [currentUser?.ageVerified]);

  const safesportStatus = useMemo(() => {
    if (currentUser?.safesportValidUntil) {
      const isExpired = DateUtil.isExpired(currentUser?.safesportValidUntil);
      if (isExpired) {
        return DocumentStatusLabel.EXPIRED;
      }
    }

    switch (currentUser?.documentationVerified) {
      case RequestStatus.PENDING:
        return DocumentStatusLabel.PENDING;
      case RequestStatus.APPROVED:
        return DocumentStatusLabel.APPROVED;
      case RequestStatus.DECLINED:
        return DocumentStatusLabel.DECLINED;
      default:
        return DocumentStatusLabel.EMPTY;
    }
  }, [currentUser?.documentationVerified, currentUser?.safesportValidUntil]);

  return (
    <CompleteUserTemplate>
      <S.DocumentsWrapper>
        <S.DocumentsWrapperHeader>
          <BodyXL $color='brandPrimary' $isUpper>
            Your Documents
          </BodyXL>
        </S.DocumentsWrapperHeader>
        <div>
          <DocumentCard
            isRequired
            title='Profile Photo'
            caption='This photo will be used so referees can verify your identity before the matches.'
            status={
              currentUser?.photo && currentUser?.photo !== ''
                ? photoStatus
                : DocumentStatusLabel.EMPTY
            }
            onClick={() => setModalId('photo')}
          />
          <DocumentCard
            isRequired
            title='Proof of Age'
            caption="Your proof of age can be a Passport, Driver's License or Birth certificate."
            status={
              currentUser?.ageProof && currentUser?.ageProof !== ''
                ? ageStatus
                : DocumentStatusLabel.EMPTY
            }
            onClick={() => setModalId('age')}
          />
          {isAnAdult && isRefereeOrCoach && (
            <DocumentCard
              isOptional
              title='SafeSport'
              caption='SafeSport is only needed if you intend to be a coach, referee or club director.'
              status={
                currentUser?.extraInfo?.certificate &&
                currentUser?.extraInfo?.certificate !== ''
                  ? safesportStatus
                  : DocumentStatusLabel.EMPTY
              }
              onClick={() => setModalId('safesport')}
            />
          )}
        </div>
        <S.DocumentsWrapperFooter>
          <FilledButtonWithIcon
            isUpper
            disabled={!currentUser?.ageProof || !currentUser?.photo}
            onClick={() => history.push('/v3/home-page')}
          >
            Submit
          </FilledButtonWithIcon>
        </S.DocumentsWrapperFooter>
      </S.DocumentsWrapper>
      <ProfilePhotoModal
        isOpen={modalId === 'photo'}
        status={photoStatus}
        handleCloseModal={() => setModalId('')}
      />
      <ProofOfAgeModal
        isOpen={modalId === 'age'}
        status={ageStatus}
        handleCloseModal={() => setModalId('')}
      />
      <SafeSportModal
        isOpen={modalId === 'safesport'}
        status={safesportStatus}
        handleCloseModal={() => setModalId('')}
      />
    </CompleteUserTemplate>
  );
};

export default withRouter(Documents);
