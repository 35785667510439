import React, { FC } from 'react';

import { User } from 'models/User/UserModel';

import { BodyLBold, BodyM } from 'styles/v3/variables';

import * as S from './styles';

interface IDocumentsProps {
  user: User;
}

export const Documents: FC<IDocumentsProps> = ({ user }) => {
  return (
    <S.Container>
      <BodyLBold className='title'>Documents</BodyLBold>
      <S.DocumentList>
        <BodyM>Photo</BodyM>
        <S.StatusTag $status={user.photoVerified}>
          {user.photoVerified}
        </S.StatusTag>
        <span>aye</span>
        <BodyM>Proof Of Age</BodyM>
        <S.StatusTag $status={user.ageVerified}>{user.ageVerified}</S.StatusTag>
        <span>aye</span>
      </S.DocumentList>
    </S.Container>
  );
};
