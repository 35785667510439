import React, { FC, useCallback, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';
import { Divider } from 'antd';

import useScreenType from 'hooks/useScreenType';

import InputSelect, { OptionsType } from 'components/v3/Forms/Select/Select';
import { RadioGroup } from 'components/v3/Forms/RadioGroup/RadioGroup';
import { GENDER_OPTIONS } from 'pages/V3/event/components/EventMain/components/AgeSection/configs';
import { GenderOptionValues } from 'pages/V3/event/components/EventMain/components/AgeSection/enums';

import { BodyM, BodyMBold } from 'styles/v3/variables';

import { DivisionItem } from './components/DivisionItem/DivisionItem';
import { DivisionInfoProps } from './types';

import * as S from './styles';

export const DivisionInfo: FC<DivisionInfoProps> = ({ price, divisions, ageDivisions, orderDivisionDetails }) => {
  const { isMobile } = useScreenType()

  const [selectedYears, setSelectedYears] = useState<MultiValue<OptionsType>>(
    []
  );

  const [selectedGender, setSelectedGender] = useState<GenderOptionValues>(GenderOptionValues.All);

  const handleSetGender = useCallback((gender: GenderOptionValues) => {
    setSelectedGender(gender)
  }, []);

  const yearsOptions = useMemo(() => {
    return Array.from(
      new Set(ageDivisions?.flatMap((item) => item.years))
    ).map((value) => ({
      value,
      label: value.toString(),
    }))
  }, [ageDivisions]);

  const newAgeDivisions = useMemo(() => {
    return ageDivisions?.filter((item) => {
      const matchesGender =
        selectedGender === GenderOptionValues.All || item.gender.toLowerCase() === selectedGender.toLowerCase();

      const matchesYears =
        selectedYears.length === 0 ||
        selectedYears.some((year) => item.years.includes(Number(year.value)));

      return matchesGender && matchesYears;
    })
  }, [selectedGender, selectedYears, ageDivisions]);

  const getOrderedDivision = useCallback((divisionId: string) => {
    return orderDivisionDetails.find(division => division.id === divisionId);
  }, [orderDivisionDetails]);

  const hasAgeDivisions = newAgeDivisions.length > 0;

  return (
    <S.Container $renderDivisions={hasAgeDivisions}>
      <S.FiltersWrapper >
        <S.SelectWrapper>
          <BodyM $isUpper>Year</BodyM>
          <InputSelect
            isMulti
            options={yearsOptions}
            hideSelectedOptions={false}
            onChange={(values) => setSelectedYears(values as any)}
          />
        </S.SelectWrapper>
        <RadioGroup
          label='Gender'
          value={selectedGender}
          onChange={(e) => handleSetGender(e.target.value)}
          options={GENDER_OPTIONS}
        />
      </S.FiltersWrapper>
      {hasAgeDivisions ? (
        <S.DivisionList>
          {newAgeDivisions.map((division) => (
            <DivisionItem
              key={division.id}
              item={division}
              price={price}
              divisions={divisions}
              orderInfo={getOrderedDivision(division.id)}
            />
          ))}
          { isMobile && <Divider /> }
        </S.DivisionList>
      ) : (
        <BodyMBold>There are no age divisions registered for this event yet.</BodyMBold>
      )}
    </S.Container>
  );
};
