/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PreviewOpen } from '@icon-park/react';
import { toRem } from 'styles/v3/functions';
import { Switch } from 'antd';

import { DeepPartial } from 'util/types';
import { PatchEventProps } from 'services/v3/B2bEvent/types';

import { NewAgeDivision } from 'admin/models/AgeDivision';

import { BodyLBold, BodyS, BodyMBold, BodyM } from 'styles/v3/variables';
import { useUpdateEvent } from 'hooks/v3/event/useUpdateEvent/useUpdateEvent';
import { useQueryClient } from '@tanstack/react-query';
import { DivisionAndClubsState } from '../..';
import { CirclesNested } from '../../../../../../../Home/components/CirclesNested';

import {
  DivisionCircleColor,
  DivisionContentDatas,
  DivisionContentInformation,
  DivisionContentInformationHeader,
  DivisionData,
  DivisionInformationContainer,
  DivisionInformationHeader,
  DivisionsContent,
  DivisionsInformationTitle,
  ViewIconWrapper,
} from './styles';

interface DivisionInformationProps {
  allDivisions?: NewAgeDivision[];
  divisionAndClub: DivisionAndClubsState;
  handleOpenModal: (division: DivisionAndClubsState) => void;
}

export const DivisionInformation: FC<DivisionInformationProps> = ({
  divisionAndClub,
  allDivisions,
  handleOpenModal,
}) => {
  const queryClient = useQueryClient();

  const { division, divisionOrder, clubInformation } = divisionAndClub;

  const { eventId } = useParams<{ eventId: string }>();

  const { mutateAsync } = useUpdateEvent({
    eventId,
  });

  const [lockApplications, setLockApplications] = useState(!!division.locked);

  const teamsApplications = useMemo(() => {
    return divisionOrder.reduce((acc, order) => acc + order.quantity, 0);
  }, [divisionOrder]);

  const totalReceived = useMemo(() => {
    const total = divisionOrder.reduce((acc, order) => acc + order.total, 0);

    return Math.round(total * 100) / 100;
  }, [divisionOrder]);

  const teamsInformation = useMemo(() => {
    return divisionOrder.map((order) => {
      const teamClub = clubInformation.find((club) => club.id === order.clubId);

      return {
        id: teamClub?.id ?? '',
        logo: teamClub?.logo ?? '',
        name: teamClub?.name ?? '',
      };
    });
  }, [divisionOrder, clubInformation]);

  const divisionAllow = (
    allowed: boolean | undefined,
    gender: 'MALE' | 'FEMALE' | 'COED'
  ): string => {
    if (allowed && gender !== 'COED') {
      if (gender === 'MALE') {
        return '- Allow Girls';
      }
      return '- Allow Boys';
    }

    return '';
  };

  const handleChangeApplication = async () => {
    setLockApplications((prev) => !prev);

    try {
      const updatedDivision = { ...division, locked: !lockApplications };

      const payload: DeepPartial<PatchEventProps> = {
        ageDivisions: allDivisions?.map((item) =>
          item.id === division.id ? updatedDivision : item
        ),
      };

      await mutateAsync({ id: eventId, data: payload });
      await queryClient.invalidateQueries(['event-divisions', eventId]);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
  };

  const percentage = (partialValue: number, totalValue: number) => {
    if (totalValue > 0) {
      return ((100 * partialValue) / totalValue).toFixed(0);
    }

    return 0;
  };

  useEffect(() => {
    setLockApplications(!!division.locked);
  }, [division.locked]);

  return (
    <>
      <DivisionInformationContainer>
        <DivisionInformationHeader>
          <DivisionsInformationTitle>
            <Switch
              checked={lockApplications}
              onChange={handleChangeApplication}
            />
            <BodyLBold $color='grey300' style={{ marginLeft: toRem(8) }}>
              {division.name}
            </BodyLBold>
            <BodyS $color={lockApplications ? 'brandPrimary' : 'grey600'}>
              Application {lockApplications ? 'Closed' : 'Open'}
            </BodyS>
          </DivisionsInformationTitle>
          <ViewIconWrapper onClick={() => handleOpenModal(divisionAndClub)}>
            <PreviewOpen />
          </ViewIconWrapper>
        </DivisionInformationHeader>
        <DivisionsContent>
          <DivisionContentInformation>
            <DivisionContentInformationHeader>
              <DivisionCircleColor divisionColor={division.color} />
              <BodyM $color='grey300'>
                {division.gender} - {division.years[0]}{' '}
                {divisionAllow(
                  division.allowGirlBoysOnAnotherDivision,
                  division.gender
                )}
              </BodyM>
            </DivisionContentInformationHeader>
            <CirclesNested
              circlesInfos={teamsInformation}
              maxInformation={12}
            />
          </DivisionContentInformation>
          <DivisionContentDatas>
            <DivisionData>
              <BodyS $color='grey300'>Occupation Rate</BodyS>
              <BodyMBold $color='grey300'>
                {percentage(
                  teamsApplications,
                  division.targetNumberOfTeams ?? 0
                )}
                %
              </BodyMBold>
            </DivisionData>
            <DivisionData>
              <BodyS $color='grey300'>Teams / Target</BodyS>
              <BodyMBold $color='grey300'>
                {teamsApplications} / {division.targetNumberOfTeams}
              </BodyMBold>
            </DivisionData>
            <DivisionData>
              <BodyS $color='grey300'>Total Received</BodyS>
              <BodyMBold $color='grey300'>${totalReceived}</BodyMBold>
            </DivisionData>
          </DivisionContentDatas>
        </DivisionsContent>
      </DivisionInformationContainer>
    </>
  );
};
