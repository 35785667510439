import React, { FC, useState } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { User } from 'models/User/UserModel';

import UserServices from 'services/User/UserApi';

import { ApplicationState } from 'redux/store';

import Button from 'components/uielements/Button/Button';

import ConvertUtil from 'util/ConvertUtil';

import { color } from 'styles/styleVariables';

interface IMembershipUpgradeModalProps {
  user: User;
  onSuccess: () => void;
}

const MembershipUpgradeModal: FC<IMembershipUpgradeModalProps> = (props) => {
  const { user, onSuccess } = props;

  const memberships = useSelector(
    (state: ApplicationState) => state.memberships
  );

  const [newMembership, setNewMembership] = useState(user.membershipId);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const upgradeMemmership = () => {
    if (newMembership && reason) {
      setIsLoading(true);

      UserServices.membershipUpgrade(user.id, newMembership, reason)
        .then((response) => {
          if (response.success) {
            onSuccess();
          } else {
            message.error({ content: response.message });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div
      style={{
        padding: '25px',
        borderRadius: '10px',
        backgroundColor: color.greyLight,
      }}
    >
      <h2>Upgrade User Membership</h2>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          style={{
            backgroundImage: `url(${ConvertUtil.getMinioUrl(user.photo)})`,
          }}
        />
        <div>{`${user.firstName} ${user.lastName}`}</div>
      </div>
      <div className='form-group'>
        <label htmlFor='membership' style={{ fontWeight: 'bold' }}>
          Select Membership *
        </label>
        <select
          id='membership'
          value={newMembership}
          onChange={(item) => setNewMembership(item.target.value)}
        >
          {memberships.data
            .filter((item) => item.userType === user.type)
            .map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
        </select>
      </div>
      <div className='form-group'>
        <label htmlFor='reason' style={{ fontWeight: 'bold' }}>
          Upgrade reason *
        </label>
        <textarea
          id='reason'
          value={reason}
          onChange={(item) => setReason(item.target.value)}
        />
      </div>
      <Button
        loading={isLoading}
        disabled={newMembership === '' || reason === '' || isLoading}
        onClick={() => upgradeMemmership()}
      >
        {!isLoading ? 'Save' : ''}
      </Button>
    </div>
  );
};

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #fff;
  margin-right: 10px;
  background-size: cover;
`;

export default MembershipUpgradeModal;
