import React, { FC, useMemo } from 'react';
import { Camera, IdCardH, IdCardV } from '@icon-park/react';

import { RequestStatus } from 'models/Request/RequestModel';

import { BodyS, COLORS } from 'styles/v3/variables';

import * as S from './styles';

interface IItemProps {
  name: string;
  status?: RequestStatus;
  permission:
    | 'PLAYER'
    | 'COACH'
    | 'CLUB_DIRECTOR'
    | 'REFEREE'
    | 'TOURNAMENT_DIRECTOR'
    | 'STAFF';
}

export const Item: FC<IItemProps> = ({ name, status, permission }) => {
  const isEnabled = useMemo(() => {
    const safeSportPermission = ['COACH', 'CLUB_DIRECTOR', 'REFEREE'];
    const agePermission = ['PLAYER'];

    switch (name) {
      case 'SS':
        return safeSportPermission.includes(permission);
      case 'AV':
        return agePermission.includes(permission);
      default:
        return true;
    }
  }, [name, permission]);

  const CurrentIcon = useMemo(() => {
    switch (name) {
      case 'SS':
        return IdCardV;
      case 'AV':
        return IdCardH;
      default:
        return Camera;
    }
  }, [name]);

  const title = useMemo(() => {
    const notApply = !isEnabled ? '(Not Apply)' : '';

    switch (name) {
      case 'SS':
        return `SafeSport Status ${notApply}`;
      case 'AV':
        return `Age Proof Status ${notApply}`;
      default:
        return `Photo Status ${notApply}`;
    }
  }, [name]);

  const iconColor = useMemo(() => {
    if (!isEnabled) {
      return COLORS.grey400;
    }

    switch (status) {
      case RequestStatus.APPROVED:
        return COLORS.green;
      case RequestStatus.PENDING:
        return '#67e4ff';
      case RequestStatus.DECLINED:
      case RequestStatus.EXPIRED:
        return COLORS.supportError;
      default:
        return COLORS.grey400;
    }
  }, [status]);

  return (
    <S.Container title={title} aria-label={title} $enabled={isEnabled}>
      <S.Icon>
        <CurrentIcon style={{ color: iconColor }} />
      </S.Icon>
      <BodyS>{name}</BodyS>
    </S.Container>
  );
};
