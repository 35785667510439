import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';

import { Header } from 'components/v3/ClubManagerDashboard/Header/Header';
import { Spinner } from 'components/v3/Spinner/Spinner';
import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';

import { BodyM } from 'styles/v3/variables';

import { TABS_KEYS } from './tab-keys';
import { Members, Staff } from './components';

import * as S from './styles';

const Club = () => {
  const history = useHistory();
  const location = useLocation();

  const { clubId } = useParams<{ clubId: string }>();

  const searchParams = new URLSearchParams(location.search);

  const activeTab = searchParams.get('tab') ?? TABS_KEYS.STAFF;

  const handleTabChange = useCallback(
    (key: string) => {
      if (!location.search) {
        history.push(`${location.pathname}?tab=${key}`);

        return;
      }

      history.push(
        `${location.pathname}${location.search.replace(activeTab, key)}`
      );
    },
    [history, location.pathname]
  );

  const { data: club, isLoading: isLoadingGetClub } = useGetClubById(clubId);

  const handleGoBack = () => {
    history.push('/v3/clubs/find');
  };

  if (isLoadingGetClub) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <>
      <TextButtonWithIcon
        reverse
        icon='back'
        align='flex-end'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <Header maxWidth={false} club={club} />
      <S.Main>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <Tabs.TabPane
            key={TABS_KEYS.STAFF}
            tab={<BodyM $isUpper>Staff</BodyM>}
          >
            <Staff />
          </Tabs.TabPane>
          <Tabs.TabPane
            key={TABS_KEYS.MEMBERS}
            tab={<BodyM $isUpper>Members</BodyM>}
          >
            <Members />
          </Tabs.TabPane>
        </Tabs>
      </S.Main>
    </>
  );
};

export default Club;
