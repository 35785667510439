import React, { ReactNode } from 'react';

import { Lock } from '@icon-park/react';
import * as S from './styles';

export type TabsProps = {
  size?: 'large' | 'small',
  defaultActiveKey?: string,
  handleChangeTab?: (tab: string) => void,
  active?: string,
  tabs?: {
    key?: string,
    title?: string,
    isLocked?: boolean,
    children?: ReactNode,
  }[],
};

export const Tabs = ({
  tabs,
  active,
  size = 'large',
  defaultActiveKey,
  handleChangeTab,
}: TabsProps) => {
  return (
    <S.Container>
      <S.Tabs
        type='card'
        activeKey={active}
        defaultActiveKey={defaultActiveKey}
        onChange={handleChangeTab}
      >
        {tabs?.map((tab) => (
          <S.TabPane
            key={tab.key}
            tabKey={tab.key}
            disabled={tab.isLocked}
            tab={
              <S.TabPaneTitle>
                <S.TabTitle className={`tab-title-${size}`}>
                  {tab.title}
                </S.TabTitle>
                {tab.isLocked && <Lock size='22px' />}
              </S.TabPaneTitle>
            }
          >
            {tab.children}
          </S.TabPane>
        ))}
      </S.Tabs>
    </S.Container>
  );
};
