import React, { FC, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import CovidTerms from 'pages/Terms/CovidTerms';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import TemplateReadAndAcceptDocument from 'components/v3/TemplateReadAndAcceptDocument/TemplateReadAndAcceptDocument';

import { BodyM, BodyS, BodySBold, TitleH1 } from 'styles/v3/variables';

import * as S from './styles';

const CovidTermsPage: FC = () => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <S.CovidTermsWrapper>
      <TextButtonWithIcon
        color='light'
        align='flex-end'
        reverse
        icon='back'
        onClick={handleGoBack}
      >
        Go back
      </TextButtonWithIcon>
      <TitleH1 $isUpper $color='brandPrimary'>
        COVID-19 Agreement
      </TitleH1>
      <BodyM $color='grey300' className='caption'>
        You Must Read This Document in its Entirety to Proceed
      </BodyM>
      <TemplateReadAndAcceptDocument
        setRead={() => {}}
        buttonText='Agree and continue upgrade'
        link='/v3/profile/upgrade-membership/order-summary'
        infoText={
          <BodyS $spanColor='brandPrimary' $color='grey200'>
            By clicking accept you authorize United Futsal to{' '}
            <span>authenticate</span> the requested documentation and accept and
            acknowledge the <BodySBold>Covid-19 Waiver</BodySBold> presented to
            you during the application process.
          </BodyS>
        }
        label='I AGREE to ALL terms and conditions set forth in the Covid-19 AgreementI just read.'
      >
        <CovidTerms />
      </TemplateReadAndAcceptDocument>
    </S.CovidTermsWrapper>
  );
};

export default withRouter(CovidTermsPage);
