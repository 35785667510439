import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { Left, More } from '@icon-park/react';
import { Divider } from 'antd';

import { useGetEventRosters } from 'hooks/v3/event/useGetEventRosters';
import B2bEventService from 'services/v3/B2bEvent/B2bEventService';

import { BodyL, BodyM, COLORS } from 'styles/v3/variables';
import IconButton from 'components/v3/Buttons/IconButton';
import { NewAgeDivision } from 'admin/models/AgeDivision';
import RosterService, {
  RosterByEventId,
} from 'services/v3/Rosters/RosterService';
import RightDrawer from 'components/v3/RightDrawer';

import { CircleInformation } from '../../../Home/components/CirclesNested';
import {
  DivisionTeamInformation,
  RosterInformation,
  Modal,
} from './components';

import {
  TeamsContainer,
  TeamsHeader,
  TeamsTitleBackBtnContainer,
  TeamsContent,
  Popover,
  PopoverDropdown,
  PopoverItem,
} from './styles';

export const Teams: FC = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const popoverRef = useRef<HTMLDivElement>(null);

  const [openedPopover, setOpenedPopover] = useState(false);
  const [divisions, setDivisions] = useState<NewAgeDivision[]>();
  const [selectedDivision, setSelectedDivision] = useState<NewAgeDivision>();
  const [selectedRoster, setSelectedRoster] = useState<RosterByEventId>();
  const [isOpenModalRoster, setOpenModalRoster] = useState<boolean>(false);

  const { data, isFetching, refetch } = useGetEventRosters({ eventId });

  const rosterMap = useMemo(() => {
    return data?.reduce(
      (
        acc: Record<string, { roster?: number, isSomeLocked?: boolean }>,
        item
      ) => {
        const accumulator = acc;
        const { id } = item.ageDivision;

        accumulator[id] = {
          roster: (accumulator[id]?.roster || 0) + 1,
          isSomeLocked: accumulator[id]?.isSomeLocked || item.locked || false,
        };

        return accumulator;
      },
      {}
    );
  }, [data]);

  const handleSelectDivision = (idDivision: string) => {
    const divisionFind = divisions?.find(
      (division) => division.id === idDivision
    );

    setSelectedDivision(divisionFind);
  };

  const handleUpdateAllDivision = async (isLockedDivision: boolean) => {
    divisions?.forEach(async (division) => {
      await B2bEventService.changeDivisionSetStatus(
        eventId,
        division.id,
        isLockedDivision
      );

      refetch();
      setOpenedPopover((prevState) => !prevState);
    });
  };

  const handleUpdateDivision = async (eventId: string, divisionId: string) => {
    if (divisionId) {
      await B2bEventService.changeDivisionStatus({
        eventId,
        divisionId,
      });

      refetch();
    }
  };

  const handleUpdateRosterLockState = async (
    rosterId: string,
    state: boolean
  ) => {
    await RosterService.changeLockedRosterState(rosterId, state);

    refetch();
  };

  const handleCloseRosterModal = () => {
    setOpenModalRoster(false);
    setSelectedRoster(undefined);
  };

  const handleSelectRosterToOpenModal = (rosterSelected: RosterByEventId) => {
    setSelectedRoster(rosterSelected);
    setOpenModalRoster(true);
  };

  useEffect(() => {
    const updateDivisions = data
      ?.map((event) => event.ageDivision)
      .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

    setDivisions(updateDivisions || []);
  }, [data, refetch]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const element = event.target as HTMLElement;

      if (!element.closest('.roster-popover')) {
        setOpenedPopover(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
  }, []);

  const viewAllDivisions = () => {
    return (
      <>
        <TeamsHeader>
          <BodyL>Your Divisions</BodyL>
          <Popover ref={popoverRef} className="roster-popover">
            <IconButton
              icon={<More size={24} fill={COLORS.grey50} />}
              style={{ backgroundColor: COLORS.grey800 }}
              onClick={() => setOpenedPopover(!openedPopover)}
            />
            <PopoverDropdown className={openedPopover ? 'visible' : ''}>
              <PopoverItem onClick={() => handleUpdateAllDivision(false)}>
                <BodyM>Open all applications</BodyM>
              </PopoverItem>
              <PopoverItem onClick={() => handleUpdateAllDivision(true)}>
                <BodyM>Close all applications</BodyM>
              </PopoverItem>
            </PopoverDropdown>
          </Popover>
        </TeamsHeader>
        <Divider style={{ margin: 0 }} />
        <TeamsContent>
          {divisions?.map((division) => (
            <DivisionTeamInformation
              id={division.id}
              handleSelectedDivision={handleSelectDivision}
              handleUpgradeDivisionStatus={() =>
                handleUpdateDivision(eventId, division.id)
              }
              rule={division.rule}
              title={division.name}
              isDivisionLocked={division.locked}
              gender={division.gender}
              years={division.years}
              allowOtherGender={division.allowGirlBoysOnAnotherDivision}
              teams={rosterMap?.[division.id].roster}
              isPartiallyLocked={rosterMap?.[division.id].isSomeLocked}
              targetTeams={division.targetNumberOfTeams}
              bgColor={division.color}
            />
          ))}
          {!isFetching && !divisions?.length && (
            <BodyM>
              Teams have not yet applied in a divisions.
              Your divisions will appear here when the teams start adding their rosters to the event.
            </BodyM>
          )}
        </TeamsContent>
      </>
    );
  };

  const viewDivisionSelected = () => {
    if (!selectedDivision || !data) {
      return null;
    }

    return (
      <>
        <TeamsHeader>
          <TeamsTitleBackBtnContainer>
            <IconButton
              icon={<Left size={24} fill={COLORS.grey50} />}
              style={{ backgroundColor: COLORS.grey800 }}
              onClick={() => setSelectedDivision(undefined)}
            />
            <BodyL>{selectedDivision.name}</BodyL>
          </TeamsTitleBackBtnContainer>
        </TeamsHeader>
        <Divider style={{ margin: 0 }} />
        <TeamsContent>
          {data
            ?.filter(
              (division) => division.ageDivision.id === selectedDivision.id
            )
            .map((roster) => {
              const coachesCircles: CircleInformation[] = roster.coaches.map(
                (coach) => ({
                  id: coach.id,
                  logo: coach.photo,
                })
              );

              const playersCircles: CircleInformation[] = roster.players.map(
                (player) => ({
                  id: player.id,
                  logo: player.photo,
                })
              );

              return (
                <RosterInformation
                  key={roster.id}
                  handleUpgradeRosterStatus={() =>
                    handleUpdateRosterLockState(roster.id, !roster.locked)
                  }
                  isDivisionLocked={roster.ageDivision.locked}
                  isRosterLocked={roster.locked}
                  logoClub={roster.club.logo}
                  nameClub={roster.club.name}
                  coachesCircles={coachesCircles}
                  playersCircles={playersCircles}
                  status={roster.status}
                  handleSelectedRoster={() =>
                    handleSelectRosterToOpenModal(roster)
                  }
                />
              );
            })}
        </TeamsContent>
      </>
    );
  };

  return (
    <TeamsContainer>
      {selectedDivision ? viewDivisionSelected() : viewAllDivisions()}
      <RightDrawer
        handleCloseModal={handleCloseRosterModal}
        isOpen={isOpenModalRoster && !!selectedRoster}
      >
        {selectedRoster && isOpenModalRoster && (
          <Modal
            roster={selectedRoster}
            onUpdateRosterLockState={handleUpdateRosterLockState}
          />
        )}
      </RightDrawer>
    </TeamsContainer>
  );
};
