import React, { FC, useCallback, useMemo } from 'react';
import { RightSmallUp } from '@icon-park/react';
import { useParams } from 'react-router-dom';

import { SimpleOrderView } from 'services/v3/Order/types';

import { useGetEventById } from 'hooks/v3/event/useGetEventById/useGetEventById';

import { NumberToUSDollar } from 'util/CurrencyUtil';

import Loading from 'components/v3/Loading/Loading';
import CompactStripeCard from 'components/v3/Cards/CompactStripeCard/CompactStripeCard';
import FilledButton from 'components/v3/Buttons/FilledButton';

import {
  BodyLBold,
  BodyM,
  BodyMBold,
  BodyS,
  COLORS,
} from 'styles/v3/variables';

import * as S from './styles';
import { HR } from '../../styles';

interface ISummaryProps {
  order: SimpleOrderView;
}

const Summary: FC<ISummaryProps> = ({ order }) => {
  const { eventId } = useParams<{ eventId: string }>();

  const { data: currentEvent } = useGetEventById(eventId);

  const divisionColorMap = useCallback(
    (divisionId: string) => {
      if (!currentEvent?.ageDivisions || !order || !divisionId) {
        return '';
      }

      return currentEvent.ageDivisions.reduce((acc, item) => {
        if (item.id === divisionId) {
          return item.color ?? '';
        }

        return acc;
      }, '');
    },
    [currentEvent, order]
  );

  const orderSubtotal = useMemo(() => {
    if (!order.subtotal) {
      return 0;
    }

    return order.subtotal / 100;
  }, [order]);

  const orderDiscount = useMemo(() => {
    if (!order.discount) {
      return 0;
    }

    return order.discount / 100;
  }, [order]);

  const orderPayed = useMemo(() => {
    if (orderDiscount) {
      return orderSubtotal - orderDiscount;
    }

    return orderSubtotal;
  }, [orderDiscount, orderSubtotal]);

  const administrativeFess = useMemo(() => {
    return order.totalFee || 0;
  }, [order]);

  const handleStripe = () => {
    window.open('https://dashboard.stripe.com/payments');
  };

  if (!currentEvent) {
    return <Loading />;
  }

  return (
    <S.Container>
      <BodyLBold $color='grey50'>Summary</BodyLBold>
      <S.SummaryTable>
        <S.Row>
          <S.Cell className='firstColumn'>
            <BodyS $color='grey50'>Age DIVISION</BodyS>
          </S.Cell>
          <S.Cell>
            <BodyS $color='grey50' className='headerMiddle'>
              QTY
            </BodyS>
          </S.Cell>
          <S.Cell>
            <BodyS $color='grey50'>TOTAL</BodyS>
          </S.Cell>
        </S.Row>
        {order.item.b2bEventRosterFee?.divisions.map((item) => (
          <S.Row key={item.id}>
            <S.Cell className='firstColumn'>
              <CompactStripeCard
                title={item.name ?? ''}
                subtitle={item.description}
                stripeColor={divisionColorMap(item.id) || COLORS.pink}
                size='small'
                width='100%'
              />
            </S.Cell>
            <S.Cell className='qty'>
              <BodyM>{item.quantity}</BodyM>
            </S.Cell>
            <S.Cell>
              <BodyMBold>{NumberToUSDollar.format(item.total)}</BodyMBold>
            </S.Cell>
          </S.Row>
        ))}
      </S.SummaryTable>
      <S.SummaryTable className='secondary'>
        <S.Row>
          <S.Cell>
            <BodyM>Total Summary</BodyM>
          </S.Cell>
          <S.Cell className='secondary'>
            <BodyMBold>{NumberToUSDollar.format(orderSubtotal)}</BodyMBold>
          </S.Cell>
        </S.Row>
        {orderDiscount > 0 && (
          <S.Row>
            <S.Cell>
              <BodyM $color='supportNeutral'>Coupon discount</BodyM>
            </S.Cell>
            <S.Cell className='secondary'>
              <BodyMBold $color='supportNeutral'>
                - {NumberToUSDollar.format(orderDiscount)}
              </BodyMBold>
            </S.Cell>
          </S.Row>
        )}
      </S.SummaryTable>
      <HR />
      <S.SummaryTable className='secondary'>
        <S.Row>
          <S.Cell>
            <BodyM>Total Payed</BodyM>
          </S.Cell>
          <S.Cell className='secondary'>
            <BodyMBold>{NumberToUSDollar.format(orderPayed)}</BodyMBold>
          </S.Cell>
        </S.Row>
        <S.Row>
          <S.Cell>
            <BodyM $color='grey400'>Administrative Fees</BodyM>
          </S.Cell>
          <S.Cell className='secondary'>
            <BodyMBold $color='grey400'>
              - {NumberToUSDollar.format(administrativeFess)}
            </BodyMBold>
          </S.Cell>
        </S.Row>
        <S.Row>
          <S.Cell>
            <BodyM $color='brandPrimary'>Total Received</BodyM>
          </S.Cell>
          <S.Cell className='secondary'>
            <BodyMBold $color='brandPrimary'>
              {NumberToUSDollar.format(orderPayed - administrativeFess)}
            </BodyMBold>
          </S.Cell>
        </S.Row>
      </S.SummaryTable>
      <FilledButton
        className='btn-stripe'
        onClick={() => handleStripe()}
        color='dark-white'
        isUpper
      >
        <S.ButtonContent>
          OPEN STRIPE <RightSmallUp />
        </S.ButtonContent>
      </FilledButton>
    </S.Container>
  );
};

export default Summary;
