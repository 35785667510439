import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import UserType from 'models/User/UserTypeModel';
import { User } from 'models/User/UserModel';
import { Gender, GenderCategory } from 'admin/models/Enums';
import RosterService from 'services/v3/Rosters/RosterService';

import { useProfile } from 'hooks/v3/profile/useProfile';
import { useGetRosterByIdDetailed } from 'hooks/v3/clubs/useGetRosterByIdDetailed/useGetRosterByIdDetailed';
import { useGetClubMembersPool } from 'hooks/v3/clubs/useGetClubMembersPool/useGetClubMembersPool';
import { useUpdateRosterName } from 'hooks/v3/rosters/useUpdateRosterName/useUpdateRosterName';
import { useSubmitRoster } from 'hooks/v3/rosters/useSubmitRoster/useSubmitRoster';
import { useUpdateEditedRosterStatus } from 'hooks/v3/rosters/useUpdateEditedRosterStatus/useUpdateEditedRosterStatus';

import { notification } from 'components/v3/Notification/notification';
import RightDrawer from 'components/v3/RightDrawer';
import { useUpgradeMembersNotification } from 'components/v3/Buttons/UpgradeMembershipButton/hook/useUpgradeMembersNotification';

import { RosterStatus } from 'services/v3/Rosters/enums';
import useChangeRosterLockState from 'hooks/v3/rosters/useChangeRosterLockState/useChangeRosterLockState';
import moment from 'moment';
import { HeaderRosterEdit, ListOfUser } from './components';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import RosterSidebarSelection from './components/Modal/RosterSidebarSelection/RosterSidebarSelection';
import ModalBodyMemberInfo from './components/Modal/ModalBodyMemberInfo';
import { RequiredPointsModal } from './components/RequiredPointsModal/RequiredPointsModal';

import { IFiltering } from './components/Modal/RosterSidebarSelection/interfaces/filtering.interface';
import { validateUser, validateRoster } from './utils/validateRoster';
import { UserWithJersey, IUserRosterError, IRosterErrors } from './types';
import { AGE_RULES_VALUES, INITIAL_FILTRATING } from './configs';

const RosterEditPage: FC = () => {
  const { rosterId } = useParams<{ rosterId: string }>();

  const queryClient = useQueryClient();
  const history = useHistory();

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [savedUserIds, setSavedUserIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | false>(false);
  const [modalTitle, setModalTitle] = useState('');
  const [userType, setUserType] = useState<UserType>();
  const [visibleRequiredPointsModal, setVisibleRequiredPointsModal] = useState(
    false
  );

  const [rosterUserErrors, setRosterUserErrors] = useState<IUserRosterError[]>(
    []
  );
  const [rosterErrors, setRosterErrors] = useState<IRosterErrors>({
    coachesLimit: false,
    playersLimit: false,
  });

  const {
    currentUser: { accountId },
  } = useProfile();

  const {
    data: rosterDetailed,
    refetch: fetchRosterDetails,
  } = useGetRosterByIdDetailed(rosterId);

  const {
    data: membersResponse,
    isLoading,
    mutateAsync: fetchMembersPool,
  } = useGetClubMembersPool();
  const { mutateAsync: updateRosterName } = useUpdateRosterName();
  const { mutateAsync: submitRoster } = useSubmitRoster();
  const { mutateAsync: updateLockedStateRoset } = useChangeRosterLockState(
    rosterId
  );
  const {
    mutateAsync: updateEditedRosterStatus,
  } = useUpdateEditedRosterStatus();

  useUpgradeMembersNotification([
    ['get-club-members-pool', rosterDetailed?.club?.id, accountId],
  ]);

  const [filtering, setFiltering] = useState<IFiltering>(INITIAL_FILTRATING);

  const members = useMemo(() => {
    if (membersResponse?.data) {
      return membersResponse.data;
    }

    return [];
  }, [membersResponse]);

  const isSubmittedStatus = useMemo(() => {
    return rosterDetailed?.status === RosterStatus.Submitted;
  }, [rosterDetailed]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleOpenModal = useCallback((type: UserType) => {
    setModalTitle(type === UserType.COACH ? `Add ${type}es` : `Add ${type}s`);

    setUserType(type);
    setIsOpen(true);
  }, []);

  const handleOpenUserInfoModal = useCallback((user: User) => {
    setIsUserInfoOpen(true);
    setUserSelected(user);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalTitle('');
    setIsOpen(false);
    setUserSelected(false);
  }, []);

  const handleSaveNewUser = useCallback(async () => {
    const failedUsers: string[] = [];

    if (selectedUserIds.length > 0) {
      for await (const userId of selectedUserIds) {
        try {
          await RosterService.addPlayer(rosterId, userId);

          setSavedUserIds((prevState) => [...prevState, userId]);

          setSelectedUserIds((prevState) => [
            ...prevState.filter((curId) => curId !== userId),
          ]);

          queryClient.invalidateQueries(['/get-roster-detailed-id', rosterId]);

          if (isSubmittedStatus && rosterDetailed?.id) {
            updateEditedRosterStatus(rosterDetailed.id);
          }
        } catch (error) {
          const { message } = error as Error;

          notification.error({
            message: `Failed to save player ${message}`,
          });

          failedUsers.push(userId);
        }
      }

      if (failedUsers.length) {
        setSelectedUserIds((prevState) => [
          ...prevState.filter((userId) => !failedUsers.includes(userId)),
        ]);
      }

      handleCloseModal();
    }
  }, [
    selectedUserIds,
    rosterId,
    isSubmittedStatus,
    rosterDetailed,
    handleCloseModal,
  ]);

  const handleRemoveUser = useCallback(async () => {
    if (userSelected) {
      try {
        await RosterService.removePlayer(rosterId, userSelected.id);

        setSavedUserIds((prevState) => [
          ...prevState.filter((userId) => userId !== userSelected.id),
        ]);

        setSelectedUserIds((prevState) => [
          ...prevState.filter((userId) => userId !== userSelected.id),
        ]);

        queryClient.invalidateQueries(['/get-roster-detailed-id', rosterId]);

        if (isSubmittedStatus && rosterDetailed?.id) {
          updateEditedRosterStatus(rosterDetailed.id);
        }
      } catch {
        notification.error({
          message: `Failed to remove ${userSelected.firstName} ${userSelected.lastName}`,
        });
      }

      handleCloseModal();
    }
  }, [
    userSelected,
    rosterId,
    isSubmittedStatus,
    rosterDetailed,
    handleCloseModal,
  ]);

  const handleUpdateRosterName = useCallback(
    (rosterId: string, name: string) => {
      updateRosterName({ rosterId, name }).then(() => {
        fetchRosterDetails();
      });
    },
    [updateRosterName, fetchRosterDetails]
  );

  const handleSubmitRoster = useCallback(() => {
    const userErrors = validateUser(rosterDetailed, members);
    const rosterErrors = validateRoster(rosterDetailed);

    if (
      !userErrors.length &&
      !rosterErrors.coachesLimit &&
      !rosterErrors.playersLimit &&
      rosterDetailed?.id
    ) {
      submitRoster(rosterDetailed.id).then(() => {
        updateLockedStateRoset(true);
      });
    } else {
      notification.error({
        message: 'Roster is not valid',
        description: 'Please review your roster setup',
      });
    }

    setRosterUserErrors(userErrors);
    setRosterErrors(rosterErrors);
  }, [rosterDetailed, members]);

  const handleResetUserErrors = useCallback(
    (userId: string, param: keyof IUserRosterError, value: boolean) => {
      setRosterUserErrors((prevState) =>
        prevState.map((errorUser) =>
          errorUser.userId === userId
            ? { ...errorUser, [param]: value }
            : errorUser
        )
      );
    },
    []
  );

  const handleShowRequiredPointsModal = useCallback(() => {
    setVisibleRequiredPointsModal(true);
  }, []);

  const handleCloseRequiredPointsModal = useCallback(() => {
    setVisibleRequiredPointsModal(false);
  }, []);

  const handleMembersPoolFilter = useCallback(
    (filtering: IFiltering) => {
      if (rosterDetailed?.club?.id) {
        fetchMembersPool({
          clubId: rosterDetailed.club.id,
          params: filtering,
        });
      }
    },
    [rosterDetailed]
  );

  const coachesList = useMemo(() => {
    if (!members?.length) return [];

    return members.filter((member) => member.type === UserType.COACH);
  }, [members]);

  const playersList = useMemo(() => {
    if (rosterDetailed?.ageDivision && members) {
      const { gender } = rosterDetailed.ageDivision;
      const ageOffset = AGE_RULES_VALUES[rosterDetailed.ageDivision.rule];

      const filteredPlayers = members.filter((member) => {
        const allowedGirlsPlaysWithBoys =
          rosterDetailed.ageDivision?.allowGirlBoysOnAnotherDivision;
        const birthYear = new Date(member.birthDate as string).getFullYear();
        const ageDivisionRule = Number(
          rosterDetailed.ageDivision?.years.join('/') ?? 0
        );
        const ageAllowed = ageDivisionRule + ageOffset;

        const allowedByAgeRule =
          ageDivisionRule <= birthYear && birthYear <= ageAllowed;
        const allowedGender =
          gender === GenderCategory.COED ||
          gender === member.gender ||
          (gender === GenderCategory.MALE && allowedGirlsPlaysWithBoys);

        return (
          member.type === UserType.PLAYER && allowedGender && allowedByAgeRule
        );
      });

      return filteredPlayers;
    }

    return [];
  }, [members, rosterDetailed]);

  const coachesSelected = useMemo(() => {
    if (!members?.length) return [];

    let coachesMembers = members.filter(
      (member) =>
        member.type === UserType.COACH && savedUserIds.includes(member.id)
    );

    if (isSubmittedStatus) {
      rosterDetailed?.coaches.forEach((coach) => {
        const alreadyInlist = coachesMembers.some((c) => c.id === coach.id);

        if (!alreadyInlist) {
          const removedCoachFromSubmittedRoster: User = {
            ...coach,
            clubs: [],
            birthDate: moment(coach?.birthDate ?? ''),
            membership: {
              id: coach.membershipId,
              name: coach.membershipName,
              type: coach.membershipType,
              country: '',
              currency: 0,
              price: 0,
              userType: UserType.COACH,
            },
          };
          coachesMembers = [...coachesMembers, removedCoachFromSubmittedRoster];
        }
      });
    }

    return coachesMembers;
  }, [members, rosterDetailed, isSubmittedStatus, savedUserIds]);

  const isOtherPlayerGender = useMemo(() => {
    return (
      userType === UserType.PLAYER &&
      playersList?.some(
        (player) =>
          player?.type === UserType.PLAYER && player?.gender === Gender.OTHER
      )
    );
  }, [playersList, userType]);

  const playersSelected = useMemo<UserWithJersey[]>(() => {
    if (!members?.length) return [];

    let playersMembers: UserWithJersey[] = members
      .filter(
        (member) =>
          member.type === UserType.PLAYER && savedUserIds.includes(member.id)
      )
      .map((member) => ({
        ...member,
        jersey: rosterDetailed?.players.find((p) => p.id === member.id)?.number,
      }));

    if (isSubmittedStatus) {
      rosterDetailed?.players.forEach((player) => {
        const alreadyInlist = playersMembers.some((p) => p.id === player.id);

        if (!alreadyInlist) {
          const removedPlayerFromSubmittedRoster: UserWithJersey = {
            ...player,
            jersey: player?.number,
            birthDate: moment(player?.birthDate ?? ''),
            clubs: [],
            membership: {
              id: player.membershipId,
              name: player.membershipName,
              type: player.membershipType,
              country: '',
              currency: 0,
              price: 0,
              userType: UserType.PLAYER,
            },
            type: UserType.PLAYER,
            membershipId: player.membershipId,
            membershipType: player.membershipType,
          };
          playersMembers = [
            ...playersMembers,
            removedPlayerFromSubmittedRoster,
          ];
        }
      });
    }

    return playersMembers;
  }, [members, rosterDetailed, isSubmittedStatus, savedUserIds]);

  useEffect(() => {
    if (userType === UserType.COACH || userType === UserType.PLAYER) {
      setFiltering(INITIAL_FILTRATING);
    }
  }, [userType]);

  useEffect(() => {
    if (rosterDetailed?.club?.id) {
      fetchMembersPool({ clubId: rosterDetailed.club.id });
    }
  }, [rosterDetailed]);

  useEffect(() => {
    const selectedPlayers = rosterDetailed?.players.map((player) => player.id);
    const selectedCoaches = rosterDetailed?.coaches.map((player) => player.id);

    if (selectedPlayers && selectedPlayers?.length > 0) {
      setSavedUserIds((prevState) => [...prevState, ...selectedPlayers]);
    }

    if (selectedCoaches && selectedCoaches?.length > 0) {
      setSavedUserIds((prevState) => [...prevState, ...selectedCoaches]);
    }
  }, [rosterDetailed]);

  return (
    <>
      {rosterDetailed?.ageDivision && (
        <HeaderRosterEdit
          rosterId={rosterId || null}
          name={rosterDetailed?.name ?? ''}
          eventName={rosterDetailed?.event?.name ?? ''}
          eventLogo={rosterDetailed?.event?.logo ?? ''}
          division={rosterDetailed.ageDivision}
          isLockedRoster={
            rosterDetailed.locked
          }
          locksIn={rosterDetailed?.event?.data?.generalRules?.rosterLockDate}
          onGoBack={handleGoBack}
          onUpdateRosterName={handleUpdateRosterName}
          onSubmitRoster={handleSubmitRoster}
          onViewRequiredPoints={handleShowRequiredPointsModal}
        />
      )}
      {rosterDetailed?.coaches && (
        <ListOfUser
          typeOfUser='Coaches'
          coachesList={coachesSelected}
          isLockedRoster={
            rosterDetailed.locked
          }
          hasLimitsError={rosterErrors.coachesLimit}
          validationMin={rosterDetailed.event?.minimumCoachesNumber ?? 0}
          validationMax={rosterDetailed.event?.maximumCoachesNumber ?? 0}
          rosterUserErrors={rosterUserErrors}
          rosterStatus={rosterDetailed.status}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
          onResetUserErrors={handleResetUserErrors}
        />
      )}
      {rosterDetailed?.players && (
        <ListOfUser
          typeOfUser='Players'
          rosterId={rosterId || null}
          playersList={playersSelected}
          isLockedRoster={
            rosterDetailed.locked}
          hasLimitsError={rosterErrors.playersLimit}
          validationMin={rosterDetailed.event?.minimumPlayersNumber ?? 0}
          validationMax={rosterDetailed.event?.maximumPlayersNumber ?? 0}
          rosterUserErrors={rosterUserErrors}
          rosterStatus={rosterDetailed.status}
          handleOpenModal={handleOpenModal}
          handleOpenUserInfoModal={handleOpenUserInfoModal}
          onResetUserErrors={handleResetUserErrors}
        />
      )}
      {isOpen && userType && (
        <RightDrawer
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          headerComponent={<ModalHeader title={modalTitle} />}
        >
          <RosterSidebarSelection
            userType={userType}
            isLoading={isLoading}
            isOtherPlayerGender={!!isOtherPlayerGender}
            members={userType === UserType.COACH ? coachesList : playersList}
            selectedUserIds={selectedUserIds}
            savedUserIds={savedUserIds}
            filtering={filtering}
            setFiltering={setFiltering}
            setSelectedUserIds={setSelectedUserIds}
            onUpdateUsers={handleSaveNewUser}
            onMembersPoolFilter={handleMembersPoolFilter}
          />
        </RightDrawer>
      )}
      {isUserInfoOpen && userSelected && (
        <RightDrawer
          isOpen={isUserInfoOpen}
          handleCloseModal={handleCloseModal}
        >
          <ModalBodyMemberInfo
            member={userSelected}
            rosterDetailed={rosterDetailed}
            isLockedRoster={
              rosterDetailed?.locked
            }
            handleRemoveUser={handleRemoveUser}
            onResetUserErrors={handleResetUserErrors}
          />
        </RightDrawer>
      )}
      <RequiredPointsModal
        visible={visibleRequiredPointsModal}
        minPlayers={rosterDetailed?.event?.minimumPlayersNumber ?? 0}
        maxPlayers={rosterDetailed?.event?.maximumPlayersNumber ?? 0}
        minCoaches={rosterDetailed?.event?.minimumCoachesNumber ?? 0}
        maxCoaches={rosterDetailed?.event?.maximumCoachesNumber ?? 0}
        onClose={handleCloseRequiredPointsModal}
      />
    </>
  );
};

export default RosterEditPage;
