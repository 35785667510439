import React, { FC } from 'react';
import styled from 'styled-components';

import loadingGif from '../../../assets/imgs/UnitedFutsal_Loading.gif';

interface ILoadingProps {
  isLocal?: boolean;
}

const Loading: FC<ILoadingProps> = ({ isLocal = false }) => {
  return (
    <Wrapper className={isLocal ? 'local' : ''}>
      <div className='logo'>
        <img src={loadingGif} alt='' />
        <div style={{ textAlign: 'center' }}>Loading...</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.local {
    height: auto;
  }

  .logo {
    img {
      opacity: 0.15;
    }
  }
`;

export default Loading;
