import React, { FC } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import ConvertUtil from 'util/ConvertUtil';

import * as S from './styles';

interface IModalHeaderProps {
  photo?: string;
}

const ModalHeader: FC<IModalHeaderProps> = ({ photo }) => {
  return (
    <S.Container>
      <Avatar
        size={100}
        icon={<UserOutlined />}
        src={ConvertUtil.getMinioUrl(photo)}
      />
    </S.Container>
  );
};

export default ModalHeader;
