import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { RequestStatus } from 'models/Request/RequestModel';
import { User } from 'models/User/UserModel';

import { MembershipTypes } from 'services/v3/User/types';

import { ApplicationState } from 'redux/store';
import { clubDashboardToggleSelectedMemberPoolUser } from 'redux/v3/clubDashboard/actions';

import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import { useGetMembersPool } from 'hooks/v3/clubs/useGetMembersPool/useGetMembersPool';

import DateUtil from 'util/DateUtil';

import { Gender } from 'admin/models/Enums';
import { Table } from 'components/v3/Table/Table';
import RightDrawer from 'components/v3/RightDrawer';

import { BodyLBold } from 'styles/v3/variables';

import { columns } from './columns';
import ModalHeader from './components/Modal/ModalHeader/ModalHeader';
import ModalBody from './components/Modal/ModalBody/ModalBody';
import { FilterModalBody } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/FilterModalBody';
import { InvitationFiltersType } from '../../../InvitationsTab/components/InvitationsTable/components/Modal/FilterModalBody/types';
import { Filters } from './components';
import {
  getDocumentStatus,
  getPermissionType,
  getReadableGender,
} from './utils';

type MembersPool = {
  clubId: string,
  birthYear?: number[],
  gender?: Gender[],
  documentStatus?: RequestStatus[],
  membershipType?: MembershipTypes[],
  name?: string,
  validateAccess?: boolean,
};

export const MembersPoolTable = () => {
  const { clubId } = useParams<{ clubId: string }>();
  const dispatch = useDispatch();
  const [params, setParams] = useState<MembersPool>({
    clubId,
  });

  const { data: currentClub } = useGetClubById(clubId);
  const { data: members } = useGetMembersPool(params);

  const [searchValue, setSearchValue] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState<User | undefined>(
    undefined
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState<InvitationFiltersType>({
    age: [],
    documentStatus: [],
    gender: [],
    membership: [],
  });

  const {
    clubDirector: {
      memberPool: { selectedUserIds },
    },
  } = useSelector((state: ApplicationState) => state.clubDashboard);

  const membersAdapter = useMemo(() => {
    if (!currentClub || !members?.data?.length) {
      return [];
    }

    return members.data.map((member: User) => ({
      id: member.id,
      name: `${member.firstName} ${member.lastName}`,
      permission: getPermissionType(currentClub, member.id, member.type),
      type: member.type,
      age: DateUtil.getYearFromDateString(member.birthDate?.toString()),
      gender: member.gender || '',
      genderText: getReadableGender(member.gender),
      membership: member.membership?.type || 'COMMUNITY',
      documentStatus: getDocumentStatus(
        member.documentationVerified,
        member.ageVerified,
        member.type
      ),
      photoStatus: member.photoVerified,
      ageStatus: member.ageVerified,
      safeSportStatus: member.documentationVerified,
      photo: member.photo,
      selected: selectedUserIds.includes(member.id),
      toggleSelected: () => handleToggleSelectedUser(member.id),
      openDrawer: () => handleOpenDrawer(member),
    }));
  }, [members, currentClub, selectedUserIds]);

  const handleToggleSelectedUser = (userId: string) => {
    dispatch(clubDashboardToggleSelectedMemberPoolUser(userId));
  };

  const fetchBySearch = useCallback(
    debounce((value: string) => {
      setParams({
        clubId,
        birthYear: advancedFilter.age,
        gender: advancedFilter.gender,
        documentStatus: advancedFilter.documentStatus,
        membershipType: advancedFilter.membership,
        name: value,
      });
    }, 300),
    [clubId, advancedFilter]
  );

  const handleSearchMembers = (value: string) => {
    setSearchValue(value);
    fetchBySearch(value);
  };

  const handleOpenDrawer = (member: User) => {
    setCurrentMember(member);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentMember(undefined);
    setIsOpen(false);
  };

  const handleOpenFilterModal = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handleSetAdvancedFilter = useCallback(
    (filters: InvitationFiltersType) => {
      setAdvancedFilter(filters);

      setParams({
        clubId,
        birthYear: filters.age,
        gender: filters.gender,
        documentStatus: filters.documentStatus,
        membershipType: filters.membership,
        name: searchValue,
      });

      setIsFilterOpen(false);
    },
    [clubId, searchValue]
  );

  const handleRemoveFromClub = useCallback(() => {
    setParams({
      clubId,
      birthYear: advancedFilter.age,
      gender: advancedFilter.gender,
      documentStatus: advancedFilter.documentStatus,
      membershipType: advancedFilter.membership,
      name: searchValue,
    });
  }, [clubId, searchValue, advancedFilter]);

  return (
    <>
      <Filters
        handleOpenFilterModal={handleOpenFilterModal}
        handleChange={handleSearchMembers}
      />
      <Table
        columns={columns}
        dataSource={membersAdapter}
        pagination={{ hideOnSinglePage: true }}
      />
      <RightDrawer
        isOpen={isOpen && !!currentMember}
        handleCloseModal={handleCloseModal}
        headerComponent={<ModalHeader photo={currentMember?.photo} />}
      >
        {currentMember && (
          <ModalBody
            user={currentMember}
            onClose={handleCloseModal}
            onRemoveFromClub={handleRemoveFromClub}
          />
        )}
      </RightDrawer>
      <RightDrawer
        isOpen={isFilterOpen}
        handleCloseModal={handleCloseFilterModal}
        headerComponent={<BodyLBold $color='brandPrimary'>Filters</BodyLBold>}
      >
        <FilterModalBody
          filters={advancedFilter}
          handleChange={handleSetAdvancedFilter}
        />
      </RightDrawer>
    </>
  );
};
