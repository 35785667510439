import styled from 'styled-components';

import OutlinedButtonWithIcon from 'components/v3/Buttons/OutlinedButtonWithIcon';

import { COLORS, TitleH4 } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';
import { BREAKPOINTS } from 'styles/v3/constants';

import backgroundEvents from 'assets/imgs/my-events-background.jpeg';

export const Title = styled(TitleH4)`
  padding-bottom: 4px;
`

export const EventCardWrapper = styled.div<{ isHappening: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${toRem(30)} ${toRem(30)} ${toRem(25)} ${toRem(30)};
  border: 1px solid
    ${({ isHappening }) => (isHappening ? COLORS.brandPrimary : COLORS.grey800)};
  border-radius: ${toRem(24)};
  background: linear-gradient(86deg, #252622 40.19%, rgba(37, 38, 34, 0.91)),
    url(${backgroundEvents}) lightgray no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${COLORS.grey400};

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const EventContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const EventIcon = styled.div`
  width: ${toRem(69)};
  height: ${toRem(69)};
  border-radius: ${toRem(69)};
  margin: 0 auto;
  overflow: hidden;
  background-color: ${COLORS.brandPrimary};
  margin-right: ${toRem(24)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin: 0;
  }

  img {
    object-fit: cover;
    max-width: 100%;
    width: ${toRem(69)};
    height: ${toRem(69)};
  }
`;

export const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 290px);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;

export const EventDates = styled.div`
  display: flex;
  padding-bottom: ${toRem(8)};
`;

export const IsTodayEvent = styled.p`
  font-family: 'Boxed Regular';
  font-size: ${toRem(13)};
  text-transform: uppercase;
  color: ${COLORS.brandPrimary};
  margin-right: ${toRem(16)};
  margin-bottom: 0;
`;

export const EventDateOccurrence = styled.p`
  font-family: 'Boxed Regular';
  font-size: ${toRem(13)};
  text-transform: uppercase;
  color: ${COLORS.grey200};
  margin-bottom: 0;
`;

export const EventStatus = styled.p<{
  eventStatus: 'draft' | 'published' | 'roaster locked' | 'done';
}>`
  width: fit-content;
  padding: ${toRem(6)} ${toRem(12)};
  font-size: ${toRem(10)};
  text-transform: uppercase;
  color: ${({ eventStatus }) => {
    let color;
    if (eventStatus === 'draft') color = COLORS.white;
    else if (eventStatus === 'published') color = COLORS.grey1000;
    else color = COLORS.brandPrimary;
    return color;
  }};
  background-color: ${({ eventStatus }) =>
    eventStatus === 'draft' ? 'transparent' : COLORS.brandPrimary};
  border: 1px solid
    ${({ eventStatus }) =>
      eventStatus === 'draft' ? COLORS.white : COLORS.brandPrimary};
  border-radius: ${toRem(24)};
`;

export const ApplicationsResumeBtn = styled.p`
  padding-top: ${toRem(16)};
  margin: 0;
  color: ${COLORS.grey50};
  font-size: ${toRem(16)};
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  span {
    padding-left: ${toRem(8)};
  }
`;

export const EventBtnActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: fit-content;
  padding-left: ${toRem(24)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    padding-left: 0;

    & > button {
      width: 100%;
    }
  }
`;

export const PlanCardImageWrapper = styled.div`
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  overflow: hidden;
  background-color: ${COLORS.brandPrimary};

  img {
    object-fit: cover;
    max-width: 100%;
  }
`;

export const EventDivisionsContainer = styled.div`
  width: 100%;
  padding: ${toRem(23)};
  margin-top: ${toRem(48)};
  background-color: ${COLORS.grey900};
  border-radius: ${toRem(14)};
  overflow: hidden;
  transition: all 0.5s ease-out;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${toRem(33)};
`;


export const PermissionWrapper = styled.div`
  margin-top: ${toRem(16)};
  display: flex;
  flex-direction: row;
  column-gap: ${toRem(8)};
`;

export const GamesButton = styled(OutlinedButtonWithIcon)`
  &:hover {
    svg {
      color: ${COLORS.grey1000};
    }
  }

  span {
    &:before {
      display: none;
    }
  }

  svg {
    color: ${COLORS.brandPrimary};
    transition-duration: 500ms;
  }
`;
