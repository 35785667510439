import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { More } from '@icon-park/react';

import Select, { OptionsType } from 'components/v3/Forms/Select/Select';

import { BodyMBold, BodyM } from 'styles/v3/variables';

import { SelectOption } from '../../OrderFilters/types';

import {
  Container,
  FilterWrapper,
  SelectWrapper,
  MoreActionsWrapper,
  IconWrapper,
  ChooseApplications,
  ApplicationAllSelect,
} from './styles';

interface DivisionFiltersProps {
  yearsToFilter: number[];
  setGender: (gender: string) => void;
  setYear: (year: string) => void;
  handleSaveAll: (status: boolean) => Promise<void>;
}

export const DivisionsFilters: FC<DivisionFiltersProps> = ({
  yearsToFilter,
  setGender,
  setYear,
  handleSaveAll,
}) => {
  const applicationRef = useRef<HTMLDivElement>(null);

  const [openAllOptions, setOpenAll] = useState(false);
  const [selected, setSelected] = useState<SelectOption | undefined>({
    value: '',
    label: 'GENDER',
  });

  const [selectedYear, setSelectedYear] = useState<SelectOption | undefined>({
    value: '',
    label: 'YEAR',
  });

  const handleSelectedGender = (
    newValue: SingleValue<OptionsType> | MultiValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) return;
    if ('value' in newValue) {
      setGender(newValue.value.toString() || '');
      if (!newValue.value) {
        setSelected(undefined);
      } else {
        setSelected({
          value: newValue.value.toString(),
          label: newValue.label,
        });
      }
    }
  };

  const handleSelectedYear = (
    newValue: SingleValue<OptionsType> | MultiValue<OptionsType>,
    actionMeta: ActionMeta<OptionsType>
  ) => {
    if (!newValue) return;
    if ('value' in newValue) {
      setYear(newValue.value.toString() || '');
      if (!newValue.value) {
        setSelectedYear(undefined);
      } else {
        setSelectedYear({
          value: newValue.value.toString(),
          label: newValue.label,
        });
      }
    }
  };

  const saveAll = async (status: boolean) => {
    await handleSaveAll(status);
    setOpenAll(false);
  };

  useEffect(() => {
    const handleApplicationTrigger = (event: MouseEvent) => {
      const target = event.target as Node;

      if (
        applicationRef.current &&
        !applicationRef.current.contains(target)
      ) {
        setOpenAll(false);
      }
    };

    document.addEventListener('click', handleApplicationTrigger);

    return () => {
      document.removeEventListener('click', handleApplicationTrigger);
    };
  }, []);

  return (
    <Container>
      <FilterWrapper>
        <BodyMBold $isUpper $color='grey500'>
          Filter by
        </BodyMBold>
        <SelectWrapper>
          <Select
            menuPlacement='auto'
            menuPosition='fixed'
            onChange={handleSelectedGender}
            placeholder='GENDER'
            aria-label='GENDER'
            name='gender'
            value={selected}
            defaultValue={selected}
            options={[
              { value: '', label: 'GENDER' },
              { value: 'MALE', label: 'MALE' },
              { value: 'FEMALE', label: 'FEMALE' },
              { value: 'COED', label: 'COED' },
            ]}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            menuPlacement='auto'
            menuPosition='fixed'
            onChange={handleSelectedYear}
            placeholder='YEAR'
            aria-label='YEAR'
            name='year'
            value={selectedYear}
            defaultValue={selectedYear}
            options={[
              { value: '', label: 'YEAR' },
              ...yearsToFilter.map((y) => {
                return { value: y.toString(), label: y.toString() };
              }),
            ]}
          />
        </SelectWrapper>
      </FilterWrapper>
      <MoreActionsWrapper ref={applicationRef}>
        <IconWrapper onClick={() => setOpenAll(!openAllOptions)}>
          <More />
        </IconWrapper>
        <ChooseApplications className={openAllOptions ? 'visible': ''}>
          <ApplicationAllSelect onClick={() => saveAll(false)}>
            <BodyM>Open all applications</BodyM>
          </ApplicationAllSelect>
          <ApplicationAllSelect onClick={() => saveAll(true)}>
            <BodyM>Close all applications</BodyM>
          </ApplicationAllSelect>
        </ChooseApplications>
      </MoreActionsWrapper>
    </Container>
  );
};
