import React from 'react';

import { ClubManagerDashboard } from 'components/v3/ClubManagerDashboard/ClubManagerDashboard';
import { useParams } from 'react-router-dom';
import { useGetClubById } from 'hooks/v3/clubs/useGetClubById/useGetClubById';
import useClubPermission from 'hooks/v3/clubs/useClubPermission';
import {
  ClubDirectorMembers,
  ClubDirectorTeamsAndRoster,
  MemberMembers,
  MemberTeamsAndRoster,
} from 'pages/V3/ClubDashboard';

const tabPath = {
  MEMBERS: 'members',
  TEAMS_AND_ROSTERS: 'teams-and-rosters',
};

const ClubDashboard = () => {
  const params: {
    clubId: string,
    tabSlug: string,
    subTabSlug: string,
  } = useParams();

  const { data: currentClub } = useGetClubById(params.clubId);
  const { isDirectorOrManagerOfThisClub } = useClubPermission(currentClub);

  return (
    <ClubManagerDashboard
      tabs={[
        {
          title: 'Members',
          children: isDirectorOrManagerOfThisClub ? (
            <ClubDirectorMembers />
          ) : (
            <MemberMembers />
          ),
          key: tabPath.MEMBERS,
        },
        {
          title: 'Teams & Rosters',
          children: isDirectorOrManagerOfThisClub ? (
            <ClubDirectorTeamsAndRoster />
          ) : (
            <MemberTeamsAndRoster />
          ),
          key: tabPath.TEAMS_AND_ROSTERS,
        },
      ]}
    />
  );
};

export default ClubDashboard;
