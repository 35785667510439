import React, { useMemo, FC } from 'react';
import { Whistling, HeadsetOne, SoccerOne } from '@icon-park/react';

import UserType from 'models/User/UserTypeModel';

import { BodyM, COLORS } from 'styles/v3/variables';

import * as S from './styles';

interface IPermissionIconProps {
  userType: UserType;
}

export const PermissionIcon: FC<IPermissionIconProps> = ({ userType }) => {
  const buttonInfo = useMemo(() => {
    switch (userType) {
      case UserType.PLAYER:
        return {
          $bgColor: COLORS.brandPrimary,
          $textColor: COLORS.grey950,
          Icon: <SoccerOne />,
          title: 'Player',
        };
      case UserType.COACH:
        return {
          $bgColor: COLORS.brandSecondary,
          $textColor: COLORS.white,
          Icon: <HeadsetOne />,
          title: 'Coach',
        };
      case UserType.REFEREE:
        return {
          $bgColor: COLORS.brandTertiary,
          $textColor: COLORS.white,
          Icon: <Whistling />,
          title: 'Referee',
        };
      default:
        return null;
    }
  }, [userType]);

  if (!buttonInfo) {
    return null;
  }

  return (
    <S.Container
      $bgColor={buttonInfo.$bgColor}
      $textColor={buttonInfo.$textColor}
    >
      {buttonInfo.Icon}
      <BodyM>{buttonInfo.title}</BodyM>
    </S.Container>
  );
};
