import styled from 'styled-components';
import { Select, Avatar } from 'antd';

import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS, BodyM } from 'styles/v3/variables';

export const OrderSummaryWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${toRem(996)};
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    text-align: left;
    align-items: center;
    flex-direction: column;
    gap: ${toRem(32)};
    padding: ${toRem(48)} ${toRem(24)} 0;
  }
`;

export const SummaryInfoContainer = styled.div`
  display: flex;
  max-width: ${toRem(486)};
  flex-direction: column;

  h1 {
    margin-bottom: ${toRem(24)};
  }

  p {
    margin-bottom: 1rem;
  }

  .details-line {
    display: flex;
    margin-bottom: ${toRem(12)};
    align-items: flex-start;

    p {
      margin: 0 0 0 ${toRem(8)};
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    h1 {
      margin-bottom: ${toRem(32)};
    }
  }
`;

export const SummaryBoxInfo = styled.div`
  padding: ${toRem(24)};
  border-radius: 1.5rem;
  margin-top: ${toRem(12)};
  background-color: ${COLORS.grey900};

  p {
    margin: 0;
  }
`;

export const SummaryAside = styled.aside`
  display: flex;
  max-width: ${toRem(384)};
  border-radius: 1.5rem;
  padding: ${toRem(30)};
  flex-direction: column;
  justify-content: center;
  margin-left: ${toRem(126)};
  background-color: ${COLORS.grey950};
  text-align: center;

  img {
    margin: 0 auto 1.5rem;
    max-width: 120px;
  }

  .aside-title {
    margin-bottom: ${toRem(8)};
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-left: 0;
  }
`;

export const AsideBoxInfo = styled.div`
  padding: ${toRem(20)};
  width: ${toRem(324)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${toRem(30)} 0;
  border-radius: ${toRem(12)};
  background-color: ${COLORS.grey900};

  .aside-box-info-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    p {
      text-align: left;
      max-width: ${toRem(150)};
    }
  }

  h3 {
    margin: 0 ${toRem(16)} 0 0;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
  }
`;

export const CurrentUserWrapper = styled.div`
  width: 100%;
  pointer-events: none;

  & > div {
    max-width: none;
    width: 100%;
  }
`;

export const UserSelector = styled(Select)`
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 41%;
    right: 18px;
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid ${COLORS.grey300};
    border-right: 2px solid ${COLORS.grey300};
    transform: rotate(45deg);
  }

  .ant-select-selector {
    min-height: 54px !important; // Due to nesty restriction
    padding: 8px 42px 8px 16px !important; // Due to nesty restriction
    border-radius: 54px !important; // Due to nesty restriction
    border-color: #50534b !important; // Due to nesty restriction
    background-color: ${COLORS.grey900} !important; // Due to nesty restriction
    cursor: pointer;

    &:after {
      display: none;
    }

    .ant-select-selection-placeholder {
      position: static;
      transform: none;
      color: ${COLORS.grey300};
    }

    & > span:not(.ant-select-selection-placeholder):not(:nth-child(1)) {
      margin-left: -12px;
    }

    & > span:not(.ant-select-selection-placeholder):nth-child(11),
    & > span:not(.ant-select-selection-placeholder):nth-child(21) {
      margin-left: 0;
    }
  }

  .ant-select-selection-search {
    display: none;
  }
`;

export const UserDropdownWrapper = styled.div`
  .ant-select-item-option {
    &:not(:last-of-type) {
      border-bottom: 1px solid ${COLORS.grey900};
    }
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${COLORS.grey900};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${COLORS.grey970};
  }

  .anticon {
    color: ${COLORS.brandPrimary};
    font-size: 18px;
  }
`;

export const UserOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const AvatarWrapper = styled(Avatar)`
  background-color: #737370;

  &.bordered {
    border: 2px solid ${COLORS.grey300};
  }

  svg {
    color: ${COLORS.white};
  }
`;

export const UserOptionBody = styled(BodyM)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 36px);
  padding-left: 12px;
`;

export const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .i-icon {
    display: flex;
  }
`;

export const RolesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
