import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Switch } from 'antd';
import { CornerDownRight, Lock, Unlock, Camera, IdCardH, IdCardV, PreviewOpen } from '@icon-park/react';

import ConvertUtil from 'util/ConvertUtil';
import { RosterByEventId } from 'services/v3/Rosters/RosterService';

import Logo from 'components/v3/Elements/Logo/Logo';
import IconButton from 'components/v3/Buttons/IconButton';
import { BodyS, BodyM, BodyL, BodyXL, COLORS, TitleH3, ColorsInterface } from 'styles/v3/variables';

import {
  Container,
  HeaderModal,
  ClubIcon,
  ClubInformation,
  DirectorInformation,
  ContainerBody,
  HeaderBody,
  RosterLockStatus,
  ContainerOfUsers,
  UserInformationContainer,
  UserIcon,
  UserInformationHeader,
  UserMemberType,
  UserFooterContainer,
  UserDocsContainer,
  UserDocContainer,
} from './modalStyles';

interface IModalProps {
  roster: RosterByEventId;
  onUpdateRosterLockState: (rosterId: string, lockedState: boolean) => void;
}

interface IUserInformationModalProps {
  photo: string;
  name: string;
  membershipType: 'COMMUNITY' | 'OFFICIAL' | 'CCS',
  photoVerified: string;
  ageVerified: string;
  documentationVerified: string;
}

export const Modal: FC<IModalProps> = ({ roster, onUpdateRosterLockState }) => {
  const { club, coaches, players } = roster;

  const [lockedRoster, setLockedRoster] = useState(!!roster.locked);

  const isLocked = useMemo(() => {
    return roster.ageDivision.locked || lockedRoster;
  }, [roster, lockedRoster]);

  const getTextStateLock = useMemo(() => {
    if (roster.locked) {
      return 'Roster Locked';
    }

    if (roster.ageDivision.locked) {
      return 'Division Locked';
    }

    return 'Rosters Open';
  }, [roster]);

  const handleUpdateRosterLockState = useCallback(() => {
    onUpdateRosterLockState(roster.id, !lockedRoster);
    setLockedRoster(!lockedRoster);
  }, [roster, lockedRoster, onUpdateRosterLockState]);

  return (
    <Container>
      <HeaderModal>
        <ClubIcon>
          <img src={ConvertUtil.getMinioUrl(club.logo)} alt='User Img' />
        </ClubIcon>
        <ClubInformation>
          <TitleH3>{club.name}</TitleH3>
          <DirectorInformation>
            <CornerDownRight size={20} fill={COLORS.grey500} />
            <BodyM $color='grey200'>
              {roster.directors[0].firstName} {roster.directors[0].lastName}
            </BodyM>
            <BodyS $color='grey400'>Club Director</BodyS>
          </DirectorInformation>
        </ClubInformation>
      </HeaderModal>
      <Divider style={{ margin: 0, width: '100%' }} />
      <ContainerBody>
        <HeaderBody>
          <BodyL $color='grey50'>Roster</BodyL>
          <RosterLockStatus>
            <Switch
              checked={isLocked}
              onChange={handleUpdateRosterLockState}
              disabled={roster.ageDivision.lockDivision}
            />
            <BodyS $color={isLocked ? 'brandPrimary' : 'grey500'}>
              {getTextStateLock}
            </BodyS>
            {isLocked ? (
              <Lock fill={COLORS.brandPrimary} size={24} />
            ) : (
                <Unlock fill={COLORS.grey400} size={24} />
              )}
          </RosterLockStatus>
        </HeaderBody>
        <ContainerOfUsers>
          <BodyL $color='white'>Coaches ({coaches.length})</BodyL>
          {
            coaches.map((coach) => {
              const name = `${coach.firstName} ${coach.lastName}`;
              return <UsersInformationModal
                key={`key_for_coache_${coach.id}`}
                photo={coach.photo}
                name={name}
                membershipType={coach.membershipType}
                photoVerified={coach.photoVerified}
                ageVerified={coach.ageVerified}
                documentationVerified={coach.documentationVerified}
              />
            })
          }
        </ContainerOfUsers>
        <ContainerOfUsers>
          <BodyL $color='white'>Players ({players.length})</BodyL>
          {
            players.map((player) => {
              const name = `${player.firstName} ${player.lastName}`;
              return <UsersInformationModal
                key={`key_for_player_${player.id}`}
                photo={player.photo}
                name={name}
                membershipType={player.membershipType}
                photoVerified={player.photoVerified}
                ageVerified={player.ageVerified}
                documentationVerified={player.documentationVerified}
              />
            })
          }
        </ContainerOfUsers>
      </ContainerBody>
    </Container>
  );
};

const UsersInformationModal: FC<IUserInformationModalProps> = ({
  photo,
  name,
  membershipType,
  photoVerified,
  ageVerified,
  documentationVerified,
}) => {

  const findColor = (docStatus: string): keyof ColorsInterface => {
    if (docStatus === 'DECLINED' || docStatus === 'EXPIRED') {
      return 'supportError';
    }

    return 'grey400';
  }

  return (
    <UserInformationContainer>
      <UserInformationHeader>
        <UserIcon>
          <img src={ConvertUtil.getMinioUrl(photo)} alt='Club Logo' />
        </UserIcon>
        <BodyXL $color='white'>{name}</BodyXL>
      </UserInformationHeader>
      <UserMemberType>
        <Logo
          width={20}
          height={20}
          minimal
          color={
            membershipType === 'OFFICIAL' ? 'brandPrimary' : 'grey600'
          }
        />
        <BodyM
          $color={membershipType === 'OFFICIAL' ? 'grey50' : 'grey300'}
        >
          {membershipType === 'OFFICIAL'
            ? 'Official Member'
            : 'Community Member'}
        </BodyM>
      </UserMemberType>
      <UserFooterContainer>
        <UserDocsContainer>
          <UserDocContainer>
            <Camera fill={COLORS[findColor(photoVerified)]} size={24} />
            <BodyS $color={findColor(photoVerified)}>PH</BodyS>
          </UserDocContainer>
          <UserDocContainer>
            <IdCardH fill={COLORS[findColor(ageVerified)]} size={24} />
            <BodyS $color={findColor(ageVerified)}>AV</BodyS>
          </UserDocContainer>
          <UserDocContainer>
            <IdCardV fill={COLORS[findColor(documentationVerified)]} size={24} />
            <BodyS $color={findColor(documentationVerified)}>SS</BodyS>
          </UserDocContainer>
        </UserDocsContainer>
        <IconButton
          icon={<PreviewOpen size={24} fill={COLORS.grey50} />}
          style={{ backgroundColor: COLORS.grey800 }}
        />
      </UserFooterContainer>
    </UserInformationContainer>
  )
}
