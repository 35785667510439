import React from 'react';

import { useGetDiscoverEventsInfinity } from 'hooks/v3/event/useGetDiscoverEventsInfinity/useGetDiscoverEventsInfinity';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll/useInfiniteScroll';

import { notification } from 'components/v3/Notification/notification';
import EventCard from 'components/v3/Cards/EventCard/EventCard';
import Loading from 'components/v3/Loading/Loading';

import { BodyM } from 'styles/v3/variables';

import * as S from './styles';

const PAGE_SIZE = 10;

export const DiscoverEvents = () => {
  const { data, isFetching, fetchNextPage } = useGetDiscoverEventsInfinity(
    PAGE_SIZE
  );

  const eventData = data?.pages.flatMap((page) => page.data.content);

  const currentPage = data?.pages[data.pages.length - 1]?.data?.page ?? 0;
  const total = data?.pages[0]?.data?.total ?? 0;

  const hasNextPage = PAGE_SIZE * (currentPage + 1) < total;

  const setIntersectionObserver = useInfiniteScroll(
    hasNextPage,
    isFetching,
    async () => {
      try {
        await fetchNextPage();
      } catch {
        notification.error({ message: 'Failed to load more events!' });
      }
    }
  );

  return (
    <S.EventsList>
      {eventData?.map((item) => (
        <EventCard
          size='small'
          key={item.id}
          id={item.id}
          logo={item.logo}
          title={item.name}
          orientation='column'
          actionText='See event'
          eventStatus={item.draft ? 'draft' : 'published'}
          description={item.description}
          startDate={item.startDate}
          finalDate={item.endDate}
          link={`/v3/event/${item.id}`}
        />
      ))}
      {isFetching && <Loading />}
      {hasNextPage && <div ref={setIntersectionObserver} />}
      {!eventData?.length && !isFetching && <BodyM>Events coming soon</BodyM>}
    </S.EventsList>
  );
};
