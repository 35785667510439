import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/v3/constants';
import { toRem } from 'styles/v3/functions';
import { COLORS } from 'styles/v3/variables';

export const PaymentWrapper = styled.div`
  max-width: 588px;
  margin: 0 auto;

  .back-button {
    margin-bottom: ${toRem(30)};
  }

  .disclaimer {
    text-align: center;
  }

  h1 {
    margin: ${toRem(30)} 0;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: ${toRem(48)} ${toRem(10)} 0;
  }
`;

export const OrderSummaryWrapper = styled.div`
  background-color: ${COLORS.grey950};
  border-radius: 1.5rem;
  padding: ${toRem(30)};
  margin-bottom: ${toRem(30)};

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: ${toRem(30)};
  }
`;

export const SeasonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: ${toRem(24)};
  }
`;

export const SeasonDetails = styled.div`
  margin-left: 1.5rem;
  text-align: center;

  h3 {
    margin-bottom: ${toRem(8)};
  }
`;

export const MembershipDetailsWrapper = styled.div`
  display: flex;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;

    align-items: center;
    flex-direction: column;

    gap: ${toRem(16)};
  }
`;

export const MembershipInfo =
  styled.div <
  { $direction: 'column' | 'row' } >
  `
  display: flex;
  padding: ${toRem(20)};
  width: 256px;
  background-color: ${COLORS.grey900};
  border-radius: ${toRem(12)};
  flex-direction: ${(props) => props.$direction};
  align-items: ${(props) =>
    props.$direction === 'row' ? 'center' : 'flex-start'};

  h3 {
    margin: 0 ${toRem(8)} 0 0;
  }

  .user-status {
    margin-bottom: ${toRem(12)};
  }

  & + div {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    & + div {
      margin-left: 0;
    }
  }
`;

export const UsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 8px;
  border-radius: 50px;
  border: 1px solid ${COLORS.grey800};
`;
