import React from 'react';

import { BodyM, BodyS } from 'styles/v3/variables';
import * as S from './styles';
import { DocumentItemProps } from './types';

export const DocumentItem = ({
  isRequired,
  title,
  status,
}: DocumentItemProps) => {
  return (
    <S.Container>
      <BodyM className={isRequired ? 'required' : 'not-required'}>
        {title}
      </BodyM>
      {isRequired ? (
        <S.StatusTag $status={status}>
          <BodyS color='grey1000'>{status}</BodyS>
        </S.StatusTag>
      ) : (
        <BodyM className='not-required'>No Document</BodyM>
      )}
    </S.Container>
  );
};
