import styled from 'styled-components';

import { BodyM, COLORS } from 'styles/v3/variables';
import { toRem } from 'styles/v3/functions';

export const ContainerUser = styled.div`
  width: 100%;
  display: flex;
  padding: ${toRem(16)};
  align-items: center;
  justify-content: space-between;
  border: ${toRem(1)} solid ${COLORS.grey800};
  border-radius: ${toRem(16)};
`;

export const UserInformationContent = styled.div`
  display: flex;
  gap: ${toRem(24)};
  align-items: center;
`;

export const UserIconName = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserMemberType = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserDocumentsAndMore = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const UserDocuments = styled.div`
  display: flex;
  padding: ${toRem(8)};
  gap: ${toRem(12)};
  align-items: center;
`;

export const UserDocument = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${toRem(3)};

  span {
    display: flex;
  }
`;

export const UserJerseyContainer = styled.div`
  padding: ${toRem(8)} ${toRem(8)} ${toRem(8)} ${toRem(16)};
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: ${toRem(12)};
  border: ${toRem(1)} solid ${COLORS.grey400};
`;

export const JersetNumber = styled(BodyM)`
  color: ${COLORS.white};
  min-height: ${toRem(19)};
`;
