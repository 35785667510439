import { useInfiniteQuery } from '@tanstack/react-query';
import { EventListView } from 'admin/models/event/Event';
import PageResponse from 'services/util/PageResponse';
import ServiceResponseV3 from 'services/util/ServiceResponseV3';
import EventService from 'services/v3/Event/EventService';

export const useGetDiscoverEventsInfinity = (pageSize = 10) => {
  return useInfiniteQuery({
    keepPreviousData: true,
    queryKey: ['discover-events-infinity', pageSize],
    queryFn: ({ pageParam: page }) => {
      return EventService.getActiveEventsPaged(pageSize, page);
    },
    getNextPageParam: (data: ServiceResponseV3<PageResponse<EventListView>>) =>
      data.data?.hasNextPage ? data.data?.page + 1 : undefined,
  });
};
