import React, {
  FC,
  useCallback,
  useState,
  MouseEvent,
  useMemo,
  ReactNode,
} from 'react';
import { Select } from 'antd';
import { Star, CheckOne } from '@icon-park/react';
import { withRouter, useHistory } from 'react-router-dom';
import { SelectValue } from 'antd/lib/select';
import { UserOutlined } from '@ant-design/icons';

import UserType from 'models/User/UserTypeModel';

import { MembershipTypes } from 'services/v3/User/types';
import OrderService from 'services/v3/Order/OrderService';

import { useProfile } from 'hooks/v3/profile/useProfile';

import TextButtonWithIcon from 'components/v3/Buttons/TextButtonWithIcon';
import FilledButtonWithIcon from 'components/v3/Buttons/FilledButtonWithIcon';
import AvatarAction from 'components/v3/Elements/Avatares/AvatarAction/AvatarAction';
import { notification } from 'components/v3/Notification/notification';
import { ACCOUNTS_TAG_ICONS } from 'components/v3/AccountModal/components/AccountTag/AccountTag';

import DateUtil from 'util/DateUtil';
import ConvertUtil from 'util/ConvertUtil';

import Logo from 'assets/imgs/logos/logo-vault-green.png';

import {
  TitleH1,
  BodyM,
  COLORS,
  BodyS,
  TitleH3,
  BodyMBold,
} from 'styles/v3/variables';

import { User } from 'models/User/UserModel';
import * as S from './styles';

const PRICE = 29;

const OrderSummary: FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const { currentUser, users, isAnyRoles } = useProfile();

  const currentSeason = DateUtil.formatSeason();
  const firstYear = currentSeason.split('-')[0];
  const lastYear = currentSeason.split('-')[1];

  const upgradingUsers = useMemo(() => {
    return users
      .filter((user) =>
        user.baseUserId &&
        user.membershipType === "COMMUNITY" &&
        (user.type === UserType.PLAYER || user.type === UserType.COACH)
      )
      .reduce<(User & { types: UserType[] })[]>((result, user) => {
        const siblings = users.filter(
          (sibling) =>
            sibling.baseUserId === user.baseUserId &&
            (sibling.type === UserType.PLAYER || sibling.type === UserType.COACH)
        );

        const types = Array.from(
          new Set(siblings.map((sibling) => sibling.type).filter((type): type is UserType => !!type))
        );

        if (!result.some((u) => u.baseUserId === user.baseUserId)) {
          result.push({ ...user, types });
        }
  
        return result;
      }, []);
  }, [users]);

  const price = useMemo(() => {
    const selectedMembersLength = selectedMembers.length;

    if (isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER])) {
      return selectedMembersLength * PRICE;
    }

    return PRICE;
  }, [selectedMembers]);

  const isDisabledSubmitButton = useMemo(() => {
    if (isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER]) && !!selectedMembers.length) {
      return false;
    }

    return currentUser.membershipType === MembershipTypes.Official || isLoading;
  }, [currentUser, isLoading, selectedMembers, isAnyRoles]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const createMembership = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const ids = selectedMembers.join(',');

    OrderService.getMembershipCheckoutStripeUrl({
      userIds: selectedMembers,
      cancelUrl: `/v3/profile/upgrade-membership/payment?users=${ids}`,
      successUrl: `/v3/profile/upgrade-membership/payment?success=true&users=${ids}`,
    })
      .then((res) => {
        if (res.httpCode === 200) {
          window.location.href = res.data.paymentUrl;
        } else {
          notification.error({
            duration: 3,
            message: 'Error',
            description:
              res.message ||
              'Error upgrading membership checkout please try again',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderTagResult = useCallback(
    (params: any) => {
      const user = upgradingUsers.find((user) => user.id === params.value);

      return (
        <S.AvatarWrapper
          icon={<UserOutlined />}
          src={ConvertUtil.getMinioUrl(user?.photo)}
          className='bordered'
        />
      );
    },
    [upgradingUsers]
  );

  const renderDropdown = useCallback((originNode: ReactNode) => {
    return (
      <S.UserDropdownWrapper>
        {originNode}
      </S.UserDropdownWrapper>
    )
  }, []);

  const handleMembershipsChange = useCallback((value: SelectValue) => {
    setSelectedMembers(value as string[]);
  }, []);

  const getRoleIcon = useCallback((type?: UserType) => {
    if (!type) {
      return null;
    }

    return ACCOUNTS_TAG_ICONS[type];
  }, []);

  return (
    <S.OrderSummaryWrapper>
      <S.SummaryInfoContainer>
        <TextButtonWithIcon
          color='light'
          align='flex-end'
          reverse
          icon='back'
          onClick={handleGoBack}
        >
          Go back
        </TextButtonWithIcon>
        <TitleH1 $color='brandPrimary'>Order Summary</TitleH1>
        <BodyM $color='grey300'>What You’ll Get</BodyM>
        <div className='details-line'>
          <CheckOne size={16} />
          <BodyM $color='grey300' className='highlight'>
            Vault Player Profile
          </BodyM>
        </div>
        <div className='details-line'>
          <CheckOne size={16} />
          <BodyM $color='grey300' className='highlight'>
            View upcoming events
          </BodyM>
        </div>
        <div className='details-line'>
          <CheckOne size={16} />
          <BodyM $color='grey300' className='highlight'>
            Access to photos and media from United Futsal events
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            Discounts on United Futsal Merchandise
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            Participate in any Officially Sanctioned United Futsal Leagues and
            Tournaments
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>Industry - Leading Insurance</BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            Earn XP to LEVEL UP your player profile
          </BodyM>
        </div>
        <div className='details-line'>
          <Star size={16} color={COLORS.white} theme='filled' />
          <BodyM className='highlight'>
            StatTracker+TM System for tracking goals and other metrics.
          </BodyM>
        </div>
        <S.SummaryBoxInfo>
          <BodyS $color='grey200' $spanColor='brandPrimary'>
            By upgrading your account you authorize <span>United Futsal</span>{' '}
            to authenticate the requested documentation and accept and
            acknowledge the <span>Release & Liability Waiver</span> and{' '}
            <span>Covid-19 Waiver</span> presented to you during the application
            process.
          </BodyS>
        </S.SummaryBoxInfo>
      </S.SummaryInfoContainer>
      <S.SummaryAside>
        <img src={Logo} alt='Vault Logo' />
        <TitleH3 className='aside-title'>Season {currentSeason}</TitleH3>
        <BodyM $color='grey300'>
          Starts at September 1st, {firstYear}
          <br />
          Ends at August 31th, {lastYear}
        </BodyM>
        <S.AsideBoxInfo>
          <div className='aside-box-info-price'>
            {currentUser.membershipType !== MembershipTypes.Official || isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER]) ? (
              <>
                <TitleH3 $color='brandPrimary'>${price}</TitleH3>
                <BodyM>Official Membership</BodyM>
              </>
            ) : (
              <BodyM $color='supportError'>
                You are already an OFFICIAL member
              </BodyM>
            )}
          </div>
          {!isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER]) && currentUser?.firstName && (
            <S.CurrentUserWrapper>
              <AvatarAction />
            </S.CurrentUserWrapper>
          )}
          {isAnyRoles([UserType.CLUB_DIRECTOR, UserType.TEAM_MANAGER]) && (
            <S.UserSelector
              mode='multiple'
              showSearch={false}
              placeholder='Select users to upgrade'
              tagRender={renderTagResult}
              onChange={handleMembershipsChange}
              dropdownRender={renderDropdown}
            >
              {upgradingUsers.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  <S.UserOption>
                    <S.AvatarWrapper
                      icon={<UserOutlined />}
                      src={ConvertUtil.getMinioUrl(user?.photo)}
                    />
                    <S.UserOptionBody>
                      <BodyMBold>
                        {user.firstName} {user.lastName}
                      </BodyMBold>
                      <S.RolesWrapper>
                        {user.types.map((type) => {
                          const RoleIcon = getRoleIcon(type);

                          return (
                            <S.RoleWrapper key={type}>
                              {RoleIcon && (
                                <RoleIcon size="18px" />
                              )}
                              <BodyS>{type.replace('_', '  ')}</BodyS>
                            </S.RoleWrapper>
                          )
                        })}
                      </S.RolesWrapper>
                    </S.UserOptionBody>
                  </S.UserOption>
                </Select.Option>
              ))}
            </S.UserSelector>
          )}
        </S.AsideBoxInfo>
        <FilledButtonWithIcon
          isUpper
          onClick={createMembership}
          disabled={isDisabledSubmitButton}
        >
          {isLoading ? 'Processing....' : 'Continue to payment'}
        </FilledButtonWithIcon>
      </S.SummaryAside>
    </S.OrderSummaryWrapper>
  );
};

export default withRouter(OrderSummary);
