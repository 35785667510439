import { useCallback, useRef } from 'react';

export const useInfiniteScroll = (
  hasMore: boolean,
  isFetching: boolean,
  onLoadMore: () => void
) => {
  const observer = useRef<Maybe<IntersectionObserver>>(null);

  const setIntersectionObserver = useCallback(
    (node: HTMLDivElement) => {
      if (isFetching) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((items) => {
        if (items[0].isIntersecting && hasMore) {
          onLoadMore();
        }
      });

      if (node && hasMore && observer.current) {
        observer.current.observe(node);
      }
    },
    [hasMore, onLoadMore]
  );

  return setIntersectionObserver;
};
